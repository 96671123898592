import styled from 'styled-components';

export const Container = styled.div`
  height: 90%;
`;

export const Content = styled.div`
  height: 100%;
  margin-top: 20px;
  form {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    input {
      margin-bottom: 7px;
    }
    .select {
      width: inherit;
    }
    .marginButton {
      margin-bottom: 30px;
    }
    .sectionDate {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      column-gap: 5px;
    }
    .inputDateTime {
      width: inherit;
      height: 52px;
      max-height: 52px;
      border: 1px solid #d2d2d4;
      padding: 22px;
    }
    button {
      margin-bottom: 20px;
    }
  }
`;

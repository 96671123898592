import styled, { css } from 'styled-components';

interface ContainerProps {
  showMenu?: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${(props) =>
    !props.showMenu &&
    css`
      display: none;
    `}
  > section {
    display: flex;
    flex-direction: column;
    height: 0;

    > div {
      svg {
        width: 22px;
        margin-left: 22px;
      }
      align-self: flex-end;
    }
  }

  > div {
    display: flex;
    width: 100%;
    flex-direction: row;
    height: 56px;
    padding: 0 28px;
    align-items: center;
    transition: 0.3s all;
    cursor: pointer;

    svg,
    span {
      color: #fff;
    }

    .sub-menus {
      display: flex;
      flex-direction: column;
    }

    svg {
      transition: 0.3s all;
    }

    ${(props) =>
      !props.showMenu &&
      css`
        & {
          display: none;
        }
      `}

    &:hover {
      background-color: #9fa2b429;
      border-left: 2px solid #2f2f2f;

      svg,
      span {
        color: #fff;
      }
    }

    span {
      font-weight: 500;
      font-size: 16px;
      margin-left: 28px;
      transition: 0.3s all;
    }
  }
`;

import React from 'react';
import ReactModal from 'react-awesome-modal';

import Button from 'components/Button';
import { Container } from './styled';

interface ModalProps {
  openModal: boolean;
  toggleModal: () => void;
  onClickConfirm?: (params?: any) => void;
  loading?: boolean;
  title?: string;
  width?: string;
}

const Modal: React.FC<ModalProps> = ({
  openModal,
  toggleModal,
  onClickConfirm,
  loading,
  title,
  width = '900',
  children,
}) => {
  return (
    <Container>
      <ReactModal
        visible={openModal}
        effect="fadeInUp"
        width={width}
        maxWidth="900px"
        onClickAway={toggleModal}
      >
        <div className="modal-content">
          <h1>{title}</h1>
          {children}
        </div>
        <div className="modal-actions">
          <Button onClick={toggleModal}>Voltar</Button>
          <Button onClick={onClickConfirm}>
            {loading ? 'Salvando...' : 'Confirmar'}
          </Button>
        </div>
      </ReactModal>
    </Container>
  );
};

export default Modal;

/* eslint-disable spaced-comment */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Table } from '@pdasolucoes/web';
import FullLoader from 'components/FullLoader';
import Pagination from 'components/Pagination';
import { addMonths, isAfter, isBefore } from 'date-fns';
import { useUsers } from 'hooks/users';
import api from 'services/api';
import { toast } from 'react-toastify';
import { useMenu } from 'context';
import Select from 'components/ReactSelect';
import Checkbox from '@mui/material/Checkbox';
import Input from 'components/Input';
import Button from 'components/Button';
import { SelectItem, transformToSelect } from 'utils/toSelect';
import { FaCheck, FaMinusCircle } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import CircularLoading from 'components/CircularLoading';
import formatDate from 'utils/formatDate';
import formatDateUS from 'utils/formatDateUS';
import { padStart } from '@fullcalendar/react';
import {
  Container,
  Foother,
  InputRetrieved,
  SectionDate,
  SectionFilters,
  TableContainer,
  TableContent,
  TotalsContainer,
} from './styles';

interface IReportsProps {
  id: string;
  name: string;
  ssn: string;
  date: string;
  grossValue: string;
  netValue: string;
  payment_type: string;
  isAvailable: string;
  retrieved: string;
  created_at: string;
  id_transaction: string;
  retrieved_date: string;
  available_in: string;
}

interface PropsSelect {
  value: string;
  label: string;
}

const Reports: React.FC = () => {
  const idPlace = process.env.REACT_APP_ID_PLACE;
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedType, setSelectedType] = useState('1');
  const [selectedyear, setSelectedyear] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [showOnlyPaid, setShowOnlyPaid] = useState(false);
  const [reports, setReports] = useState<IReportsProps[]>([]);
  const [reportsFilter, setReportsFilter] = useState<IReportsProps[]>([]);
  const [selectYears, setSelectYears] = useState<PropsSelect[]>(
    [] as PropsSelect[]
  );
  const [places, setPlaces] = useState({} as PropsSelect[]);
  const [placeSelected, setPlaceSelected] = useState({} as PropsSelect);
  const [selectPlaceIsDisable, setSelectPlaceIsDisable] = useState(false);
  const dispatch = useDispatch();
  const { setPageTitle } = useMenu();
  const datestart = new Date();
  const mes = datestart.getMonth() + 1;
  const ano = datestart.getFullYear();

  const optionsSelecttypes = [
    { value: '0', label: 'Tudo' },
    { value: '1', label: 'Avulsa' },
    { value: '2', label: 'Day Use' },
  ];
  const optionsSelectMontly = [
    { value: '13', label: 'Tudo' },
    { value: '1', label: 'Janeiro' },
    { value: '2', label: 'Fevereiro' },
    { value: '3', label: 'Março' },
    { value: '4', label: 'Abril' },
    { value: '5', label: 'Maio' },
    { value: '6', label: 'Junho' },
    { value: '7', label: 'Julho' },
    { value: '8', label: 'Agosto' },
    { value: '9', label: 'Setembro' },
    { value: '10', label: 'Outubro' },
    { value: '11', label: 'Novembro' },
    { value: '12', label: 'Dezembro' },
  ];

  const getInitialAppointments = React.useCallback(() => {
    setLoading(true);
    api
      .get(
        `/appointments/findWebReport?type=1&month=${mes}&year=${ano}&id_place=${idPlace}`
      )
      .then((response) => {
        const data = response.data.map((item: IReportsProps) => {
          const startDate = item.date.replace('Z', '');

          const retrievedDate = formatDateUS(
            new Date(item.retrieved_date),
            '-'
          );
          return {
            ...item,
            retrieved_date: retrievedDate,
            date: startDate,
            available_in: item.available_in.replace('Z', ''),
          };
        }) as IReportsProps[];

        setReports(data);
        setReportsFilter(data);
        setLoading(false);
      });
  }, [ano, idPlace, mes]);

  const getAppointments = React.useCallback(() => {
    setLoading(true);
    api
      .get(
        // eslint-disable-next-line prettier/prettier
        `/appointments/findWebReport?type=${selectedType}&month=${
          selectedMonth || mes
        }
        &year=${selectedyear || ano}&id_place=${idPlace}`
      )
      .then((response) => {
        const data = response.data.map((item: IReportsProps) => {
          const startDate = item.date.replace('Z', '');

          const retrievedDate = formatDateUS(
            addMonths(new Date(item.retrieved_date), 1),
            '-'
          );

          console.log(retrievedDate);
          return {
            ...item,
            retrieved_date: retrievedDate,
            date: startDate,
            available_in: item.available_in.replace('Z', ''),
          };
        }) as IReportsProps[];
        setReports(data);
        setReportsFilter(data);
        setLoading(false);
      });
  }, [selectedType, selectedMonth, mes, selectedyear, ano, idPlace]);

  const filterPaid = React.useCallback(() => {
    setShowOnlyPaid(!showOnlyPaid);
    setLoading(true);
    if (!showOnlyPaid) {
      const filteredPaids = reportsFilter.filter(
        (report) => report.payment_type !== 'Reserva manual'
      );
      setReports(filteredPaids);
    } else {
      setReports(reportsFilter);
    }
    setLoading(false);
  }, [reportsFilter, showOnlyPaid]);

  const totalGrossValue = useMemo(() => {
    let finalPrice = 0;

    reports
      .filter((report) => report.payment_type !== 'Reserva manual')
      .map((item) => {
        finalPrice += Number(item.grossValue);
        return null;
      });

    return finalPrice;
  }, [reports]);

  const totalNetValue = useMemo(() => {
    let finalPrice = 0.0;

    reports
      .filter((report) => report.payment_type !== 'Reserva manual')
      .map((item) => {
        if (item.id_transaction > '') {
          finalPrice += Number(item.netValue);
          return null;
        }
        return null;
      });

    return finalPrice;
  }, [reports]);

  const totalAvailableValue = useMemo(() => {
    let availablePrice = 0.0;

    reports
      .filter((report) => report.payment_type !== 'Reserva manual')
      .map((item) => {
        if (!item.retrieved && item.payment_type !== 'Reserva manual') {
          if (isBefore(new Date(item.available_in), new Date())) {
            availablePrice += Number(item.netValue);
          }
        }

        return null;
      });

    return availablePrice;
  }, [reports]);

  const totalRecievedValue = useMemo(() => {
    let recievedValue = 0.0;

    reports
      .filter((report) => report.payment_type !== 'Reserva manual')
      .map((item) => {
        if (item.id_transaction > '') {
          if (item.retrieved) {
            recievedValue += Number(item.netValue);
          }
        }

        return null;
      });

    return recievedValue;
  }, [reports]);

  useEffect(() => {
    getAppointments();
  }, [selectedType, selectedMonth, selectedyear, getAppointments]);

  useEffect(() => {
    setPageTitle('Relatórios');
    const years: PropsSelect[] = [];
    years.push({ value: '1', label: 'Tudo' });
    const currentDate = new Date();
    //@ts-ignore
    const ageInMilliseconds = currentDate - new Date('2020-01-01');
    const totalYears = Math.floor(
      ageInMilliseconds / 1000 / 60 / 60 / 24 / 365
    );
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < totalYears; i++) {
      years.push({
        value: `${currentDate.getFullYear() - i}`,
        label: `${currentDate.getFullYear() - i}`,
      });
    }

    setSelectYears(years);

    api.get(`/places/findAll`).then((response) => {
      const listPlaces = transformToSelect([...response.data]);
      if (response.data.length <= 1) {
        setPlaceSelected(listPlaces[0]);
        setSelectPlaceIsDisable(true);
      } else {
        setPlaces([...listPlaces]);
      }
    });
    getInitialAppointments();
  }, [page, itemsPerPage, dispatch, setPageTitle, getInitialAppointments]);

  const handleChangeRetreivedFlag = useCallback(
    (id_appointment: string) => {
      api
        .put(`/appointments/UpdateRetrievedFlag`, { id_appointment })
        .then(() => {
          toast.success('Alterado com sucesso!');
          const data = reportsFilter.map((item) => {
            if (item.id === id_appointment) {
              return {
                ...item,
                retrieved: !item.retrieved,
              };
            }
            return item;
          }) as IReportsProps[];
          setReports(data);
          setReportsFilter(data);
        });
    },
    [reportsFilter]
  );

  const handleChangeRetreivedDate = useCallback(
    (id_appointment: string, date: string) => {
      const dateRetrieved = new Date(date);
      const year = dateRetrieved.getFullYear();
      const month = String(dateRetrieved.getMonth() + 1).padStart(2, '0');
      const day = String(dateRetrieved.getDate() + 1).padStart(2, '0');
      const stringDate = `${year}-${month}-${day}T18:25:43.511Z`;
      api
        .put(`/appointments/updateRetrievedDate`, {
          id_appointment,
          date: stringDate,
        })
        .then(() => {
          toast.success('Data alterada com sucesso!');
          const data = reportsFilter.map((item) => {
            if (item.id === id_appointment) {
              return {
                ...item,
                retrieved_date: date,
              };
            }
            return item;
          }) as IReportsProps[];
          setReports(data);
          setReportsFilter(data);
        });
    },
    [reportsFilter]
  );
  return (
    <Container>
      <FullLoader show={loading} />
      <SectionFilters>
        <div className="body">
          <Select
            label="Tipo"
            className="select"
            options={optionsSelecttypes}
            closeMenuOnSelect
            onChange={(e) => setSelectedType(String(e?.value))}
            defaultValue={optionsSelecttypes[0]}
            name="type"
          />
          <SectionDate>
            <Select
              label="Ano"
              className="select"
              options={selectYears}
              defaultValue={{ value: ano, label: ano }}
              closeMenuOnSelect
              onChange={(e) => setSelectedyear(Number(e?.value))}
              name="data"
            />
            <Select
              label="Mês"
              className="select"
              options={optionsSelectMontly}
              defaultValue={{
                value: mes,
                label: optionsSelectMontly.filter(
                  (i) => i.value === String(mes)
                )[0].label,
              }}
              closeMenuOnSelect
              onChange={(e) => setSelectedMonth(Number(e?.value))}
              name="local"
            />
          </SectionDate>

          <Select
            label="Local"
            className="select"
            options={places}
            value={placeSelected}
            isDisabled={selectPlaceIsDisable}
            name="local"
          />

          <br />
        </div>
        <Foother>
          <Button type="button" onClick={getAppointments}>
            Filtrar
          </Button>
        </Foother>
      </SectionFilters>
      <TableContainer>
        <TotalsContainer>
          <div>
            <p>Total da pesquisa:</p>
            <span>{`${reports.length}`}</span>
          </div>
          <div>
            <p>Valor bruto total da pesquisa:</p>
            <span>
              {`R$ ${parseFloat(String(totalGrossValue))
                .toFixed(2)
                .replace('.', ',')
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`}
            </span>
          </div>
          <div>
            <p>Valor líquido total da pesquisa:</p>
            <span>
              {`R$ ${parseFloat(String(totalNetValue))
                .toFixed(2)
                .replace('.', ',')
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`}
            </span>
          </div>
          <div>
            <p>Valor disponível para retirada: </p>
            <span>
              {`R$ ${parseFloat(String(totalAvailableValue))
                .toFixed(2)
                .replace('.', ',')
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`}
            </span>
          </div>
          <div>
            <p>Valor total retirado: </p>
            <span>
              {`R$ ${parseFloat(String(totalRecievedValue))
                .toFixed(2)
                .replace('.', ',')
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`}
            </span>
          </div>
        </TotalsContainer>
        <TableContent>
          <Table<IReportsProps>
            exportList
            defaultNumberOfRows={50}
            columns={[
              {
                title: 'Nome',
                type: 'string',
                orderable: true,
                props: ['name'],
                cssTitle: {
                  justifyContent: 'flex-start',
                  textAlign: 'left',
                },
                cssProps: {
                  width: '2%',
                  paddingRight: '8px',
                  fontFamily: 'sans-serif',
                },
                cssText: {
                  paddingLeft: '8px',
                },
              },
              {
                display: false,
                title: 'CPF',
                type: 'string',
                orderable: true,
                props: ['ssn'],
                cssTitle: {
                  justifyContent: 'flex-start',
                  textAlign: 'left',
                  display: 'none',
                },
                cssProps: {
                  width: '2%',
                  paddingRight: '8px',
                  fontFamily: 'sans-serif',
                  display: 'none',
                },
                cssText: {
                  paddingLeft: '8px',
                },
              },
              {
                delimiter: '3',
                title: 'Data ',
                type: 'datetime',

                // orderable: true,
                props: ['date'],
                cssTitle: {
                  justifyContent: 'flex-start',
                  textAlign: 'center',
                },
                cssProps: {
                  width: '2%',
                  paddingRight: '8px',
                  paddingLeft: '16px',
                  fontFamily: 'sans-serif',
                },
                cssText: {
                  paddingLeft: '8px',
                },
              },
              {
                delimiter: '3',
                title: 'Criado em ',
                type: 'datetime',

                // orderable: true,
                props: ['created_at'],
                cssTitle: {
                  justifyContent: 'flex-start',
                  textAlign: 'center',
                },
                cssProps: {
                  width: '2%',
                  paddingRight: '8px',
                  paddingLeft: '16px',
                  fontFamily: 'sans-serif',
                },
                cssText: {
                  paddingLeft: '8px',
                },
              },
              {
                delimiter: '3',
                title: 'Valor Bruto ',
                type: 'currency',
                // orderable: true,
                props: ['grossValue'],
                cssTitle: {
                  justifyContent: 'flex-start',
                  textAlign: 'center',
                },
                cssProps: {
                  width: '2%',
                  paddingRight: '8px',
                  paddingLeft: '16px',
                  fontFamily: 'sans-serif',
                },
                cssText: {
                  paddingLeft: '8px',
                },
              },
              {
                delimiter: '3',
                title: 'Valor Liquido',
                type: 'currency',
                // orderable: true,
                props: ['netValue'],
                cssTitle: {
                  justifyContent: 'flex-start',
                  textAlign: 'center',
                },
                cssProps: {
                  width: '2%',
                  paddingRight: '8px',
                  paddingLeft: '16px',
                  fontFamily: 'sans-serif',
                },
                cssText: {
                  paddingLeft: '8px',
                },
              },
              {
                delimiter: '4',
                title: 'Tipo Pagamento',
                type: 'string',
                // orderable: true,
                props: ['payment_type'],
                cssTitle: {
                  justifyContent: 'flex-start',
                  textAlign: 'center',
                },
                cssProps: {
                  width: '2%',
                  paddingRight: '8px',
                  paddingLeft: '16px',
                  fontFamily: 'sans-serif',
                },
                cssText: {
                  paddingLeft: '8px',
                },
              },
              // {
              //   delimiter: '4',
              //   title: 'Disponível para retirada?',
              //   type: 'string',
              //   // orderable: true,
              //   props: ['isAvailable'],
              //   cssTitle: {
              //     justifyContent: 'flex-start',
              //     textAlign: 'center',
              //   },
              //   cssProps: {
              //     width: '2%',
              //     paddingRight: '8px',
              //     paddingLeft: '16px',
              //     fontFamily: 'sans-serif',
              //   },
              //   cssText: {
              //     paddingLeft: '8px',
              //   },
              //   renderItem: (row) => {
              //     if (row.isAvailable) {
              //       return (
              //         <div className="center">
              //           <FaCheck color="#286738" size={20} />
              //         </div>
              //       );
              //     }
              //     return (
              //       <div className="center">
              //         <FaMinusCircle color="#fcbb02" size={20} />
              //       </div>
              //     );
              //   },
              // },
              {
                delimiter: '5',
                title: 'Já retirado',
                type: 'string',
                // orderable: true,
                props: ['retrieved'],
                cssTitle: {
                  justifyContent: 'flex-start',
                  textAlign: 'center',
                },
                cssProps: {
                  width: '2%',
                  paddingRight: '8px',
                  paddingLeft: '16px',
                  fontFamily: 'sans-serif',
                },
                cssText: {
                  paddingLeft: '8px',
                },
                renderItem: (row) => {
                  return (
                    <button
                      type="button"
                      className="center"
                      onClick={() => handleChangeRetreivedFlag(row.id)}
                    >
                      {row.retrieved ? (
                        <FaCheck color="#286738" size={20} />
                      ) : (
                        <FaMinusCircle color="#fcbb02" size={20} />
                      )}
                    </button>
                  );
                },
              },
              {
                delimiter: '3',
                title: 'Disponível em ',
                type: 'datetime',

                // orderable: true,
                props: ['available_in'],
                cssTitle: {
                  justifyContent: 'flex-start',
                  textAlign: 'center',
                },
                cssProps: {
                  width: '2%',
                  paddingRight: '8px',
                  paddingLeft: '16px',
                  fontFamily: 'sans-serif',
                },
                cssText: {
                  paddingLeft: '8px',
                },
              },
              {
                delimiter: '5',
                title: 'Data Retirada',
                type: 'string',
                // orderable: true,
                props: ['retrieved_date'],
                cssTitle: {
                  justifyContent: 'flex-start',
                  textAlign: 'center',
                },
                cssProps: {
                  width: '2%',
                  paddingRight: '8px',
                  paddingLeft: '16px',
                  fontFamily: 'sans-serif',
                },
                cssText: {
                  paddingLeft: '8px',
                },
                renderItem: (row) => {
                  return (
                    <InputRetrieved>
                      <input
                        type="date"
                        value={`${row.retrieved_date}` || '--/--/----'}
                        onChange={(e) =>
                          handleChangeRetreivedDate(row.id, e.target.value)
                        }
                        placeholder=""
                      />
                    </InputRetrieved>
                  );
                },
              },
              {
                display: false,
                delimiter: '3',
                title: 'ID',
                type: 'string',
                // orderable: true,
                props: ['id_transaction'],
                cssTitle: {
                  justifyContent: 'flex-start',
                  textAlign: 'center',
                  display: 'none',
                },
                cssProps: {
                  width: '2%',
                  paddingRight: '8px',
                  paddingLeft: '16px',
                  fontFamily: 'sans-serif',
                  display: 'none',
                },
                cssText: {
                  paddingLeft: '8px',
                },
              },
            ]}
            rows={[
              ...reports
                .sort(
                  (a, b) =>
                    new Date(a.available_in).getTime() -
                    new Date(b.available_in).getTime()
                )
                .map((item) => ({
                  ...item,
                })),
            ]}
          />
        </TableContent>
      </TableContainer>
    </Container>
  );
};

export default Reports;

import ProjectProps from './dtos/ProjectProps';

const Project: ProjectProps = {
  id_place: '1c93faec-962c-4606-a9e5-dc74c7531a29',
  primary_color: '#286738',
  secondary_color: '#cb5223',
  login_background_img: 'https://app-reinaldo.s3.amazonaws.com/fundo_tenis.jpg',
  logo_url: 'https://app-reinaldo.s3.amazonaws.com/square_logo.png',
  api_url: 'https://reinaldo.pluma.tech',
};

export default Project;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .gobackButton {
    width: 35px;
    height: 35px;
    background: none;
    border: none;
  }
`;
export const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: #fff;
  flex-direction: column;
  border: 1px solid #dfe0eb;
  border-radius: 8px;
`;

export const TableContent = styled.div`
  background: #fff;
  color: #505050;
  border-radius: 15px;
  /* padding: 1rem; */

  .actionsContainer {
    .MuiIconButton-root {
      padding: 10px !important;
      /* Update colors in light.ts and dark.ts */
      background-color: #f2f2f2 !important;
    }

    .MuiButtonBase-root {
      .MuiIconButton-root {
        padding: 10px !important;
        background-color: #f2f2f2 !important;
      }
    }
  }
  button {
    border: 0;
    background: transparent;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .render-number,
  .render-string {
    font-weight: 400;
    line-height: 18px;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    color: #505050;
    padding: 0 8px;
  }
  .render_checkBox {
    font-weight: 400;
    line-height: 18px;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    color: #505050;
    padding: 0 8px;
  }

  .render-number {
    text-align: right;
    padding: 0 8px;
  }

  .detailTableWrap {
    display: flex;
    align-items: center;
    margin-bottom: 0.7rem;

    p {
      margin-left: 0.7rem;
    }
  }
`;

import React, { useCallback, useState, useEffect } from 'react';
import { Chart } from 'react-google-charts';
import { Container, Content } from './styles';

interface PageProps {
  responsesYes: number;
  responsesNo: number;
}

const PieChart: React.FC<PageProps> = ({ responsesYes, responsesNo }) => {
  const [options, setOptions] = useState({
    title: 'Gráfico de Pizza',
    is3D: true,
    slices: {
      0: { color: '#286738' },
      1: { color: '#cb5223' },
    },
    animation: {
      startup: true,
      easing: 'linear',
      duration: 1500,
    },
  });
  const [data, setData] = useState([
    ['sim', 'não'],
    ['Respostas sim', responsesYes],
    ['Respostas não', responsesNo],
  ]);

  useEffect(() => {}, []);

  return (
    <Container>
      <Content>
        <Chart
          width="500px"
          height="300px"
          chartType="PieChart"
          data={data}
          options={options}
        />
      </Content>
    </Container>
  );
};

export default PieChart;

interface AppColors {
  black: string;
  blue: string;
  green: string;
  pink: string;
  white: string;
  red: string;
  darkGrey: string;
}

const colors: AppColors = {
  black: '#000',
  blue: '#0A2D64',
  green: '#74B55A',
  pink: '#CB2A8B',
  white: '#fff',
  red: '#c53030',
  darkGrey: '#999',
};

export default colors;

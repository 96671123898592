/* eslint-disable react/no-array-index-key */
import React from 'react';
import { FaBars, FaPowerOff, FaTimes } from 'react-icons/fa';

import Modal from 'components/Modal';
import MenuItemComponent from 'components/MenuItem';

import menus from 'routes/menus';
import { useAuth, useMenu, usePlace } from 'context';
import { useWindowSize } from 'hooks/windowSize';

import { Container, Sidebar, Main, AppBar } from './styled';

const AppWrapper: React.FC = ({ children }) => {
  const [openModal, setOpenModal] = React.useState(false);

  const { showMenu, toggleMenu, pageTitle } = useMenu();

  const { signOut } = useAuth();

  const [windowSize] = useWindowSize();

  const { place, loading } = usePlace();

  const toggleModal = React.useCallback(
    () => setOpenModal((current) => !current),
    []
  );

  return (
    <Container>
      {!loading && (
        <>
          <Modal
            openModal={openModal}
            toggleModal={toggleModal}
            onClickConfirm={signOut}
            title="Sair"
            width="400px"
          >
            <h3>Deseja realmente sair do sistema?</h3>
          </Modal>
          <AppBar showMenu primary_color={place.primaryColor}>
            <FaBars size={32} onClick={toggleMenu} className="appbar-bars" />
            <h3>{pageTitle}</h3>
            <FaPowerOff size={32} onClick={toggleModal} />
          </AppBar>
          <Sidebar
            showMenu={showMenu || windowSize >= 1024}
            primary_color={place.primaryColor}
          >
            {windowSize < 1024 ? (
              <div className="menu-icon-container">
                {showMenu ? (
                  <FaTimes size={32} onClick={toggleMenu} />
                ) : (
                  <FaBars
                    size={32}
                    onClick={toggleMenu}
                    className="menu-bars"
                  />
                )}
              </div>
            ) : (
              <div className="logo-container">
                <img src={place.logo} className="logo" alt="logo" />
              </div>
            )}
            {menus
              .filter((menu) => !menu.hide)
              // .filter((item) => place.options.indexOf(String(item.label)) > -1)
              .map(({ label = '', icon, iconSize, path, subMenus }, index) => (
                <MenuItemComponent
                  key={index}
                  label={label}
                  icon={icon}
                  iconSize={iconSize}
                  path={path}
                  showMenu={showMenu || windowSize >= 1024}
                  subMenus={subMenus}
                />
              ))}
          </Sidebar>
          <Main
            showMenu={showMenu || windowSize >= 1024}
            primary_color={place.primaryColor}
          >
            {children}
          </Main>
        </>
      )}
    </Container>
  );
};

export default AppWrapper;

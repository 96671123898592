import styled from 'styled-components';
import { shade } from 'polished';
import white_label from 'white_label';

const { secondary_color } = white_label();
export const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: #fff;
  flex-direction: column;
  border: 1px solid #dfe0eb;
  border-radius: 8px;

  .addClientButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    background: ${secondary_color};
    border: 0;
    transition: 0.3s all;
    span {
      margin-left: 20px;
      color: #fff;
    }
    &:hover {
      background: ${shade(0.2, secondary_color)};
    }
  }

  .pagination-container {
    display: flex;
    justify-content: flex-end;
    margin: 22px 22px 22px 0;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .pagination-options {
      display: flex;
      margin-right: 90px;
      align-items: center;

      @media (max-width: 768px) {
        margin-left: 12px;
      }

      p {
        color: #7a8799;
      }

      select {
        margin-left: 22px;
        border: none;
        background-color: #fff;
        color: #7a8799;
      }
    }
  }

  .table-container {
    overflow-y: auto;
  }
  button {
    border: 0;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .options {
    svg {
      & + svg {
        margin-left: 12px;
      }
    }
  }
`;
export const TableContent = styled.div`
  background: #fff;
  color: #505050;
  border-radius: 15px;
  /* padding: 1rem; */

  .actionsContainer {
    .MuiIconButton-root {
      padding: 10px !important;
      /* Update colors in light.ts and dark.ts */
      background-color: #f2f2f2 !important;
    }

    .MuiButtonBase-root {
      .MuiIconButton-root {
        padding: 10px !important;
        background-color: #f2f2f2 !important;
      }
    }
  }

  .render-number,
  .render-string {
    font-weight: 400;
    line-height: 18px;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    color: #505050;
    padding: 0 8px;
  }
  .render_checkBox {
    font-weight: 400;
    line-height: 18px;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    color: #505050;
    padding: 0 8px;
  }

  .render-number {
    text-align: right;
    padding: 0 8px;
  }

  .detailTableWrap {
    display: flex;
    align-items: center;
    margin-bottom: 0.7rem;

    p {
      margin-left: 0.7rem;
    }
  }
`;

import styled from 'styled-components';
import { shade } from 'polished';
import white_label from 'white_label';

const { secondary_color } = white_label();

export const Container = styled.div`
  display: flex;
  width: 98%;
  flex-direction: column;
  border: 0px solid #dfe0eb;
  border-radius: 8px;

  .addPackageButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    background: ${secondary_color};
    border: 0;
    transition: 0.3s all;
    span {
      margin-left: 20px;
      color: #fff;
    }

    &:hover {
      background: ${shade(0.2, secondary_color)};
    }
  }

  .pagination-container {
    display: flex;
    justify-content: flex-end;
    margin: 22px 22px 22px 0;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .pagination-options {
      display: flex;
      margin-right: 90px;
      align-items: center;

      @media (max-width: 768px) {
        margin-left: 12px;
      }

      p {
        color: #7a8799;
      }

      select {
        margin-left: 22px;
        border: none;
        background-color: #fff;
        color: #7a8799;
      }
    }
  }

  .table-container {
    overflow-y: auto;
  }
  button {
    border: 0;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .options {
    svg {
      & + svg {
        margin-left: 12px;
      }
    }
  }
`;

export const CurrentConfig = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 150px;
  width: 100%;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  div {
    width: 70%;
    input {
      border: 2px solid #ccc;
      height: 10px;
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  height: 90%;
`;

export const Content = styled.div`
  height: 100%;
  margin-top: 40px;
  .sectionFields {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 90%;

    .photoContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px 0;
      height: 64px;
      img {
        position: absolute;
        height: 140px;
        width: 140px;
        object-fit: cover;
        border-radius: 100px;
        z-index: 1;
        transition: 0.2s;
        border: 1px solid #ccc;
        &:hover {
          opacity: 0.5;
        }
      }

      &:hover {
        cursor: pointer;
      }
      .photoInput {
        height: 140px;
        width: 140px;
        background: red;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        border-radius: 100px;
        opacity: 0;
        position: absolute;
        z-index: 3;
        &:hover {
          cursor: pointer;
          margin-left: 20px;
        }
      }
    }
  }
`;

/* eslint-disable no-plusplus */
import React, { useEffect, useState, useCallback, useRef } from 'react';

import Webcam from 'react-webcam';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import {
  MdSearch,
  MdCamera,
  MdCameraAlt,
  MdCancel,
  MdRefresh,
} from 'react-icons/md';
import * as yup from 'yup';

import Select from 'components/ReactSelect';
import Input from 'components/Input';
import Button from 'components/Button';
import FullLoader from 'components/FullLoader';
import { cpfMask, cellPhoneMask, cepMask } from 'masks';
import api from 'services/api';
import { useMenu } from 'context';
import { getAddressByCEP } from 'services/cep';

import { format } from 'date-fns';
import { Container } from './styles';

interface LastStepProps {
  name: string;
  email: string;
  ssn: string;
  cellphone: string;
  zipCode: string;
  street: string;
  number: string;
  complement: string;
  district: string;
  city: string;
  state: string;
  password: string;
  birthDate: string;
  sex: {
    label: string;
    value: string;
  };
}
interface UserTypes {
  value: string;
  label: string;
}

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().required(),
  ssn: yup.string().required(),
  cellphone: yup.string().required(),
  zipCode: yup.string().required(),
  street: yup.string().required(),
  number: yup.string().required(),
  complement: yup.string().required(),
  district: yup.string().required(),
  birthDate: yup.date().required(),
});

const AddUser: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [takePhoto, setTakePhoto] = useState(false);
  const [zipCodeLoading, setZipCodeLoading] = useState(false);
  const [userPhoto, setUserPhoto] = useState<string>('');
  const [selectTypeUser, setSelectTypeUser] = useState<string>('');
  const [selectSex, setSelectSex] = useState<string>('');
  const webcamRef = useRef<Webcam>(null);
  const history = useHistory();
  const [userTypes, setUserTypes] = useState<UserTypes[]>([]);
  const { setPageTitle } = useMenu();

  useEffect(() => {
    setPageTitle('Novo usuário');
  }, [setPageTitle]);

  useEffect(() => {
    api.get(`/users/findAllUserTypes`).then((response: any) => {
      const tempUserTypes: UserTypes[] = [];
      response.data.map((item: any) => {
        tempUserTypes.push({
          value: item.id,
          label: item.name,
        });
        return null;
      });
      setUserTypes(tempUserTypes);
    });
  }, []);

  const onSubmit = useCallback(
    ({
      name,
      email,
      ssn,
      cellphone,
      zipCode,
      street,
      number,
      complement,
      district,
      password,
      birthDate,
      city,
      state,
    }: LastStepProps) => {
      console.log(selectSex);
      const userData = {
        name,
        email,
        ssn,
        password,
        id_place: process.env.REACT_APP_ID_PLACE,
        user_type: selectTypeUser,
        is_monthly: false,
        cellphone,
        zipCode,
        street,
        number,
        complement,
        district,
        city,
        state,
        gender: selectSex,
        birth_date: `${format(
          new Date(birthDate),
          'yyyy-MM-dd'
        )}T18:25:43.511Z`,
        notification_id: '',
      };

      setLoading(true);
      api
        .post('/users', userData)
        .then((response) => {
          toast.success('Cliente criado com sucesso!');
          history.push(`/usuarios`);
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [history, selectTypeUser, selectSex]
  );

  const { register, handleSubmit, setValue, getValues, errors, control } =
    useForm<LastStepProps>({
      resolver: yupResolver(schema),
    });

  const findZipCode = useCallback(async () => {
    setZipCodeLoading(true);
    const zipCode = getValues('zipCode');
    try {
      const { data } = await getAddressByCEP(zipCode.replace('-', ''));

      setValue('street', data.street);
      setValue('district', data.neighborhood);
      setValue('city', data.city);
      setValue('state', data.state);
      setZipCodeLoading(false);
    } catch (error) {
      toast.error('CEP não encontrado!');
      setZipCodeLoading(false);
    }
  }, [getValues, setValue]);

  const optionsSelectSex = [
    { value: '1', label: 'Masculino' },
    { value: '2', label: 'Feminino' },
    { value: '3', label: 'Outros' },
  ];
  return (
    <Container isEnabled={takePhoto}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          placeholder="Nome completo"
          name="name"
          autoCapitalize="words"
          register={register}
          error={!!errors.name?.message}
        />
        <Input
          placeholder="Data de nascimento"
          type="date"
          name="birthDate"
          autoCapitalize="words"
          register={register}
          error={!!errors.birthDate?.message}
        />
        <Select
          className="select"
          options={optionsSelectSex}
          closeMenuOnSelect
          onChange={(e) => setSelectSex(e?.label || '')}
          name="sex"
          isClearable={false}
          placeholder="Sexo"
          register={register}
          // error={!!errors.sex?.message}
        />
        <Input
          placeholder="E-mail"
          name="email"
          register={register}
          error={!!errors.email?.message}
        />
        <Input
          placeholder="CPF"
          name="ssn"
          mask={cpfMask}
          control={control}
          error={!!errors.ssn?.message}
        />
        <Input
          placeholder="Celular"
          name="cellphone"
          mask={cellPhoneMask}
          control={control}
          error={!!errors.cellphone?.message}
        />
        <Select
          className="select"
          options={userTypes}
          closeMenuOnSelect
          onChange={(e) => setSelectTypeUser(e?.value || '')}
          name="courts"
          isClearable={false}
          placeholder="Tipo de usuário"
        />
        <div className="zipCodeContainer">
          <Input
            placeholder="CEP"
            name="zipCode"
            mask={cepMask}
            control={control}
            error={!!errors.zipCode?.message}
          />
          <button type="button" onClick={findZipCode}>
            <MdSearch size={20} color="#fff" />
          </button>
        </div>
        <Input
          placeholder="Logradouro"
          name="street"
          register={register}
          error={!!errors.street?.message}
        />

        <Input
          placeholder="Número"
          name="number"
          register={register}
          error={!!errors.number?.message}
        />
        <Input
          placeholder="Complemento"
          name="complement"
          register={register}
          error={!!errors.complement?.message}
        />
        <Input
          placeholder="Bairro"
          name="district"
          register={register}
          error={!!errors.district?.message}
        />
        <Input
          placeholder="Cidade"
          name="city"
          register={register}
          error={!!errors.city?.message}
        />
        <Input
          placeholder="UF"
          name="state"
          register={register}
          error={!!errors.state?.message}
        />
        <Input
          placeholder="Senha"
          name="password"
          register={register}
          error={!!errors.password?.message}
        />

        <Button type="submit">{loading ? 'Aguarde...' : 'Finalizar'}</Button>
      </form>
    </Container>
  );
};

export default AddUser;

import React, { useCallback, useState, useEffect, useMemo } from 'react';
import * as yup from 'yup';
import { addDays, addMonths } from 'date-fns';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Court } from 'interfaces/courts';
import CurrencyInput from 'components/CurrencyInput';
import Input from 'components/Input';
import Button from 'components/Button';
import ModalHeader from 'components/ModalHeader';
import api from 'services/api';
import { toast } from 'react-toastify';
import Select from 'components/ReactSelect';
import formatHour from 'utils/formatHour';
import formatDateUS from 'utils/formatDateUS';
import { Container, Content } from './styles';

interface SingleEvent {
  id: string;
  price: string;
  start_date: string;
  finish_date: string;
  canceled: boolean;
  created_at: string;
  updated_at: string;
  id_user: string;
  court_name: string;
  id_court: string;
  created_sequence: string;
  id_transaction: string;
  observation: string;
  cellphone: string;
  number_of_players: string;
}

interface PageProps {
  closeModal: () => void;
  closeModalAndRefresh: () => void;
  dataEvent: SingleEvent;
}

interface EventModalProps {
  title: string;
  price: string;
  starthour: string;
  finishour: string;
  players: number;
}
interface SelectCourtsProps {
  value: string;
  label: string;
}
const schema = yup.object().shape({
  title: yup.string().required(),
  price: yup.string().required(),
  players: yup.number().required(),
});

const AddEventModal: React.FC<PageProps> = ({
  closeModal,
  closeModalAndRefresh,
  dataEvent,
}) => {
  const [loading, setLoading] = useState(false);
  const [dataStartEvent, setDataStartEvent] = useState('');
  const [startHour, setstartHour] = React.useState('');
  const [finishHour, setfinishHour] = React.useState('');
  const [selectedCourts, setSelectedCourts] = useState<any>({} as any);
  const [courts, setCourts] = useState<Court[]>([]);

  const { register, handleSubmit, setValue, control, errors } =
    useForm<EventModalProps>({
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    if (dataEvent) {
      setValue('title', dataEvent.observation.replace(' - App', ''));
      setValue('price', Number(dataEvent.price));
      setValue('players', Number(dataEvent.number_of_players));
      const dateEvent = new Date(dataEvent.start_date);
      setDataStartEvent(formatDateUS(addMonths(dateEvent, 1), '-'));
      setstartHour(`${formatHour(dateEvent.getHours())}`);
      setfinishHour(`${formatHour(dateEvent.getHours() + 1)}`);
      setSelectedCourts([
        { label: dataEvent.court_name, value: dataEvent.id_court },
      ]);
    } else {
      setSelectedCourts([]);
      const dataTime = new Date();
      setstartHour(`${formatHour(dataTime.getHours())}`);
      setfinishHour(`${formatHour(dataTime.getHours() + 1)}`);
    }

    api
      .get(`/courts/findAll?id_place=${process.env.REACT_APP_ID_PLACE}&page=1`)
      .then((response) => {
        setCourts(response.data);
      });
  }, [setstartHour, dataEvent, setValue]);

  const onSubmit = useCallback(
    ({ title, price, players }: EventModalProps) => {
      const datestart = addDays(new Date(dataStartEvent), 1);
      const start_date = new Date(
        datestart.setHours(Number(startHour.split(':')[0]))
      );
      const finish_date = new Date(
        datestart.setHours(Number(finishHour.split(':')[0]))
      );
      if (
        dataStartEvent !== '' &&
        selectedCourts !== null &&
        startHour !== '' &&
        finishHour !== ''
      ) {
        const appointment = {
          email: '',
          id_user: '',
          paid: true,
          user_name: title,
          finalPrice: price.replace('R$', '').replace(',', '.').trim(),
          id_transaction: '',
          id_place: process.env.REACT_APP_ID_PLACE,
        };
        const hours: any[] = [];

        selectedCourts.map((item: SelectCourtsProps) => {
          hours.push({
            id: '123',
            id_court: item.value,
            start_date,
            finish_date,
            number_of_players: players,
            court_name: item.label,
          });
          return null;
        });
        console.log('aqui');
        api
          .post('/appointments/create', {
            appointment,
            hours,
            materials: null,
          })
          .then((response) => {
            if (response.status === 200) {
              toast.success('Reserva criada com sucesso');
              closeModalAndRefresh();
            }
          })
          .catch(() => {
            toast.error('Este horário já está reservado!');
          });
      } else {
        toast.error('Informe todos os campos para continuar');
      }
    },

    [
      dataStartEvent,
      selectedCourts,
      startHour,
      finishHour,
      closeModalAndRefresh,
    ]
  );

  // const onSubmit = useCallback(
  //   ({ title, price, players }: EventModalProps) => {
  //     const datestart = addDays(new Date(dataStartEvent), 1);
  //     const start_date = new Date(
  //       datestart.setHours(Number(startHour.split(':')[0]))
  //     );
  //     const finish_date = new Date(
  //       datestart.setHours(Number(finishHour.split(':')[0]))
  //     );
  //     if (
  //       dataStartEvent !== '' &&
  //       selectedCourts !== null &&
  //       startHour !== '' &&
  //       finishHour !== ''
  //     ) {
  //       if (dataEvent) {
  //         const data = {
  //           id: dataEvent.id,
  //           id_court: selectedCourts.value,
  //           observation: title,
  //           start_date,
  //           finish_date,
  //           price: price.replace('R$', '').replace(',', '.').trim(),
  //           number_of_players: players,
  //         };
  //         setLoading(true);
  //         api.put(`/appointments/editAppointment`, data).then(() => {
  //           toast.success('Reserva atualizada com sucesso');
  //           closeModalAndRefresh();
  //         });
  //       } else {
  //         const appointment = {
  //           email: '',
  //           id_user: '',
  //           paid: true,
  //           user_name: title,
  //           finalPrice: price.replace('R$', '').replace(',', '.').trim(),
  //           id_transaction: '',
  //           id_place: process.env.REACT_APP_ID_PLACE,
  //         };
  //         const hours: any[] = [];

  //         selectedCourts.map((item: SelectCourtsProps) => {
  //           hours.push({
  //             id: '123',
  //             id_court: item.value,
  //             start_date,
  //             finish_date,
  //             number_of_players: players,
  //             court_name: item.label,
  //           });
  //           return null;
  //         });

  //         api
  //           .post('/appointments/create', {
  //             appointment,
  //             hours,
  //             materials: null,
  //           })
  //           .then((response) => {
  //             if (response.status === 200) {
  //               toast.success('Reserva criada com sucesso');
  //               closeModalAndRefresh();
  //             }
  //           })
  //           .catch(() => {
  //             toast.error('Este horário já está reservado!');
  //           });
  //       }
  //     } else {
  //       toast.error('Informe todos os campos para continuar');
  //     }
  //   },

  //   [
  //     dataEvent,
  //     dataStartEvent,
  //     selectedCourts,
  //     startHour,
  //     finishHour,
  //     closeModalAndRefresh,
  //   ]
  // );

  // const onSubmit = useCallback(
  //   ({ title, price, players }: EventModalProps) => {
  //     const datestart = addDays(new Date(dataStartEvent), 1);
  //     const start_date = new Date(
  //       datestart.setHours(Number(startHour.split(':')[0]))
  //     );
  //     const finish_date = new Date(
  //       datestart.setHours(Number(finishHour.split(':')[0]))
  //     );
  //     if (
  //       dataStartEvent !== '' &&
  //       selectedCourts !== null &&
  //       startHour !== '' &&
  //       finishHour !== ''
  //     ) {
  //       if (dataEvent) {
  //         const data = {
  //           id: dataEvent.id,
  //           id_court: selectedCourts.value,
  //           observation: title,
  //           start_date,
  //           finish_date,
  //           price: price.replace('R$', '').replace(',', '.').trim(),
  //           number_of_players: players,
  //         };
  //         setLoading(true);
  //         api.put(`/appointments/editAppointment`, data).then(() => {
  //           toast.success('Reserva atualizada com sucesso');
  //           closeModalAndRefresh();
  //         });
  //       } else {
  //         const appointment = {
  //           email: '',
  //           id_user: '',
  //           paid: true,
  //           user_name: title,
  //           finalPrice: price.replace('R$', '').replace(',', '.').trim(),
  //           id_transaction: '',
  //           id_place: process.env.REACT_APP_ID_PLACE,
  //         };
  //         const hours: any[] = [];

  //         selectedCourts.map((item: SelectCourtsProps) => {
  //           hours.push({
  //             id: '123',
  //             id_court: item.value,
  //             start_date,
  //             finish_date,
  //             number_of_players: players,
  //             court_name: item.label,
  //           });
  //           return null;
  //         });

  //         api
  //           .post('/appointments/create', {
  //             appointment,
  //             hours,
  //             materials: null,
  //           })
  //           .then((response) => {
  //             if (response.status === 200) {
  //               toast.success('Reserva criada com sucesso');
  //               closeModalAndRefresh();
  //             }
  //           })
  //           .catch(() => {
  //             toast.error('Este horário já está reservado!');
  //           });
  //       }
  //     } else {
  //       toast.error('Informe todos os campos para continuar');
  //     }
  //   },

  //   [
  //     dataEvent,
  //     dataStartEvent,
  //     selectedCourts,
  //     startHour,
  //     finishHour,
  //     closeModalAndRefresh,
  //   ]
  // );

  const courtOptions = useMemo(() => {
    const arrayToReturn: SelectCourtsProps[] = [];
    courts.map((item) => {
      arrayToReturn.push({ value: item.id, label: item.courtname });
      return null;
    });
    return arrayToReturn;
  }, [courts]);
  return (
    <Container>
      <ModalHeader
        title={`${
          dataEvent ? 'Editar reserva avulsa' : 'Criar reserva avulsa'
        }`}
        closeModal={closeModal}
      />
      <Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            title="Titulo"
            label="Titulo do evento"
            name="title"
            register={register}
            error={!!errors.title?.message}
          />
          <Input
            title="Data"
            label="Data Inicial"
            type="date"
            className="inputDateTime flexItem"
            name="start"
            value={dataStartEvent}
            onChange={(e) => setDataStartEvent(e.target.value)}
          />
          <div className="sectionDate">
            <Input
              title="Hora inicio"
              label="Hora Inicio"
              type="time"
              className="inputDateTime flexItem"
              name="starthour"
              value={startHour}
              onChange={(e) => setstartHour(e.target.value)}
            />
            <Input
              title="Hora Final"
              label="Hora Final"
              type="time"
              className="inputDateTime flexItem"
              name="finishour"
              value={finishHour}
              onChange={(e) => setfinishHour(e.target.value)}
            />
          </div>
          <CurrencyInput
            control={control}
            title="preço"
            label="Preço"
            name="price"
            prefix="R$ "
            register={register}
            error={!!errors.price?.message}
          />
          <Select
            // components={animatedComponents}
            label="Quadras"
            placeholder="Selecione"
            isMulti={!dataEvent}
            className="select"
            options={courtOptions}
            value={selectedCourts}
            onChange={(item: any) => setSelectedCourts(item)}
            name="courts"
          />
          <Input
            title="Número de jogadores"
            label="0"
            type="number"
            className="marginButton flexItem"
            name="players"
            register={register}
            error={!!errors.players?.message}
          />
          <Button type="submit">{loading ? 'Aguarde...' : 'Salvar'}</Button>
        </form>
      </Content>
    </Container>
  );
};

export default AddEventModal;

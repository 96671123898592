import React from 'react';
import axios from 'axios';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import Input from 'components/Input';
import Button from 'components/Button';

import { useAuth } from 'context';

import api from 'services/api';
import white_label from 'white_label';
import { Container } from './styled';

interface FormProps {
  email: string;
}

interface SendEmailProps {
  emailSent: (sent: boolean) => void;
}

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

const SendEmail = ({ emailSent }: SendEmailProps) => {
  const [loading, setLoading] = React.useState(false);

  const history = useHistory();

  const { logo_url } = white_label();

  const { register, handleSubmit, errors } = useForm<FormProps>({
    resolver: yupResolver(schema),
  });

  const { isAuthenticated } = useAuth();

  const onSubmit = React.useCallback(
    async ({ email }: FormProps) => {
      setLoading(true);
      await api
        .post(`/password/forgot`, { email })
        .then((response) => {
          history.goBack();
          toast.success('E-mail enviado, verifique sua caixa de entrada!');
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          toast.error('E-mail não encontrado!');
        });
    },
    [history]
  );

  React.useEffect(() => {
    if (isAuthenticated) history.push('/');
  }, [isAuthenticated, history]);

  return (
    <Container>
      <div className="bg" />
      <div className="form">
        <div className="logo">
          <img src={logo_url} alt="logo" />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            label="Email"
            name="email"
            register={register}
            error={!!errors.email?.message}
          />
          <div className="signin-button-container">
            <Button type="submit">{loading ? 'Enviando...' : 'Enviar'}</Button>
          </div>
          <button
            type="button"
            className="text-button"
            onClick={history.goBack}
          >
            Voltar
          </button>
        </form>
      </div>
    </Container>
  );
};

export default SendEmail;

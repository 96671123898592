/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-plusplus */
/* eslint-disable func-names */
// prettier-ignore
import React, { useEffect, useCallback, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { User } from 'interfaces/user';
import Select from 'components/ReactSelect';
import Input from 'components/Input';
import Button from 'components/Button';
import ModalHeader from 'components/ModalHeader';
import FullLoader from 'components/FullLoader';
import api from 'services/api';
import { toast } from 'react-toastify';

import { Container, Content, SectionHour } from './styles';

interface PageProps {
  closeModal: () => void;
}

interface EventModalProps {
  initialHour: string;
  finishHour: string;
}
interface SelectCourtsProps {
  value: number;
  label: string;
}
const schema = yup.object().shape({
  initialHour: yup.string().required(),
  finishHour: yup.string().required(),

  createdOn: yup.date().default(function () {
    return new Date();
  }),
});

const MontlyEventModal: React.FC<PageProps> = ({ closeModal }) => {
  const [loading, setLoading] = useState(false);
  const [loadingCourts, setLoadingCourts] = useState(false);
  const [selectUser, setSelectUser] = useState('');
  const [selectDayOfWeek, setSelectDayOfWeek] = useState(-1);
  const [selectedCourt, setSelectedCourts] = useState<string>('');
  const [users, setUsers] = useState<User[]>([]);
  const [usersCombo, setUsersCombo] = useState<any[]>([]);
  const [courtsCombo, setCourtsCombo] = useState<any[]>([]);
  const [selectedHour, setSelectedHour] = useState<number>(-1);

  const [files, setFiles] = useState([]);
  const handleDrop = (acceptedFiles: any) =>
    setFiles(
      acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  const { register, handleSubmit, control, errors } = useForm<EventModalProps>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    api.get(`/users/findAll?limit=${2000}&offset=0`).then((response) => {
      setUsers(response.data.users);
      const tempUsers: any = [];
      response.data.users.map((item: any) => {
        tempUsers.push({ value: item.id, label: item.name });
        return null;
      });
      setUsersCombo(tempUsers);
    });
  }, []);

  useEffect(() => {
    setCourtsCombo([]);
    if (Number(selectDayOfWeek) >= 0) {
      setLoadingCourts(true);
      api
        .get(
          `/monthly/findAvailableHoursForCourt?dayOfWeek=${selectDayOfWeek}&hour=${selectedHour}`
        )
        .then((response) => {
          const tempCourts: any = [];
          response.data.map((item: any) => {
            tempCourts.push({ value: item.id, label: item.name });
            return null;
          });
          setCourtsCombo(tempCourts);
          setLoadingCourts(false);
        });
    }
  }, [selectDayOfWeek, selectedHour]);

  const handleChangeUser = (value: any) => {
    const user = value.value;
    setSelectUser(user);
  };
  const handleChangeDayOfWeek = (value: any) => {
    const day = value.value;
    setSelectDayOfWeek(Number(day));
  };
  const handleChangeCourt = (value: any) => {
    const court = value.value;
    setSelectedCourts(court);
  };
  const onSubmit = useCallback(
    ({ initialHour, finishHour }: EventModalProps) => {
      if (selectUser === '' || selectDayOfWeek < 0 || selectedCourt === '') {
        toast.error('Informe todos os campos para continuar');
      }

      const hours: Number[] = [];

      for (let i = Number(initialHour); i < Number(finishHour); i++) {
        hours.push(i);
      }

      const data = {
        id_user: selectUser,
        id_court: selectedCourt,
        week_day: selectDayOfWeek,
        hours,
      };
      api
        .post('/monthly/createMonthlyHoursOnWebSystem', data)
        .then(() => {
          toast.success('Mensalista criado com sucesso!');
          closeModal();
        })
        .catch(() => {
          toast.error('Mensalista já existe neste horário!');
        });
      return null;
    },
    [selectUser, selectDayOfWeek, selectedCourt, closeModal]
  );

  const optionsSelectDayWeek = [
    { value: '1', label: 'Segunda-feira' },
    { value: '2', label: 'Terça-feira' },
    { value: '3', label: 'Quarta-feira' },
    { value: '4', label: 'Quinta-feira' },
    { value: '5', label: 'Sexta-feira' },
    { value: '6', label: 'Sabado' },
    { value: '7', label: 'Domingo' },
  ];
  return (
    <Container>
      <ModalHeader title="Mensalista" closeModal={closeModal} />
      <Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Select
            label="Usuário"
            className="select"
            options={usersCombo}
            onChange={handleChangeUser}
          />
          <Select
            label="Dia"
            className="select"
            options={optionsSelectDayWeek}
            onChange={handleChangeDayOfWeek}
          />
          <SectionHour>
            <Input
              label="Hora inicial"
              type="text"
              name="initialHour"
              register={register}
              error={!!errors.initialHour?.message}
            />
            <Input
              label="Hora final"
              type="text"
              name="finishHour"
              register={register}
              error={!!errors.finishHour?.message}
            />
          </SectionHour>
          <Select
            label="Quadra"
            className="select"
            options={courtsCombo}
            onChange={handleChangeCourt}
          />
          <Button style={{ marginTop: 30, marginBottom: 200 }} type="submit">
            {loading ? 'Aguarde...' : 'Criar'}
          </Button>
        </form>
      </Content>
      <FullLoader show={loadingCourts} />
    </Container>
  );
};

export default MontlyEventModal;

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import api from 'services/api';
import { AccessToken, AuthData, Metadata, User } from 'interfaces';

import { signIn as ApiSignIn, signUp as ApiSignUp } from 'services/auth';

interface AuthContextData {
  step: number;
  loading: boolean;
  registerData: any;
  isAuthenticated: boolean;
  signIn: (data: AuthData) => Promise<void>;
  signUp: (data: User, meta?: Metadata) => Promise<void>;
  setStep: (value: number) => void;
  signOut: () => void;
  setRegisterData: React.Dispatch<React.SetStateAction<any>>;
}

interface UserTypes {
  value: string;
  label: string;
}

export const AuthContext = React.createContext<AuthContextData>(
  {} as AuthContextData
);

export const AuthProvider: React.FC = ({ children }) => {
  const [step, setStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [registerData, setRegisterData] = React.useState({} as any);
  const [userTypes, setUserTypes] = useState<UserTypes[]>([]);
  const [isAuthenticated, setIsAutenticated] = React.useState(
    () => !!localStorage.getItem('@ahaya-web:accessToken')
  );

  const history = useHistory();

  useEffect(() => {
    api.get(`/users/findAllUserTypes`).then((response: any) => {
      const tempUserTypes: UserTypes[] = [];
      response.data.map((item: any) => {
        tempUserTypes.push({
          value: item.id,
          label: item.name,
        });
        return null;
      });
      setUserTypes(tempUserTypes);
    });
  }, []);

  const saveTokenAndGo = React.useCallback(
    ({ token }: AccessToken) => {
      localStorage.setItem('@ahaya-web:accessToken', JSON.stringify(token));
      setIsAutenticated(true);
      history.push('/');
    },
    [history]
  );

  const signIn = React.useCallback(
    async (data: AuthData) => {
      setLoading(true);
      try {
        const response = await ApiSignIn(data);

        saveTokenAndGo(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    [saveTokenAndGo]
  );

  const signUp = React.useCallback(async (data: User, meta?: Metadata) => {
    try {
      const response = await ApiSignUp(data);
      localStorage.setItem(
        '@ahaya-web:accessToken',
        JSON.stringify(response.data.token)
      );
      if (meta?.onSuccess) meta.onSuccess();
    } catch (error) {
      if (meta?.onFailure) meta.onFailure();
    }
  }, []);

  const signOut = React.useCallback(() => {
    localStorage.removeItem('@ahaya-web:accessToken');
    setIsAutenticated(false);
    history.push('/signin');
  }, [history]);

  return (
    <AuthContext.Provider
      value={{
        step,
        signIn,
        signUp,
        signOut,
        setStep,
        loading,
        registerData,
        setRegisterData,
        isAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

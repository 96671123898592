import styled from 'styled-components';
import { shade } from 'polished';
import white_label from 'white_label';

const { secondary_color } = white_label();
interface TeacherItemProps {
  selected: boolean;
}

interface CartButtonProps {
  selected: boolean;
}

export const Container = styled.div`
  height: 90%;

  .Dropdown-control {
    height: 30px;
    margin-left: 30px;
    font-size: 10px;
    width: 130px;
  }

  .Dropdown-option {
    height: 30px;
    font-size: 10px;
  }
`;

export const Content = styled.div`
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
`;

export const TeacherItem = styled.button<TeacherItemProps>`
  background: ${(props) => (props.selected ? secondary_color : '#ccc')};
  width: 80px;
  height: 40px;
  border: 0;
  margin-left: 40px;
  color: ${(props) => (props.selected ? '#fff' : '#000')};
  transition: 0.3s all;

  &:hover {
    background: ${shade(0.2, secondary_color)};
    color: #fff;
  }
`;

export const TeacherClasses = styled.div`
  width: 100%;
  margin-top: 20px;
  h1 {
    color: #000;
    font-size: 14px;
  }
`;

export const HourItem = styled.div<CartButtonProps>`
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    color: #999;
    width: 150px;
  }

  button {
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => (props.selected ? '#ff0000' : '#006400')};
    height: 20px;
    width: 40px;
  }

  .statusBadge {
    margin-left: 25px;
  }
`;

export const SaveButton = styled.button`
  width: 90%;
  margin-left: 30px;
  margin-top: 20px;
  margin: 20px 0 30px 30px;
  height: 30px;
  background: ${secondary_color};
  transition: 0.3s all;
  color: #fff;
  border: 0;
  &:hover {
    background: ${shade(0.2, secondary_color)};
    color: #fff;
  }
`;

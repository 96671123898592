import React, { useEffect, useCallback, useState, useMemo } from 'react';

import { MdAddCircle, MdRemoveCircle } from 'react-icons/md';
import { Dropdown } from 'react-dropdown-now';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import 'react-dropdown-now/style.css';

import FullLoader from 'components/FullLoader';
import { PackagesProps } from 'interfaces/packages';
import api from 'services/api';
import { toast } from 'react-toastify';
import { Container, PaymentType, FinishButton, ExpiryButton } from './styles';

interface DropdownProps {
  id: string;
  value: string;
}

interface PageProps {
  id_user: string;
}

const PaymentData: React.FC<PageProps> = ({ id_user }) => {
  const [loading, setLoading] = useState(false);
  const [remainingClasses, setRemainingClasses] = useState(0);
  const [classes, setClasses] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [packages, setPackages] = useState<PackagesProps[]>([]);
  const [options, setOptions] = useState<DropdownProps[]>([]);
  const [finalPrice, setFinalPrice] = useState(0);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [paymentType, setPaymentType] = useState<string>('');
  const [useExpiry, setUseExpiry] = useState<String>('');
  const [expiryDate, setExpiryDate] = useState<Date>(new Date());
  const history = useHistory();

  useEffect(() => {
    api.get('/payers/findAllPackages').then((response) => {
      setPackages(response.data);
      api
        .get(`/payers/findRemainingPackages?id_user=${id_user}`)
        .then((response2) => {
          setRemainingClasses(response2.data);
        })
        .catch((error) => {});
    });
  }, [id_user]);

  useEffect(() => {
    const tempArray: DropdownProps[] = [];
    packages.map((item) => {
      const data: DropdownProps = {
        id: String(item.price),
        value: String(item.amount),
      };
      tempArray.push(data);
      return null;
    });

    setOptions(tempArray);
  }, [packages]);

  useEffect(() => {
    packages.map((item) => {
      if (item.amount === selectedAmount) {
        if (discount > 0) {
          setFinalPrice(
            Number(item.price) - (discount * Number(item.price)) / 100
          );
        } else {
          setFinalPrice(Number(item.price));
        }
      }
      return null;
    });
  }, [finalPrice, packages, classes, discount, selectedAmount]);

  const handleChangeClassesAmount = useCallback(
    (operation: string) => {
      if (operation === 'add') {
        setClasses(classes + 1);
      } else if (classes > 0) {
        setClasses(classes - 1);
      }
    },
    [classes]
  );

  const handleChangeDiscount = useCallback(
    (operation: string) => {
      if (operation === 'add') {
        setDiscount(discount + 1);
      } else if (discount > 0) {
        setDiscount(discount - 1);
      }
    },
    [discount]
  );

  const finishButtonDisabled = useMemo(() => {
    if (classes === 0 && paymentType === '') {
      return true;
    }
    return false;
  }, [classes, paymentType]);

  const handleFinish = useCallback(() => {
    setLoading(true);
    let id_package = '';

    packages.map((item) => {
      if (item.amount === selectedAmount) {
        id_package = item.id;
      }
      return null;
    });
    const data = {
      id_user,

      id_package,

      paid: paymentType === 'Aqui',

      paid_date: paymentType === 'Aqui' ? new Date() : null,

      id_transaction: '',

      courtesy: classes - selectedAmount,

      discount,

      expires_in: useExpiry === 'Sim' ? expiryDate : null,
    };
    api.post(`payers/createPayer`, data).then((response) => {
      setLoading(false);
      toast.success('Pagamento atualizado com sucesso!');
      history.push('/usuarios');
    });
  }, [
    packages,
    id_user,
    paymentType,
    classes,
    selectedAmount,
    discount,
    history,
    useExpiry,
    expiryDate,
  ]);

  return (
    <Container>
      <aside>
        <h1>Qtd. de aulas restantes:</h1>
        <span>{`${remainingClasses} aulas`}</span>
      </aside>
      <aside>
        <h1>Adicionar aulas</h1>
      </aside>
      <aside>
        <h1>Selecione um plano:</h1>
        <Dropdown
          placeholder="Planos"
          options={options}
          value="one"
          onSelect={(value) => {
            setClasses(Number(value.value));
            setSelectedAmount(Number(value.value));
          }}
        />
      </aside>
      <aside>
        <h1>Adicionar aulas</h1>
        <main>
          <button
            type="button"
            onClick={() => handleChangeClassesAmount('minus')}
          >
            <MdRemoveCircle color="red" size={20} />
          </button>
          <span>{classes}</span>
          <button
            type="button"
            onClick={() => handleChangeClassesAmount('add')}
          >
            <MdAddCircle color="green" size={20} />
          </button>
        </main>
      </aside>
      <aside>
        <h1>Desconto:</h1>
        <main>
          <button type="button" onClick={() => handleChangeDiscount('minus')}>
            <MdRemoveCircle color="red" size={20} />
          </button>
          <span>{`${discount}%`}</span>
          <button type="button" onClick={() => handleChangeDiscount('add')}>
            <MdAddCircle color="green" size={20} />
          </button>
        </main>
      </aside>

      <aside>
        <h1>Preço Final:</h1>
        <span>
          {`${finalPrice
            .toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })
            .replace('.', ',')}`}
        </span>
      </aside>
      <aside>
        <h1>Tem vencimento?</h1>
        <main>
          <div className="expiryDiv">
            <ExpiryButton
              type="button"
              selected={useExpiry === 'Sim'}
              onClick={() => setUseExpiry('Sim')}
            />
            <span>Sim</span>
          </div>
          <div className="expiryDiv">
            <ExpiryButton
              type="button"
              selected={useExpiry === 'Não'}
              onClick={() => setUseExpiry('Não')}
            />
            <span>Não</span>
          </div>
        </main>
      </aside>
      {useExpiry === 'Sim' ? (
        <DatePicker
          onChange={(e: Date) => setExpiryDate(e)}
          value={expiryDate}
        />
      ) : (
        <></>
      )}
      <aside>
        <PaymentType
          selected={paymentType === 'Aqui'}
          onClick={() => setPaymentType('Aqui')}
        >
          <button type="button" />
          <p>Pagar aqui</p>
        </PaymentType>
        <PaymentType
          selected={paymentType === 'App'}
          onClick={() => setPaymentType('App')}
        >
          <button type="button" />
          <p>Pagar no app</p>
        </PaymentType>
      </aside>

      <FinishButton
        disabled={finishButtonDisabled}
        onClick={() => handleFinish()}
      >
        <p>Finalizar</p>
      </FinishButton>
      <FullLoader show={loading} />
    </Container>
  );
};

export default PaymentData;

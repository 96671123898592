import styled from 'styled-components';
import { shade } from 'polished';
import white_label from 'white_label';

const { secondary_color } = white_label();

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  h1 {
    color: #999;
    font-family: 'Arial';
    font-size: 20px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    height: 30px;
    width: 30px;
    border-radius: 40px;
    background: ${secondary_color};

    &:hover {
      background: ${shade(0.2, secondary_color)};
    }
  }
`;

import React, { useCallback, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CurrencyInput from 'components/CurrencyInput';
import DatePicker from 'react-datepicker';
import Button from 'components/Button';
import ModalHeader from 'components/ModalHeader';
import api from 'services/api';
import getDayOfWeekDescription from 'utils/getDayOfWeekDescription';
import { toast } from 'react-toastify';
import { PriceCourts } from 'interfaces/priceCourts';
import { Container, Content } from './styles';

interface PageProps {
  closeModal: (refresh: boolean) => void;
  idCourt: string;
  priceSelected: PriceCourts;
}

interface LastStepProps {
  price: string;
}

const schema = yup.object().shape({
  price: yup.string().required(),
});

const AddExceptionModal: React.FC<PageProps> = ({
  closeModal,
  idCourt,
  priceSelected,
}) => {
  const [loading, setLoading] = useState(false);
  const [dateEvent, setDateEvent] = useState<any>();

  const { register, handleSubmit, control, errors } = useForm<LastStepProps>({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    ({ price }: LastStepProps) => {
      setLoading(true);
      const priceFormat = price.replace('R$', '');
      const data = {
        id_appointment_price: priceSelected.id,
        date: dateEvent,
        new_price: Number(priceFormat.replace(',', '.')),
      };

      api.post('/appointments/createPriceException', data).then(() => {
        toast.success('Exceção criada com sucesso!');
        setLoading(false);
        closeModal(true);
      });
    },
    [closeModal, dateEvent, priceSelected]
  );

  return (
    <Container>
      <ModalHeader title="Nova exceção" closeModal={() => closeModal(false)} />
      <Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <aside>
            <p>
              {`Hora e dia selecionados: ${getDayOfWeekDescription(
                Number(priceSelected.week_day)
              )} às ${priceSelected.hour}:00`}
            </p>
            <div className="contentInputs">
              <section>
                <span>Selecione da data</span>
                <DatePicker
                  className="dateInput"
                  selected={dateEvent}
                  onChange={(date: any) => setDateEvent(date)}
                  filterDate={(date) =>
                    date.getDay() === priceSelected.week_day
                  }
                  placeholderText="dd/MM/yyyy"
                />
              </section>
              <section>
                <CurrencyInput
                  control={control}
                  label="Preço"
                  name="price"
                  prefix="R$"
                  register={register}
                  error={!!errors.price?.message}
                />
              </section>
            </div>
          </aside>
          <Button type="submit">{loading ? 'Aguarde...' : 'Salvar'}</Button>
        </form>
      </Content>
    </Container>
  );
};

export default AddExceptionModal;

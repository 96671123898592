/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useCallback, useState } from 'react';

import ModalHeader from 'components/ModalHeader';
import api from 'services/api';
import { toast } from 'react-toastify';
import { MdCheckCircleOutline, MdNotInterested } from 'react-icons/md';
import { FaCheckCircle, FaMinusCircle } from 'react-icons/fa';
import WeekClassDetail from 'interfaces/class_detail';
import { Container, Content, Row, ChangeTypeButton } from './styles';

interface PageProps {
  id_week: string;
  closeModal: () => void;
}

const ClassEventModal: React.FC<PageProps> = ({ id_week, closeModal }) => {
  const [loading, setLoading] = useState(true);
  const [classDetails, setClassDetails] = useState<WeekClassDetail>(
    {} as WeekClassDetail
  );

  useEffect(() => {
    api
      .get(`/teachers/findClassDetails?id_week=${id_week}`)
      .then((response) => {
        setClassDetails(response.data);
      });
  }, [id_week]);

  const handleCheckInStudent = useCallback(
    (id: string) => {
      api.put(`/teachers/retrieveTicket?id_classuser=${id}`).then(() => {
        api
          .get(`/teachers/findClassDetails?id_week=${id_week}`)
          .then((response) => {
            setClassDetails(response.data);
          });
      });
    },
    [id_week]
  );

  const formatDate = useCallback((startDate: string) => {
    const tempString = startDate.split('-');

    const year = tempString[0];
    const month = tempString[1];
    const day = tempString[2]?.substring(0, 2);
    return `${day}/${month}/${year}`;
  }, []);

  return (
    <Container>
      <ModalHeader title="Aula" closeModal={closeModal} />
      <Content>
        <Row>
          <h3> Professor :</h3>
          <span>{classDetails.teacher_name}</span>
        </Row>
        <Row>
          <h3> Data :</h3>
          <span>{formatDate(String(classDetails.date))}</span>
        </Row>
        <Row>
          <h3> Limite :</h3>
          <span>{classDetails.limit}</span>
        </Row>
        <Row className="students">
          <h3> Alunos :</h3>
          {classDetails.users?.map((user) => {
            return (
              <p>
                {' '}
                - {user.name}{' '}
                {user.retrieved ? (
                  <div>
                    <MdCheckCircleOutline color="#286738" size={15} />
                    <ChangeTypeButton
                      type="button"
                      checkIn={false}
                      onClick={() => handleCheckInStudent(user.id)}
                    >
                      Dar falta
                    </ChangeTypeButton>
                  </div>
                ) : (
                  <div>
                    <MdNotInterested color="orange" size={15} />
                    <ChangeTypeButton
                      type="button"
                      checkIn
                      onClick={() => handleCheckInStudent(user.id)}
                    >
                      Fazer check in
                    </ChangeTypeButton>
                  </div>
                )}
              </p>
            );
          })}
        </Row>
      </Content>
    </Container>
  );
};

export default ClassEventModal;

import styled from 'styled-components';

export const Container = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  thead {
    tr {
      height: 60px;

      /* th {
        min-width: 100px;
      } */
      /* 
      & > th:first-child {
        padding: 22px;
      }

      & > th:last-child {
        width: 84px;
        padding: 22px;
      } */
    }
  }

  tbody {
    tr {
      cursor: pointer;
      text-align: left;
      height: 92px;
      border-bottom: 1px solid #dfe0eb;
      transition: 0.2s all;
      margin: 0 22px;

      &:first-child {
        border-top: 1px solid #dfe0eb;
      }

      & > td:first-child {
        padding: 22px;
      }

      & > td:last-child {
        min-width: 84px;
        padding: 22px;
      }

      &:hover {
        background-color: #f6f6f8;
      }
    }

    img {
      width: 44px;
      height: 44px;
      border-radius: 50%;
    }
  }

  th {
    font-size: 14px;
    font-weight: bold;
    color: #9fa2b4;
  }
`;

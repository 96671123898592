/* eslint-disable array-callback-return */
/* eslint-disable radix */
/* eslint-disable func-names */
import React, { useCallback, useState, useEffect } from 'react';
import ModalHeader from 'components/ModalHeader';
import { Chart } from 'react-google-charts';

import FullLoader from 'components/FullLoader';
import { User } from 'interfaces';
import { Container, Content } from './styles';

interface PageProps {
  closeModal: () => void;
  users: User[];
}
const PointsModal: React.FC<PageProps> = ({ closeModal, users }) => {
  const [loading, setLoading] = useState(false);
  const [chartsPoints, setChartsPoints] = useState([]);

  useEffect(() => {
    setLoading(true);
    const data: any = [
      [
        'Element',
        'Density',
        { role: 'style' },
        {
          sourceColumn: 0,
          role: 'annotation',
          type: 'string',
          calc: 'stringify',
        },
      ],
    ];
    users.map((user) => {
      const points = parseInt(user.points) || 0;
      let color = '';
      if (points <= 30) {
        color = '#FFFD01';
      }
      if (points >= 30 && points <= 70) {
        color = '#6D5ACF';
      }
      if (points >= 70) {
        color = '#2DFE54';
      }
      const bar = [user.name, points, color, points];
      data.push(bar);
    });
    setChartsPoints(data);
    setLoading(false);
  }, [users]);

  const options = {
    title: 'Pontuação',
    width: 'auto',
    height: 'auto',
    bar: { groupWidth: '55%' },
    legend: { position: 'none' },
  };
  return (
    <Container>
      <FullLoader show={loading} />
      <ModalHeader title="Pontuação geral" closeModal={closeModal} />
      <Content>
        {loading ? (
          ''
        ) : (
          <Chart
            chartType="BarChart"
            width="100%"
            height="400px"
            data={chartsPoints}
            // @ts-ignore
            options={options}
          />
        )}
      </Content>
    </Container>
  );
};

export default PointsModal;

/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
import axios from 'axios';
import apiConfig from 'config';
import { toast } from 'react-toastify';
import white_label from 'white_label';

const { api_url } = white_label();

const api = axios.create({
  baseURL: apiConfig.baseUrl,
});

api.interceptors.request.use(function (config) {
  const accessToken = localStorage.getItem('@ahaya-web:accessToken');
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`.replace(
      /['"]+/g,
      ''
    );
  }

  return config;
});

api.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error) {
    if (
      [401, 403].includes(error?.response?.status) &&
      window.location.hash !== '#/signin'
    ) {
      localStorage.clear();
      window.open('/', '_self');
      toast.error('Seu login expirou');
    }
    return Promise.reject(error);
  }
);

export default api;

import React from 'react';
import { IconBaseProps } from 'react-icons';
import { useHistory } from 'react-router-dom';

import { useAuth } from 'context';
import { MenuItem } from 'interfaces';

import { Container } from './styled';

interface MenuItemProps {
  icon?: React.ElementType<IconBaseProps>;
  iconSize?: number;
  path?: string;
  label: string;
  showMenu?: boolean;
  subMenus?: MenuItem[];
}

const MenuItemComponent: React.FC<MenuItemProps> = ({
  icon: Icon,
  path,
  label,
  showMenu,
  subMenus = [],
  iconSize = 32,
}) => {
  const history = useHistory();

  const { signOut } = useAuth();

  const handleClick = (pathItem?: string) => {
    if (label === 'Sair') signOut();
    else if (pathItem) {
      history.push(pathItem);
    }
  };

  return (
    <Container onClick={() => handleClick(path)} showMenu={showMenu}>
      <div>
        {/* @ts-ignore */}
        {Icon && <Icon size={iconSize} />}
        <span>{label}</span>
      </div>
    </Container>
  );
};

export default MenuItemComponent;

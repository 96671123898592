import React, { useEffect, useCallback, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import Input from 'components/Input';
import Button from 'components/Button';
import FullLoader from 'components/FullLoader';
import api from 'services/api';
import { User } from 'interfaces/user';
import { Container } from './styles';

interface PageProps {
  user: User;
}

interface LastStepProps {
  points: number;
}

const schema = yup.object().shape({
  points: yup.number().required(),
});

const UserScore: React.FC<PageProps> = ({ user }) => {
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, setValue, errors, control } =
    useForm<LastStepProps>({
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    api.get(`/score/findUserPoints?id_user=${user.id}`).then((response) => {
      setValue('points', response.data);
    });
  });

  const onSubmit = useCallback(
    ({ points }: LastStepProps) => {
      const userData = {
        id_user: user.id,
        points,
      };
      setLoading(true);
      api
        .put('/score/updateUserPoints', userData)
        .then(() => {
          toast.success('Pontuação alterada com sucesso!');
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    },
    [user]
  );

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          placeholder="Pontos do usuario"
          name="points"
          autoCapitalize="words"
          register={register}
          error={!!errors.points?.message}
        />
        <Button type="submit">{loading ? 'Aguarde...' : 'Salvar'}</Button>
      </form>
      <FullLoader show={loading} />
    </Container>
  );
};

export default UserScore;

import styled from 'styled-components';
import { shade } from 'polished';
import white_label from 'white_label';

const { secondary_color } = white_label();

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  form {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100vh;

    .zipCodeContainer {
      display: flex;
      flex-direction: row;
      input {
        width: 95%;
      }

      button {
        border: 0;
        height: 50px;
        width: 53px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        background: ${secondary_color};
        transition: 0.2s;

        &:hover {
          background: ${shade(0.2, secondary_color)};
        }
      }
    }
    .actions {
      width: 100%;
      display: flex;
      justify-content: space-between;
      column-gap: 10px;
      button {
        padding: 5px 0px;
        &.remove {
          background: #d32727;
        }
      }
    }
  }
`;

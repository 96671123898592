/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
export interface SelectItem {
  codigo?: number | string | boolean;
  id?: number | string | boolean;
  descricao?: string;
  label?: string;
  courtname?: string;
  name?: number | string | boolean;
}

export const transformToSelect = (array: any) => {
  return array.map((item: SelectItem) => ({
    label: item.descricao || item.name || item.courtname,
    value: item.id || item.codigo,
  }));
};

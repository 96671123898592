import React, { useEffect, useCallback, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/Button';
import ModalHeader from 'components/ModalHeader';
import api from 'services/api';

import { toast } from 'react-toastify';
import Input from 'components/Input';
import Select from 'components/ReactSelect';
import { transformToSelect } from 'utils/toSelect';
import { Container, Content } from './styles';

interface LastStepProps {
  name: string;
}
interface SelectProps {
  value: string;
  label: string;
}
interface PageProps {
  closeModal: () => void;
}
const schema = yup.object().shape({
  name: yup.string().required(),
});

const AddSportModal: React.FC<PageProps> = ({ closeModal }) => {
  const [loading, setLoading] = React.useState(false);
  const [selectedSports, setSelectedSports] = useState<SelectProps>();
  const [options, setOptions] = React.useState([] as SelectProps[]);

  const { register, handleSubmit, control, errors } = useForm<LastStepProps>({
    resolver: yupResolver(schema),
  });
  const getTypeSports = useCallback(() => {
    api.get('/courts/findAllCourtTypes').then((response) => {
      const types = transformToSelect([...response.data]);
      setOptions(types);
    });
  }, []);
  React.useLayoutEffect(() => {
    getTypeSports();
  }, [getTypeSports]);
  const onSubmit = useCallback(
    ({ name }: LastStepProps) => {
      setLoading(true);
      const data = {
        id_type: selectedSports?.value,
        name,
      };
      api.post('/sports/createSport', data).then((response) => {
        toast.success('Esporte criado com sucesso!');
        setLoading(false);
        closeModal();
      });
    },
    [closeModal, selectedSports]
  );
  const handleSelectTypeSport = (selectedOption: any) => {
    setSelectedSports(selectedOption);
  };
  return (
    <Container>
      <ModalHeader title="Adicionar Esporte" closeModal={closeModal} />
      <Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="sectionFields">
            <Input
              title="Nome"
              name="name"
              register={register}
              placeholder="Esporte"
              error={!!errors.name?.message}
            />
            <Select
              label="Tipo da quadra"
              onChange={handleSelectTypeSport}
              className="select"
              value={selectedSports}
              options={options}
            />
          </div>
          <Button type="submit">{loading ? 'Aguarde...' : 'Salvar'}</Button>
        </form>
      </Content>
    </Container>
  );
};

export default AddSportModal;

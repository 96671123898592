import React from 'react';

import { MdClose } from 'react-icons/md';

import { Container } from './styles';

interface PageProps {
  title: string;
  closeModal: () => void;
}

const ModalHeader: React.FC<PageProps> = ({ title, closeModal }) => {
  return (
    <Container>
      <h1>{title}</h1>
      <button type="button" onClick={() => closeModal()}>
        <MdClose color="#fff" size={20} />
      </button>
    </Container>
  );
};

export default ModalHeader;

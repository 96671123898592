import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import Input from 'components/Input';
import Button from 'components/Button';

import { useAuth, usePlace } from 'context';
import logo from 'assets/logo.png';
import { FORGOT_PASSWORD, REGISTER } from 'routes/constants';
import { Container } from './styled';

interface LastStepProps {
  email: string;
  password: string;
}

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(4),
});

const SignIn: React.FC = () => {
  const history = useHistory();
  const { place } = usePlace();
  const { register, handleSubmit, errors } = useForm<LastStepProps>({
    resolver: yupResolver(schema),
  });

  const { signIn, loading, isAuthenticated } = useAuth();

  const onSubmit = React.useCallback(
    ({ email, password }: LastStepProps) => {
      signIn({ email, password });
    },
    [signIn]
  );

  React.useEffect(() => {
    if (isAuthenticated) history.push('/');
  }, [isAuthenticated, history]);

  return (
    <Container
      background_img_url={place.backgroundUrl}
      primary_color={place.primaryColor}
    >
      <div className="bg" />
      <div className="form">
        <div className="logo">
          <img src={place.logo} alt="logo" />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            label="Email"
            name="email"
            register={register}
            error={!!errors.email?.message}
          />
          <Input
            label="Senha"
            type="password"
            name="password"
            register={register}
            error={!!errors.password?.message}
          />
          <Button type="submit">{loading ? 'Entrando...' : 'Entrar'}</Button>
        </form>
      </div>
    </Container>
  );
};

export default SignIn;

import { Table } from '@pdasolucoes/web';
import { AxiosResponse } from 'axios';
import React, { useCallback, useEffect } from 'react';
import { useQuery } from 'hooks/query';
import api from 'services/api';
import { useMenu } from 'context';
import { FaArrowLeft } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { MdDeleteForever } from 'react-icons/md';
import { toast } from 'react-toastify';
import ConfirmDialog from 'components/ConfirmDialog';
import { TableContent, Container, ContentContainer } from './styles';

interface User {
  id_user: string;
  name: string;
  observation: string;
  paid_price: number;
  material_amount: number;
  tickets: number;
  tickets_retrieved: number;
}
const UsersInDayUse: React.FC = () => {
  const { setPageTitle } = useMenu();
  const id_dayUse = useQuery().get('id_day_use');
  const [users, setUsers] = React.useState([] as User[]);
  const [loading, setLoading] = React.useState(true);
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const [idUser, setIUser] = React.useState('');
  const history = useHistory();
  const loadDUsers = useCallback(async () => {
    api
      .get(`/dayUse/findUsersByList?id_dayuse=${id_dayUse}`)
      .then((response: AxiosResponse) => {
        if (response.data.length === 0) {
          setUsers([]);
          return [];
        }
        return setUsers([...response.data]);
      });
  }, [id_dayUse]);

  useEffect(() => {
    setPageTitle('Day Use > Usuários');
    loadDUsers();
  }, [loadDUsers, setPageTitle]);

  const goback = useCallback(() => {
    history.goBack();
  }, [history]);
  const handleOpenConfirm = useCallback((row: User) => {
    setIUser(row.id_user);
    setIsOpenDialog(true);
  }, []);

  const handleCloseConfirm = useCallback(() => {
    setIsOpenDialog(false);
  }, []);

  const handleConfirm = useCallback(() => {
    setLoading(true);
    api
      .delete(`/dayUse/deleteUser?id_dayuse=${id_dayUse}&id_user=${idUser}`)
      .then(() => {
        toast.success('Usuário removido com sucesso !');
        setLoading(false);
        setIsOpenDialog(false);
        loadDUsers();
      });
  }, [idUser, loadDUsers, id_dayUse]);
  return (
    <Container>
      <button type="button" className="gobackButton" onClick={goback}>
        <FaArrowLeft size={25} />
      </button>

      <ContentContainer>
        <TableContent>
          <Table<User>
            defaultNumberOfRows={10}
            columns={[
              {
                title: 'Nome',
                type: 'string',
                // orderable: true,
                props: ['name'],
                cssTitle: {
                  justifyContent: 'flex-start',
                  textAlign: 'left',
                },
                cssProps: {
                  width: '70%',
                  paddingRight: '8px',
                  fontFamily: 'sans-serif',
                },
                cssText: {
                  paddingLeft: '8px',
                },
              },
              {
                title: 'Preço Pago',
                type: 'currency',
                // orderable: true,
                props: ['paid_price'],
                cssTitle: {
                  justifyContent: 'flex-start',
                  textAlign: 'left',
                },
                cssProps: {
                  width: '70%',
                  paddingRight: '8px',
                  fontFamily: 'sans-serif',
                },
                cssText: {
                  paddingLeft: '8px',
                },
              },
              {
                title: 'Quantidade Material ',
                type: 'number',
                // orderable: true,
                props: ['material_amount'],
                cssTitle: {
                  justifyContent: 'flex-start',
                  textAlign: 'left',
                },
                cssProps: {
                  width: '70%',
                  paddingRight: '8px',
                  fontFamily: 'sans-serif',
                },
                cssText: {
                  paddingLeft: '8px',
                },
              },
              {
                title: 'Bilhetes ',
                type: 'number',
                // orderable: true,
                props: ['tickets'],
                cssTitle: {
                  justifyContent: 'flex-start',
                  textAlign: 'left',
                },
                cssProps: {
                  width: '70%',
                  paddingRight: '8px',
                  fontFamily: 'sans-serif',
                },
                cssText: {
                  paddingLeft: '8px',
                },
              },
              {
                // cssRow:{
                //   width: 'auto';
                // },
                delimiter: '3',
                title: 'Excluir',
                type: 'string',
                // orderable: true,
                props: ['id'],
                cssTitle: {
                  justifyContent: 'center',
                  textAlign: 'center',
                },
                cssProps: {
                  width: '2%',
                  paddingRight: '8px',
                  paddingLeft: '16px',
                  fontFamily: 'sans-serif',
                },
                cssText: {
                  paddingLeft: '8px',
                },
                renderItem: (row) => {
                  return (
                    <button
                      type="button"
                      onClick={() => handleOpenConfirm(row)}
                    >
                      <MdDeleteForever color="#d32727" size={20} />
                    </button>
                  );
                },
              },
            ]}
            rows={[
              ...users.map((item) => ({
                ...item,
              })),
            ]}
          />
        </TableContent>
      </ContentContainer>
      <ConfirmDialog
        title="Deseja realmente remover Usuário?"
        isOpen={isOpenDialog}
        onClose={handleCloseConfirm}
        handleConfirm={handleConfirm}
      />
    </Container>
  );
};

export default UsersInDayUse;

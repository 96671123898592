import { AxiosResponse } from 'axios';

import api from 'services/api';
import { AccessToken, AuthData, User } from 'interfaces';

const signIn = (data: AuthData): Promise<AxiosResponse<AccessToken>> => {
  return api.post('/sessions', data);
};

const signUp = (data: User): Promise<AxiosResponse<AccessToken>> => {
  return api.post('/api/v1/users', data);
};

export { signIn, signUp };

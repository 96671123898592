export const HOME = '/';
export const SIGNIN = '/signin';
export const REGISTER = '/registro';
export const USERS = '/usuarios';
export const USER = '/usuario';
export const ADDUSER = '/adduser';
export const EDITUSER = '/edituser';
export const USERPAYMENT = '/userpayment';
export const FORGOT_PASSWORD = '/esqueci-minha-senha';
export const RESET_PASSWORD = '/resetPassword';
export const PRICES = '/prices';
export const REQUESTS = '/requests';
export const CATEGORIES = '/categories';
export const TEACHERS = '/teachers';
export const COURTS = '/courts';
export const CALENDAR = '/calendar';
export const PRIVACYPOLICY = '/privacypolicy';
export const CLUB = '/club';
export const INTERESTS = '/interests';
export const VIEWITEMS = '/viewitems';
export const SPORTS = '/sports';
export const CANCELLATIONRULES = '/rules';
export const REPORTS = '/reports';
export const DAYUSE = '/dayuse';
export const USERSINDAYUSE = '/usersInDayUse';
export const WEEKLICALENDAR = '/weeklicalendar';
export const FIDELITY = '/fidelity';

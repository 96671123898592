import styled from 'styled-components';
import { shade } from 'polished';
import white_label from 'white_label';

const { secondary_color } = white_label();

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 300px;

    input {
      width: 100%;
      height: 35px;
      padding: 10px;
      border: 2px solid #ccc;
    }

    button {
      width: 100%;
      height: 35px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background: ${secondary_color};
      transition: 0.3s all;
      border: 0;

      &:hover {
        background: ${shade(0.2, secondary_color)};
      }
      p {
        color: #fff;
        margin-left: 5px;
      }
    }
  }
`;

/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useCallback, useState } from 'react';
import ReactWhatsapp from 'react-whatsapp';
import ModalHeader from 'components/ModalHeader';
import api from 'services/api';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import ConfirmDialog from 'components/ConfirmDialog';
import { FaWhatsapp } from 'react-icons/fa';
import FullLoader from 'components/FullLoader';
import {
  Container,
  Content,
  Row,
  ChangeTypeButton,
  GoWhatsapp,
} from './styles';

interface SingleEvent {
  id: string;
  price: string;
  start_date: string;
  finish_date: string;
  canceled: boolean;
  created_at: string;
  updated_at: string;
  id_user: string;
  court_name: string;
  id_court: string;
  created_sequence: string;
  id_transaction: string;
  observation: string;
  cellphone: string;
  count_transaction: string;
}
interface PageProps {
  id_reservation: string;
  closeModal: () => void;
  closeAndRefresh: () => void;
  handleEdit: (id: string) => void;
}
const AppointmentModal: React.FC<PageProps> = ({
  id_reservation,
  closeModal,
  closeAndRefresh,
  handleEdit,
}) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [classDetails, setClassDetails] = useState<SingleEvent>(
    {} as SingleEvent
  );

  useEffect(() => {
    api
      .get(`/appointments/findById?id_appointment=${id_reservation}`)
      .then((response) => {
        console.log(response.data);
        setClassDetails(response.data);
        setLoading(false);
      });
  }, [id_reservation]);

  const handleDeleteAppointment = useCallback(() => {
    setIsOpenDialog(false);
    setLoading(true);
    api
      .delete(
        `/appointments/deleteAppointment?id_appointment=${id_reservation}`
      )
      .then(() => {
        toast.info('Reserva excluída com sucesso!');
        closeAndRefresh();
      });
  }, [id_reservation, closeAndRefresh]);

  return (
    <Container>
      <FullLoader show={loading} />
      <ModalHeader title="Reserva Avulsa" closeModal={closeModal} />
      {!loading && (
        <Content>
          <Row>
            <h3> Preço:</h3>
            <span>{`R$ ${String(classDetails.price).replace('.', ',')}`}</span>
          </Row>
          <Row>
            <h3> Data Inicio:</h3>
            <span>
              {format(new Date(classDetails.start_date), 'dd/MM/yyyy HH:mm')}
            </span>
          </Row>
          <Row>
            <h3> Data Fim:</h3>
            <span>
              {format(new Date(classDetails.finish_date), 'dd/MM/yyyy HH:mm')}
            </span>
          </Row>
          <Row>
            <h3> Criado em:</h3>
            <span>
              {format(new Date(classDetails.created_at), 'dd/MM/yyyy HH:mm')}
            </span>
          </Row>
          <Row>
            <h3> Quadra:</h3>
            <span>{classDetails.court_name}</span>
          </Row>
          <Row>
            <h3> Cliente:</h3>
            <span>{classDetails.observation.split('-')[0]}</span>
          </Row>
          {classDetails.cellphone && (
            <Row>
              <h3> Celular:</h3>
              <span>
                {classDetails.cellphone}
                {/* @ts-ignore */}
                <ReactWhatsapp
                  className="whats"
                  number={`55-${classDetails.cellphone}`}
                  message="Olá!"
                  element="div"
                >
                  <GoWhatsapp type="button" title="Conversar ➤">
                    <FaWhatsapp color="#08cc39" size={20} />
                  </GoWhatsapp>
                </ReactWhatsapp>
              </span>
            </Row>
          )}
          <Row>
            <button
              type="button"
              className="actionButton"
              onClick={() => handleEdit(id_reservation)}
            >
              Editar Reserva
            </button>
            <button
              type="button"
              className="actionButton"
              onClick={() => setIsOpenDialog(true)}
            >
              Excluir Reserva
            </button>
          </Row>
        </Content>
      )}
      <ConfirmDialog
        isOpen={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
        handleConfirm={handleDeleteAppointment}
        title="Deseja realmente excluir essa reserva?"
      />
    </Container>
  );
};

export default AppointmentModal;

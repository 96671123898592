import React, { useEffect, useCallback, useState } from 'react';

import { User } from 'interfaces/user';
import { useQuery } from 'hooks/query';
import { useMenu } from 'context';
import api from 'services/api';

import UserPhoto from './UserPhoto';
import UserData from './UserData';
import PaymentData from './PaymentData';
import Categories from './Categories';
import Monthly from './Monthly';
import { Container, OptionsContainer, Option, Content } from './styles';
import UserPassword from './UserPassword';
import UserScore from './UserScore';

interface RadioTypes {
  id: string;
  label: string;
  value: string;
}

const EditUser: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User>({} as User);
  const [type, setType] = useState('');
  const { setPageTitle } = useMenu();

  const id_user = useQuery().get('id_user');

  const radioTypes: RadioTypes[] = [
    {
      id: 'Dados Cadastrais',
      label: 'Dados',
      value: '2',
    },
    {
      id: 'Senha',
      label: 'Senha',
      value: '6',
    },
    {
      id: 'Fidelidade',
      label: 'Fidelidade',
      value: '7',
    },
  ];

  useEffect(() => {
    api.get(`/users/findById?id_user=${id_user}`).then((response) => {
      setUser(response.data);
      setPageTitle(response.data.name);
      setLoading(false);
    });
  }, [id_user, setPageTitle]);

  return (
    <Container>
      <OptionsContainer>
        {radioTypes.map((item) => (
          <Option
            key={item.id}
            selected={type === item.value}
            onClick={() => setType(item.value)}
          >
            <button type="button" />
            <p>{item.label}</p>
          </Option>
        ))}
      </OptionsContainer>
      {type !== '' ? (
        <Content>
          {type === '1' ? (
            <UserPhoto id_user={id_user || ''} />
          ) : (
            <>
              {type === '2' ? (
                <UserData user={user} />
              ) : (
                <>
                  {type === '3' ? (
                    <PaymentData id_user={id_user || ''} />
                  ) : (
                    <>
                      {type === '4' ? (
                        <Monthly
                          id_user={id_user || ''}
                          user_name={user.name}
                          ssn={user.ssn}
                        />
                      ) : (
                        <>
                          {type === '5' ? (
                            <Categories id_user={id_user || ''} />
                          ) : (
                            <>
                              {type === '6' ? (
                                <UserPassword user={user} />
                              ) : (
                                <UserScore user={user} />
                              )}
                            </>
                            // <UserPassword user={user} />
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Content>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default EditUser;

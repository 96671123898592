import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import { Container } from './styled';

interface PaginationProps {
  offset: number;
  total: number;
  currentCount: number;
  handleNextPage: () => void;
  handlePreviousPage: () => void;
}

function Pagination({
  offset = 0,
  currentCount = 0,
  total = 0,
  handleNextPage,
  handlePreviousPage,
}: PaginationProps) {
  return (
    <Container
      isBackButtonDisabled={offset + 1 === 1}
      isFowardButtonDisabled={offset + currentCount === total}
    >
      <p>{`${offset + 1} - ${offset + currentCount} de ${total}`}</p>
      <div className="pagination-buttons">
        <button type="button" onClick={handlePreviousPage}>
          <FaChevronLeft size={16} />
        </button>
        <button type="button" onClick={handleNextPage}>
          <FaChevronRight size={16} />
        </button>
      </div>
    </Container>
  );
}

export default Pagination;

import styled, { css } from 'styled-components';

interface InputStyleProps {
  error?: boolean;
}

export const Container = styled.div<InputStyleProps>`
  width: 100%;
  display: 'flex';
  flex-direction: column;
  font-size: 15px;
  &::placeholder {
    color: #999;
  }

  label {
    font-size: 15px;
    color: #999;
  }

  > div {
    font-weight: 500;

    ${(props) =>
      props.error &&
      css`
        span {
          color: #f44336;
        }
      `}

    > div {
      ${(props) =>
        props.error &&
        css`
          border: 1px solid #f44336;
        `}
      > div {
        > div {
          margin-left: 0;
        }
      }
    }
  }

  ${(props) =>
    props.error &&
    css`
      input {
        border: 1px solid #f44336;
      }
    `}
`;

import { useSelector } from 'react-redux';
import { UsersResponse, User } from 'interfaces';

interface State {
  usersReducer: {
    data: {
      items: UsersResponse;
      user: User | null;
    };
  };
}

const useUsers = (): UsersResponse =>
  useSelector((state: State) => state.usersReducer.data.items);

const useUser = (): User | null =>
  useSelector((state: State) => state.usersReducer.data.user);

export { useUsers, useUser };

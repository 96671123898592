/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useCallback, useState } from 'react';
import {
  MdEdit,
  MdCheckCircleOutline,
  MdRemoveCircleOutline,
  MdDeleteForever,
} from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';

import { Table } from '@pdasolucoes/web';
import FullLoader from 'components/FullLoader';

import { useMenu } from 'context';
import { Court } from 'interfaces/courts';
import api from 'services/api';
import { FaFontAwesomeFlag } from 'react-icons/fa';
import { modalStyle } from 'components/ModalStyle';
import ConfirmDialog from 'components/ConfirmDialog';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import AddCourtModal from './AddCourtModal/index';
import EditCourtModal from './EditCourtModal/index';

import { Container, TableContent } from './styles';

const Courts: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const [idCourt, setIdCourt] = React.useState('');
  const [courts, setCourts] = React.useState<Court[]>([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalEditOpen, setModalEditOpen] = React.useState(false);
  const [court, setCourt] = useState<Court>({} as Court);

  const { setPageTitle } = useMenu();
  const loadCourts = useCallback(() => {
    api
      .get(`/courts/findAll?id_place=f13f0061-01f0-476f-9d6c-fe4a1a1f64ca`)
      .then((response) => {
        setCourts(response.data);
        setLoading(false);
      });
  }, [setCourts, setLoading]);

  useEffect(() => {
    loadCourts();
    setPageTitle('Quadras');
  }, [setPageTitle, loadCourts]);

  const handleOpenEditModal = useCallback(
    (courtSelected) => {
      setCourt(courtSelected);
      setModalEditOpen(true);
    },
    [setCourt]
  );

  const closeModalAndRefresh = useCallback(() => {
    setModalOpen(false);
    setModalEditOpen(false);
    setLoading(true);
    api.get(`/courts/findAll`).then((response) => {
      setCourts(response.data);
      setLoading(false);
    });
  }, []);
  const handleOpenConfirm = useCallback((row: Court) => {
    setIdCourt(row.id);
    setIsOpenDialog(true);
  }, []);
  const handleCloseConfirm = useCallback(() => {
    setIsOpenDialog(false);
    setLoading(false);
  }, []);
  const handleConfirm = useCallback(async () => {
    try {
      setLoading(true);
      await api
        .delete(`/courts/deleteCourt?id_court=${idCourt}`)
        // eslint-disable-next-line consistent-return
        .then((response: AxiosResponse) => {
          if (response.status === 200 || response.status === 204) {
            toast.success('Quadra excluída comm sucesso!');
            setLoading(false);
            setIsOpenDialog(false);
            loadCourts();
          }
          setLoading(false);
          setIsOpenDialog(false);
        });
    } catch (error) {
      // console.log(error);
    }
  }, [idCourt, loadCourts]);
  return (
    <Container>
      <FullLoader show={loading} />
      <button
        onClick={() => setModalOpen(true)}
        className="addClientButton"
        type="button"
      >
        <FaFontAwesomeFlag size={20} color="#fff" />
        <span>Adicionar quadra</span>
      </button>
      <TableContent>
        <Table<Court>
          defaultNumberOfRows={10}
          columns={[
            {
              title: 'Nome',
              type: 'string',
              orderable: true,
              props: ['name'],
              cssTitle: {
                justifyContent: 'flex-start',
                textAlign: 'left',
              },
              cssProps: {
                width: '90%',
                paddingRight: '8px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
            },
            {
              title: 'Coberta?',
              type: 'string',
              orderable: false,
              props: ['covered'],
              cssTitle: {
                justifyContent: 'flex-center',
                textAlign: 'left',
              },
              cssProps: {
                width: '1%',
                paddingLeft: '16px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
              renderItem: (row) => {
                return (
                  <div>
                    {row.covered ? (
                      <MdCheckCircleOutline color="#286738" size={20} />
                    ) : (
                      <MdRemoveCircleOutline color="#c53030" size={20} />
                    )}
                  </div>
                );
              },
            },
            {
              // cssRow:{
              //   width: 'auto';
              // },
              delimiter: '3',
              title: 'Editar',
              type: 'string',
              // orderable: true,
              props: ['id'],
              cssTitle: {
                justifyContent: 'flex-center',
                textAlign: 'center',
              },
              cssProps: {
                width: '2%',
                paddingRight: '8px',
                paddingLeft: '16px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
              renderItem: (row) => {
                return (
                  <button
                    type="button"
                    onClick={() => handleOpenEditModal(row)}
                  >
                    <MdEdit color="#286738" size={20} />
                  </button>
                );
              },
            },
            {
              // cssRow:{
              //   width: 'auto';
              // },
              delimiter: '3',
              title: 'Excluir',
              type: 'string',
              // orderable: true,
              props: ['id'],
              cssTitle: {
                justifyContent: 'flex-center',
                textAlign: 'center',
              },
              cssProps: {
                width: '2%',
                paddingRight: '8px',
                paddingLeft: '16px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
              renderItem: (row) => {
                return (
                  <button type="button" onClick={() => handleOpenConfirm(row)}>
                    <MdDeleteForever color="#d32727" size={20} />
                  </button>
                );
              },
            },
          ]}
          rows={[
            ...courts.map((item) => ({
              ...item,
            })),
          ]}
        />
      </TableContent>
      <Modal isOpen={modalOpen} style={modalStyle}>
        <AddCourtModal
          closeModal={() => setModalOpen(false)}
          closeAndRefresh={() => closeModalAndRefresh()}
        />
      </Modal>
      <Modal isOpen={modalEditOpen} style={modalStyle}>
        <EditCourtModal
          closeModal={() => setModalEditOpen(false)}
          closeAndRefresh={() => closeModalAndRefresh()}
          court={court}
        />
      </Modal>
      <ConfirmDialog
        isOpen={isOpenDialog}
        onClose={handleCloseConfirm}
        handleConfirm={handleConfirm}
        title="Deseja realmente excluir esta quadra?"
      />
    </Container>
  );
};

export default Courts;

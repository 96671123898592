import React, { useEffect, useCallback, useState, useMemo } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Input from 'components/Input';
import Button from 'components/Button';
import ModalHeader from 'components/ModalHeader';
import api from 'services/api';
import Select from 'components/ReactSelect';
import makeAnimated from 'react-select/animated';
import { Sports } from 'interfaces/sports';
import { toast } from 'react-toastify';
import { Container, Content } from './styles';

interface PageProps {
  closeModal: () => void;
  closeAndRefresh: () => void;
}

interface LastStepProps {
  court_name: string;
}
interface SelectSportProps {
  value: string;
  label: string;
}

interface SportsObjectProps {
  id: string;
  name: string;
}

const schema = yup.object().shape({
  court_name: yup.string().required(),
});

const AddCourtModal: React.FC<PageProps> = ({
  closeModal,
  closeAndRefresh,
}) => {
  const [loading, setLoading] = useState(false);
  const [sports, setSports] = useState<Sports[]>([]);
  const [selectedSports, setSelectedSports] = useState<SelectSportProps[]>([]);
  const [selectedCovered, setSelectedCovered] = useState('');

  const animatedComponents = makeAnimated();

  const { register, handleSubmit, control, errors } = useForm<LastStepProps>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    api.get('/sports/findAll').then((response) => {
      setSports(response.data);
    });
  }, []);

  const onSubmit = useCallback(
    ({ court_name }: LastStepProps) => {
      setLoading(true);
      selectedSports.map((item) => {
        const newItem: SportsObjectProps = {
          id: item.value,
          name: item.label,
        };
        sports.push(newItem);
        return null;
      });
      const data = {
        name: court_name,
        id_place: process.env.REACT_APP_ID_PLACE,
        id_type: '5dc409a1-5c2f-45c5-8179-d62e5d803d8e',
        sports: sports.map((i) => i.id),
        covered: selectedCovered === '1',
      };

      api.post('/courts/create', data).then(() => {
        toast.success('Quradra criada com sucesso!');
        closeAndRefresh();
      });
    },
    [selectedSports, closeAndRefresh, sports, selectedCovered]
  );
  const handleSelectSport = (selectedOption: any) => {
    setSelectedSports(selectedOption);
  };
  const handleSelectCoverade = (selectedOption: any) => {
    setSelectedCovered(selectedOption);
  };

  const options = useMemo(() => {
    const tempArray: any[] = [];
    sports.map((item) => {
      tempArray.push({ value: item.id, label: item.name });
      return null;
    });
    return tempArray;
  }, [sports]);
  const Converad = [
    {
      value: '1',
      label: 'Sim',
    },
    {
      value: '0',
      label: 'Não',
    },
  ];

  return (
    <Container>
      <ModalHeader title="Nova Quadra" closeModal={closeModal} />
      <Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            label="Nome da quadra"
            name="court_name"
            register={register}
            error={!!errors.court_name?.message}
          />
          <Select
            label="Esportes"
            onChange={handleSelectSport}
            className="select"
            isMulti
            options={options}
          />
          <Select
            label="É coberta?"
            onChange={handleSelectCoverade}
            className="select"
            options={Converad}
          />
          <Button type="submit">{loading ? 'Aguarde...' : 'Salvar'}</Button>
        </form>
      </Content>
    </Container>
  );
};

export default AddCourtModal;

import styled from 'styled-components';

interface OptionProps {
  selected: boolean;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: row;
`;

export const OptionsContainer = styled.div`
  height: 8%;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DisplayGrid = styled.div`
  height: 90%;
  display: flex;
  flex-direction: column;
  width: 20%;
`;
export const Option = styled.div<OptionProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  button {
    height: 30px;
    width: 30px;
    border: 1px solid #999;
    border-radius: 50px;
    background: ${(props) => (props.selected ? '#ccc' : 'transparent')};
  }
  p {
    margin-left: 10px;
  }
`;

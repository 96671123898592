import axios, { AxiosResponse } from 'axios';
import { AddressBrasilAPI } from 'interfaces';

const getAddressByCEP = (
  cep: string
): Promise<AxiosResponse<AddressBrasilAPI>> => {
  return axios.get(`https://brasilapi.com.br/api/cep/v1/${cep}`);
};

export { getAddressByCEP };

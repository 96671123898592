import { AxiosResponse } from 'axios';

import api from 'services/api';
import { User, UsersResponse } from 'interfaces';

const createUserAPI = (data: User): Promise<AxiosResponse<{ agent: User }>> => {
  return api.post('/users', data);
};

const listUsersAPI = (query: string): Promise<AxiosResponse<UsersResponse>> => {
  return api.get(`/users/findAll?${query}`);
};

const updateUserAPI = (data: User): Promise<AxiosResponse<{ agent: User }>> => {
  return api.put('/users', data);
};

export { createUserAPI, listUsersAPI, updateUserAPI };

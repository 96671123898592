import styled from 'styled-components';
import { shade } from 'polished';

interface OptionProps {
  selected: boolean;
}

interface ContinueButtonProps {
  enabled: boolean;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: row;
`;

export const OptionsContainer = styled.div`
  height: 12%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Option = styled.div<OptionProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    height: 30px;
    width: 30px;
    border: 1px solid #999;
    border-radius: 50px;
    background: ${(props) => (props.selected ? '#ccc' : 'transparent')};
  }
  p {
    margin-left: 10px;
  }
`;

export const Content = styled.div`
  height: 80vh;
  width: 100%;
`;

export const UserData = styled.div``;

/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useCallback, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox } from '@mui/material';
import { FormControlLabel } from '@material-ui/core';
import CurrencyInput from 'components/CurrencyInput';
import ConfirmDialog from 'components/ConfirmDialog';
import Button from 'components/Button';
import ModalHeader from 'components/ModalHeader';
import { MonthlyProps } from 'interfaces/monthly';
import api from 'services/api';
import { FaTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { PriceCourts } from 'interfaces/priceCourts';
import { format } from 'date-fns';
import { Container, Content } from './styles';

interface LastStepProps {
  price: string;
  discount_value: string;
}

interface PricesExceptions {
  id: string;
  id_appointment_price: String;
  date: Date;
  new_price: string;
}

interface PageProps {
  priceSelected: PriceCourts;
  closeModal: () => void;
  openExceptionModal: () => void;
}

const schema = yup.object().shape({
  price: yup.string().required(),
  discount_value: yup.string().required(),
});

const EditPriceModal: React.FC<PageProps> = ({
  priceSelected,
  closeModal,
  openExceptionModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [allowDiscount, setAllowDiscount] = useState(false);
  const [isNoble, setIsNoble] = useState(false);
  const [exceptionsLoading, setExceptionsLoading] = useState(true);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [selectedExceptionId, setSelectedExceptionId] = useState('');
  const [exceptions, setExceptions] = useState<PricesExceptions[]>([]);

  const { register, handleSubmit, setValue, control, errors } =
    useForm<LastStepProps>({
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    console.log(priceSelected);
    setValue(
      'discount_value',
      String(priceSelected.discount_value).replace('.', ',')
    );

    setIsNoble(priceSelected.is_noble);
    setAllowDiscount(priceSelected.allow_discount);
    setValue('price', String(priceSelected.price).replace('.', ','));
  }, [setValue, priceSelected]);

  useEffect(() => {
    api.get(`/appointments/findAllPricesExceptions`).then((response) => {
      setExceptionsLoading(false);
      setExceptions(response.data);
    });
  }, [priceSelected]);

  const onSubmit = useCallback(
    ({ price, discount_value }: LastStepProps) => {
      setLoading(true);
      const priceFormat = price.replace('R$', '');
      const disctountPriceFormat = discount_value.replace('R$', '');
      const data = {
        id: priceSelected.id,
        price: Number(priceFormat.replace(',', '.')),
        allow_discount: allowDiscount,
        discount_value: Number(disctountPriceFormat.replace(',', '.')),
        is_noble: isNoble,
      };

      api.put('/appointments/editPrice', data).then(() => {
        toast.success('Preço atualizado com sucesso!');
        closeModal();
        setLoading(false);
      });
    },
    [priceSelected, closeModal, allowDiscount, isNoble]
  );

  const handleDeleteException = useCallback(() => {
    setIsOpenDialog(false);
    setExceptionsLoading(true);
    api
      .delete(`/appointments/deletePriceException?id=${selectedExceptionId}`)
      .then(() => {
        toast.success('Exceção excluída com sucesso!');
        api.get(`/appointments/findAllPricesExceptions`).then((response) => {
          setExceptions(response.data);
          setExceptionsLoading(false);
        });
      });
  }, [selectedExceptionId]);

  const handelOpenConfirmDialog = useCallback((id: string) => {
    setSelectedExceptionId(id);
    setIsOpenDialog(true);
  }, []);

  return (
    <Container>
      <ModalHeader title="Editar Preço" closeModal={closeModal} />
      <Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CurrencyInput
            control={control}
            label="Preço"
            name="price"
            prefix="R$"
            register={register}
            error={!!errors.price?.message}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="isNoble"
                defaultChecked={priceSelected.is_noble}
                value={isNoble}
                onChange={() => setIsNoble(!isNoble)}
              />
            }
            label="É horario nobre?"
            className="checkDiscount"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="allowDiscount"
                defaultChecked={priceSelected.allow_discount}
                value={allowDiscount}
                onChange={() => setAllowDiscount(!allowDiscount)}
              />
            }
            label="Permitir desconto"
            className="checkDiscount"
          />
          <CurrencyInput
            control={control}
            label="Valor do desconto"
            name="discount_value"
            prefix="R$"
            hidden={!allowDiscount}
            register={register}
            error={!!errors.discount_value?.message}
          />
          <div className="exceptionsContainer">
            <p>Exceções:</p>
            {exceptionsLoading ? (
              <p>Carregando...</p>
            ) : (
              <>
                {exceptions
                  .filter((i) => i.id_appointment_price === priceSelected.id)
                  .map((j) => (
                    <div className="content">
                      <span>{format(new Date(j.date), 'dd/MM/yyyy')}</span>
                      <span>{`R$ ${j.new_price.replace('.', ',')}`}</span>
                      <button
                        type="button"
                        onClick={() => handelOpenConfirmDialog(j.id)}
                      >
                        <FaTrashAlt size={14} />
                      </button>
                    </div>
                  ))}
              </>
            )}
          </div>
          <aside className="buttonsContainer">
            <button
              className="addExceptionButton"
              type="button"
              onClick={() => openExceptionModal()}
            >
              Adicionar exceção
            </button>
            <Button type="submit">{loading ? 'Aguarde...' : 'Salvar'}</Button>
          </aside>
        </form>
      </Content>
      <ConfirmDialog
        isOpen={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
        handleConfirm={() => handleDeleteException()}
        title="Deseja realmente apagar esta exceção ?"
      />
    </Container>
  );
};

export default EditPriceModal;

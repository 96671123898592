/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useCallback, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { MdAdd, MdCheckCircle, MdRemoveCircle } from 'react-icons/md';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import CurrencyInput from 'components/CurrencyInput';
import Input from 'components/Input';
import Button from 'components/Button';
import Table from 'components/Table';
import FullLoader from 'components/FullLoader';
import Pagination from 'components/Pagination';
import { modalStyle } from 'components/ModalStyle';

import { Charge } from 'interfaces/charges';
import { ChargeConfig } from 'interfaces/chargesConfig';
import { useUsers } from 'hooks/users';
import formatDate from 'utils/formatDate';
import api from 'services/api';

import { toast } from 'react-toastify';

// import EditPackageModal from './EditPackageModal';
import { Container, CurrentConfig } from './styles';

interface LastStepProps {
  day_of_week: number;
  price: string;
}

interface PageProps {
  id_user: string;
}

const schema = yup.object().shape({
  amount: yup.number().required(),
  price: yup.string().required(),
});

const UserCharges: React.FC<PageProps> = ({ id_user }) => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [charges, setCharges] = useState<Charge[]>([]);
  const [chargeConfig, setChargeConfig] = useState<ChargeConfig>(
    {} as ChargeConfig
  );
  const [selectedClass, setSelectedClass] = useState<Charge>({} as Charge);
  const dispatch = useDispatch();

  const { count = 0, users = [] } = useUsers();

  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setItemsPerPage(Number(e.target.value));
    setPage(1);
  };

  const handleNextPage = React.useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  const handlePreviousPage = React.useCallback(() => {
    setPage((prev) => prev - 1);
  }, []);

  useEffect(() => {
    api
      .get(`/payers/findAllUserCharges?id_user=${id_user}`)
      .then((response) => {
        setCharges(response.data);
        api
          .get(`/payers/findUserChargeConfig?id_user=${id_user}`)
          .then((response2) => {
            setChargeConfig(response2.data);
            setLoading(false);
          });
      });
  }, [page, itemsPerPage, dispatch, id_user]);

  const { register, handleSubmit, setValue, control, errors } =
    useForm<LastStepProps>({
      resolver: yupResolver(schema),
    });
  const onSubmit = useCallback(({ day_of_week, price }: LastStepProps) => {
    // const data = {
    //   id: initialData.id,
    //   amount,
    //   price,
    // };
    // api.put('/payers/updatePackage', data).then((response) => {
    //   toast.success('Pacote atualizado com sucesso!');
    //   closeModal();
    // });
  }, []);

  return (
    <Container>
      <FullLoader show={loading} />
      <CurrentConfig>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Input
              label="Dia do mês para cobrança"
              name="amount"
              register={register}
              error={!!errors.day_of_week?.message}
            />
          </div>
          <div>
            <CurrencyInput
              control={control}
              label="Valor total"
              name="price"
              prefix="R$ "
              register={register}
              error={!!errors.price?.message}
            />
          </div>
        </form>
      </CurrentConfig>
      <div className="table-container">
        <Table>
          <thead>
            <tr>
              <th align="left">Data</th>
              <th align="left">Valor</th>
              <th align="left">Estornado</th>
            </tr>
          </thead>
          <tbody>
            {charges?.map((item, index) => (
              <tr key={index}>
                <td>{formatDate(new Date(item?.paid_date))}</td>
                <td>{item?.paid_price}</td>
                <td>
                  {item?.reversed ? (
                    <MdCheckCircle color="green" size={10} />
                  ) : (
                    <MdRemoveCircle color="orange" size={25} />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="pagination-container">
          <div className="pagination-options">
            <p>Itens por página</p>
            <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div className="pagination">
            <Pagination
              offset={(page - 1) * itemsPerPage}
              currentCount={users?.length}
              total={count}
              handlePreviousPage={handlePreviousPage}
              handleNextPage={handleNextPage}
            />
          </div>
        </div>
      </div>
      {/* <Modal isOpen={addModalOpen} style={modalStyle}>
        <AddUserToClassModal
          id_user={id_user}
          user_name={user_name}
          ssn={ssn}
          closeModal={() => setAddModalOpen(false)}
          closeModalAndRefresh={() => handleCloseModalAndRefresh()}
        />
      </Modal>
      <Modal isOpen={editModalOpen} style={modalStyle}>
        <EditPackageModal
          initialData={selectedPackage}
          closeModal={() => handleCloseModal()}
        />
      </Modal> */}
    </Container>
  );
};

export default UserCharges;

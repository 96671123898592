import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: #fff;
  flex-direction: column;
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  padding: 22px;

  h1 {
    margin-top: 22px;
    font-size: 22px;
    font-weight: bolder;
    color: #838383;
  }
`;

import ProjectProps from './dtos/ProjectProps';

const Project: ProjectProps = {
  id_place: 'f688a5be-2df4-496e-9aa1-05c5d5da602e',
  primary_color: '#139fd4',
  secondary_color: '#e00265',
  login_background_img:
    'https://app-haka.s3.amazonaws.com/fundo_beach_tennis.jpeg',
  logo_url: 'https://app-haka.s3.amazonaws.com/logo_square.png',
  api_url: 'https://ahaya.pluma.tech',
};

export default Project;

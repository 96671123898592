/* eslint-disable no-nested-ternary */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useMenu } from 'context';
import { useUser } from 'hooks/users';

import { Container } from './styled';

const UserDetails = () => {
  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const user = useUser();

  const { setPageTitle } = useMenu();

  React.useEffect(() => {
    setPageTitle('Detalhes do usuário');
  }, [setPageTitle]);

  return <Container>{user?.name}</Container>;
};

export default UserDetails;

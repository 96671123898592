/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Controller } from 'react-hook-form';
import CurrencyInput from 'react-currency-input';

import { Container } from './styled';

interface CurrencyInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  register?: any;
  error?: boolean;
  label?: string;
  name?: any;
  defaultValue?: any;
  textHint?: string;
  control?: any;
}

const Input: React.FC<CurrencyInputProps> = ({
  register,
  error,
  label,
  name,
  textHint,
  defaultValue,
  control,
  ...rest
}) => {
  return (
    <Container error={error}>
      {label && <label>{label}</label>}
      <Controller
        as={
          <CurrencyInput
            decimalSeparator=","
            thousandSeparator="."
            prefix="R$ "
            {...rest}
          />
        }
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
      {textHint && <span className="text-hint">{textHint}</span>}
    </Container>
  );
};

export default Input;

/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-alert */
import React, { useState, useEffect, useCallback } from 'react';
import FullCalendar, {
  DateSelectArg,
  EventClickArg,
  EventContentArg,
  EventInput,
  EventChangeArg,
  EventApi,
} from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import ptbr from '@fullcalendar/core/locales/pt-br';
import { MdBook } from 'react-icons/md';
import api from 'services/api';
import { useMenu } from 'context';
import { Court } from 'interfaces/courts';
import { CalendarProps } from 'interfaces/calendar';
import FullLoader from 'components/FullLoader';
import Modal from 'react-modal';
import { modalStyle, modalStyleLarge } from 'components/ModalStyle';
import getCourtColors from 'utils/getCourtColors';
import { toast } from 'react-toastify';
import { addHours, differenceInHours } from 'date-fns';
import ConfirmDialog from 'components/ConfirmDialog';

import BlinkingDot from 'components/BlinkingDot';
import { FaFilter, FaPen, FaSearch, FaTimes } from 'react-icons/fa';
import AddEventModal from './AddEventModal';
import ClassItem from './ClassItem';
import CalendarItem from './CalendarItem';
import ClassEventModal from './ClassEventModal';
import MontlyEventModal from './MonthlyEventModal';
import AppointmentModal from './AppointmentModal';
import DayUseModal from './DayUseModal';
import {
  Container,
  OptionsContainer,
  Option,
  StyleWrapper,
  SectionCourts,
  SectionScheduling,
  EventTitle,
  InputSearch,
  SearchResults,
  SectionSearch,
} from './styles';
import SearchModal from './SearchModal';

interface SingleEvent {
  id: string;
  price: string;
  start_date: string;
  finish_date: string;
  canceled: boolean;
  created_at: string;
  updated_at: string;
  id_user: string;
  court_name: string;
  id_court: string;
  created_sequence: string;
  id_transaction: string;
  observation: string;
  cellphone: string;
  count_transaction: string;
}
interface RadioTypes {
  id: string;
  label: string;
  value: string;
}
const Calendar: React.FC = () => {
  const [weekendsVisible, setWeekendsVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [refreshing, setIsRefreshing] = useState(false);
  const [isOpenAddEventModal, setIsOpenAddEventModal] = useState(false);
  const [isOpenClassEventModal, setIsOpenClassEventModal] = useState(false);
  const [isOpenAppointmentModal, setIsOpenAppointmentModal] = useState(false);
  const [isOpenDayUseEventModal, setIsOpenDayUseEventModal] = useState(false);
  const [isOpenMontlyEventModal, setIsOpenMontlyEventModal] = useState(false);
  const [isOpenSearchModal, setIsOpenSearchModal] = useState(false);
  const [isOpenDialog, setisOpenDialog] = useState(false);
  const [results, setResults] = useState(false);
  const [idMensalistaApagar, setIdMensalistaApagar] = useState('');
  const [valueInputFilter, setValueInputFilter] = useState('');
  const [nomeMensalista, setNomeMensalista] = useState('');
  const [eventsCalendar, setEventsCalendar] = useState<EventInput[]>([]);
  const [dataEditAppointment, setDataEditAppointment] = useState<SingleEvent>();
  const [eventsCalendarFiltered, setEventsCalendarFiltered] = useState<
    EventInput[]
  >([]);
  const [detailedEvents, setDetailedEvents] = useState<CalendarProps[]>([]);
  const [courts, setCourts] = useState<Court[]>([]);
  const [options, setOptions] = useState<string[]>([
    'App',
    'Aula',
    'DayUse',
    'Mensalistas',
  ]);
  const [optionsCourts, setOptionsCourts] = useState<Court[]>([]);
  const [valueScheduling, setValueScheduling] = useState(0);
  const [selectedIdWeek, setSelectedIdWeek] = useState('');
  const [inputSearch, setInputSearch] = useState('');
  const [selectedIdSingleReservation, setSelectedIdSingleReservation] =
    useState('');

  const handleSelecScheduling = (e: any) => {
    const option = e.target.value;
    if (Number(option) === 1) {
      setIsOpenAddEventModal(true);
      setValueScheduling(0);
    }
    if (Number(option) === 2) {
      setIsOpenDayUseEventModal(true);
      setValueScheduling(0);
    }
    if (Number(option) === 3) {
      setIsOpenMontlyEventModal(true);
      setValueScheduling(0);
    }
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    const titleEvent = clickInfo.event._def.title;
    if (titleEvent?.split('-')[1].trim() === 'Aula') {
      handleOpenClassModal(clickInfo.event.id);
    }
    if (titleEvent?.split('-')[1].trim() === 'App') {
      handleOpenSingleModal(clickInfo.event.id);
    }
    if (titleEvent?.split('-')[1].trim() === 'Mensal') {
      setisOpenDialog(true);
      setNomeMensalista(titleEvent);
      setIdMensalistaApagar(clickInfo.event.id);
    }
  };
  const handleOpenSingleModal = useCallback((id: string) => {
    setSelectedIdSingleReservation(id);
    setIsOpenAppointmentModal(true);
  }, []);
  const handleOpenClassModal = useCallback((id: string) => {
    setSelectedIdWeek(id);
    setIsOpenClassEventModal(true);
  }, []);

  const radioTypes: RadioTypes[] = [
    {
      id: 'Agendamentos',
      label: 'Agendamentos',
      value: 'App',
    },
    {
      id: 'Aulas',
      label: 'Aulas',
      value: 'Aula',
    },
    {
      id: 'DayUse',
      label: 'Day Use',
      value: 'DayUse',
    },
    {
      id: 'Mensalistas',
      label: 'Mensalistas',
      value: 'Mensalistas',
    },
  ];

  const { setPageTitle } = useMenu();

  useEffect(() => {
    api
      .get(`/courts/findAll?id_place=${process.env.REACT_APP_ID_PLACE}&page=1`)
      .then((response) => {
        setCourts(response.data);
        setOptionsCourts(response.data);
        api
          .get(
            `/appointments/findCalendarEvents?id_place=${process.env.REACT_APP_ID_PLACE}`
          )
          .then((responseEvents) => {
            setEventsCalendar(responseEvents.data);
            setEventsCalendarFiltered(responseEvents.data);
            setDetailedEvents(responseEvents.data);
          });
      });
    setPageTitle('Calendário de eventos');
  }, [setPageTitle]);

  const refreshPage = useCallback(() => {
    setIsRefreshing(true);
    setTimeout(() => {
      api
        .get(
          `/appointments/findCalendarEvents?id_place=${process.env.REACT_APP_ID_PLACE}`
        )
        .then((responseEvents) => {
          setEventsCalendar(responseEvents.data);
          setEventsCalendarFiltered(responseEvents.data);
          setDetailedEvents(responseEvents.data);
          setIsRefreshing(false);
        });
    }, 3000);
  }, []);

  const handleSelectType = useCallback(
    (value: string) => {
      if (options.indexOf(value) > -1) {
        setOptions(options.filter((item) => item !== value));
        setEventsCalendarFiltered(
          eventsCalendar.filter(
            (item) => item.title?.split('-')[1].trim() !== value
          )
        );
      } else {
        const tempOptions: string[] = options;
        let tempArray: EventInput[] = [];

        tempOptions.push(value);
        setOptions(tempOptions);

        tempArray = eventsCalendar.filter(
          (item) =>
            tempOptions.indexOf(item.title?.split('-')[1].trim() || '') > -1
        );

        setEventsCalendarFiltered(tempArray);
      }
    },
    [options, eventsCalendar]
  );

  const handleSelectCourt = useCallback(
    (value: Court) => {
      if (optionsCourts.indexOf(value) > -1) {
        setOptionsCourts(optionsCourts.filter((item) => item !== value));
        setEventsCalendarFiltered(
          eventsCalendarFiltered.filter((item) => item.id_court !== value.id)
        );
      } else {
        const tempOptions: Court[] = optionsCourts;
        let tempArray: EventInput[] = [];

        tempOptions.push(value);
        setOptionsCourts(tempOptions);

        tempArray = eventsCalendar.filter(
          (item) =>
            tempOptions.filter((item2) => item2.id === value.id).length > 0
        );

        setEventsCalendarFiltered(tempArray);
      }
    },
    [optionsCourts, eventsCalendarFiltered, eventsCalendar]
  );

  const handleChangeEvent = useCallback((event: EventApi) => {
    setLoading(true);
    const data = {
      id_appointment: event._def.publicId,
      newDate: addHours(event._instance?.range.start || new Date(), 3),
      observation: event._def.title,
      id_court: event._def.extendedProps.id_court,
      range: differenceInHours(
        event._instance?.range.end || new Date(),
        event._instance?.range.start || new Date()
      ),
    };

    api.put('/appointments/editAppointment', data).then((response) => {
      toast.success('Reserva alterada com sucesso!');
      setLoading(false);
    });
  }, []);

  const handleCancelAppointment = useCallback(
    (id_appointment: string, id_transaction: string | undefined) => {
      setLoading(true);
      api
        .post(`/payments/refundCharge?id_transaction=${id_transaction}`)
        .then(() => {
          api
            .put(
              `/appointments/cancelAppointment?id_appointment=${id_appointment}`
            )
            .then(() => {
              toast.success('Reserva cancelada com sucesso!');
              setLoading(false);
              refreshPage();
            });
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [refreshPage]
  );

  const handleConfirmDeletMensalista = useCallback(() => {
    api
      .delete(`/monthly/removeMonthly?id_monthly=${idMensalistaApagar}`)
      .then((response) => {
        setisOpenDialog(false);
        toast.success('Mensalista Apagado com sucesso!');
        refreshPage();
      });
  }, [idMensalistaApagar, refreshPage]);
  const handleCloseConfirm = useCallback(() => {
    setisOpenDialog(false);
  }, []);

  const handleCloseAddAppointmentModal = useCallback(() => {
    setIsOpenAddEventModal(false);
    refreshPage();
  }, [refreshPage]);

  const handleCloseViewAppointmentModal = useCallback(() => {
    setIsOpenAppointmentModal(false);
    refreshPage();
  }, [refreshPage]);

  const handleEditAppointment = useCallback(() => {
    setIsOpenAppointmentModal(false);
    api
      .get(
        `/appointments/findById?id_appointment=${selectedIdSingleReservation}`
      )
      .then((response) => {
        setDataEditAppointment(response.data);
        setIsOpenAddEventModal(true);
      });
  }, [selectedIdSingleReservation]);

  const renderEventContent = useCallback(
    (eventContent: EventContentArg) => {
      const { title } = eventContent.event;
      const eventsFiltered = detailedEvents.filter(
        (item) => item.id === eventContent.event.id
      )[0];
      return (
        <>
          {title.substring(title.length - 4) === 'Aula' ? (
            <ClassItem
              eventsFiltered={eventsFiltered}
              eventContent={eventContent}
            />
          ) : (
            <>
              {title.substring(title.length - 6) === 'Mensal' ? (
                <EventTitle>
                  <MdBook name="#fff" size={20} />
                  <p>{`Mensal - ${eventContent.event.title.split('-')[0]}`}</p>
                </EventTitle>
              ) : (
                <>
                  <CalendarItem
                    eventsFiltered={eventsFiltered}
                    eventContent={eventContent}
                    handleCancelAppointment={(
                      id_appointment: string,
                      id_transaction: string | undefined
                    ) =>
                      handleCancelAppointment(id_appointment, id_transaction)
                    }
                  />
                </>
              )}
            </>
          )}
        </>
      );
    },
    [detailedEvents, handleCancelAppointment]
  );
  const handleShowResults = useCallback(() => {
    if (eventsCalendarFiltered.length > 0) {
      setResults(true);
    } else {
      setResults(false);
    }
  }, [eventsCalendarFiltered]);

  const handleFiltereservations = useCallback(
    (name: string) => {
      setLoading(true);
      setInputSearch(name);
      setEventsCalendarFiltered(
        eventsCalendar.filter(
          // @ts-ignore
          (item) => item.title?.toLowerCase().indexOf(name.toLowerCase()) > -1
        )
      );
      handleShowResults();
      setLoading(false);
    },
    [eventsCalendar, handleShowResults]
  );

  const handleClearFilter = useCallback(() => {
    setLoading(true);
    setInputSearch('');
    setEventsCalendarFiltered(eventsCalendar);
    setLoading(false);
  }, [eventsCalendar]);

  return (
    <Container>
      <FullLoader show={loading} />
      <OptionsContainer>
        {radioTypes.map((item) => (
          <Option
            key={item.id}
            selected={options.indexOf(item.value) > -1}
            onClick={() => handleSelectType(item.value)}
          >
            <button type="button" />
            <p>{item.label}</p>
          </Option>
        ))}
        <SectionCourts>
          <h3>Quadras</h3>
          {courts.map((court, i) => (
            <Option
              key={court.id}
              selected={optionsCourts.indexOf(court) > -1}
              borderColor={getCourtColors(i)}
              onClick={() => handleSelectCourt(court)}
            >
              <button type="button" />
              <p>{court?.courtname}</p>
            </Option>
          ))}
        </SectionCourts>
        <SectionScheduling>
          <h3>Criar...</h3>
          <select onChange={handleSelecScheduling} value={valueScheduling}>
            <option selected hidden>
              Selecione...
            </option>
            <option value="1">Agendamento</option>
            <option value="2">Day Use</option>
            <option value="3">Mensalista</option>
          </select>
        </SectionScheduling>
      </OptionsContainer>
      <div className="sectionCalendar">
        <SectionSearch onBlur={() => handleShowResults()}>
          <InputSearch>
            <button type="button" title="Filtrar">
              <FaSearch color="#999" size={20} />
            </button>
            <input
              id="FilterUser"
              type="text"
              placeholder="Filtrar"
              onChange={(e) => handleFiltereservations(e.target.value)}
              value={inputSearch}
            />
            <button
              type="button"
              title={inputSearch ? 'Limpar' : 'Filtrar'}
              onClick={() => handleClearFilter()}
            >
              {inputSearch ? (
                <FaTimes color="#999" size={20} />
              ) : (
                <FaFilter color="#999" size={20} />
              )}
            </button>
          </InputSearch>
          {results && (
            <SearchResults>
              <button
                onClick={() => setIsOpenSearchModal(true)}
                className="addClientButton"
                type="button"
              >
                <span>Ver detalhes</span>
              </button>
              {/* <ul>
                {eventsCalendarFiltered.map((event) => (
                  <li>
                    {event.title} - {event.start} -
                    <FaPen color="#999" size={16} />
                  </li>
                ))}
              </ul> */}
            </SearchResults>
          )}
        </SectionSearch>
        {refreshing && <BlinkingDot />}
        <StyleWrapper
          style={{
            visibility:
              isOpenAddEventModal ||
              isOpenClassEventModal ||
              isOpenDayUseEventModal ||
              isOpenAppointmentModal ||
              isOpenMontlyEventModal ||
              isOpenSearchModal
                ? 'hidden'
                : 'visible',
          }}
        >
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay',
            }}
            initialView="timeGridWeek"
            editable
            // height="50pc"
            locale={ptbr}
            selectable
            selectMirror
            dayMaxEvents
            weekends={weekendsVisible}
            events={eventsCalendarFiltered}
            eventContent={renderEventContent}
            eventClick={handleEventClick}
            // eventDisplay="list-item"
            droppable
            slotDuration="01:00"
            eventChange={(arg) => handleChangeEvent(arg.event)}
          />
        </StyleWrapper>
      </div>
      <FullLoader show={loading} />
      <Modal isOpen={isOpenAddEventModal} style={modalStyle}>
        <AddEventModal
          closeModal={() => setIsOpenAddEventModal(false)}
          closeModalAndRefresh={() => handleCloseAddAppointmentModal()}
          // @ts-ignore
          dataEvent={dataEditAppointment}
        />
      </Modal>
      <Modal isOpen={isOpenDayUseEventModal} style={modalStyle}>
        <DayUseModal
          closeModal={() => setIsOpenDayUseEventModal(false)}
          closeAndRefresh={refreshPage}
          // eventIsEditable={eventIsEditable}
        />
      </Modal>
      <Modal isOpen={isOpenClassEventModal} style={modalStyle}>
        <ClassEventModal
          id_week={selectedIdWeek}
          closeModal={() => setIsOpenClassEventModal(false)}
        />
      </Modal>
      <Modal isOpen={isOpenMontlyEventModal} style={modalStyle}>
        <MontlyEventModal closeModal={() => setIsOpenMontlyEventModal(false)} />
      </Modal>
      <Modal isOpen={isOpenAppointmentModal} style={modalStyle}>
        <AppointmentModal
          id_reservation={selectedIdSingleReservation}
          closeModal={() => setIsOpenAppointmentModal(false)}
          closeAndRefresh={() => handleCloseViewAppointmentModal()}
          handleEdit={() => handleEditAppointment()}
        />
      </Modal>
      <Modal isOpen={isOpenSearchModal} style={modalStyleLarge}>
        <SearchModal
          inputSearch={inputSearch}
          closeModal={() => setIsOpenSearchModal(false)}
          eventsCalendarFiltered={eventsCalendarFiltered}
          handleFiltereservations={handleFiltereservations}
          // closeAndRefresh={() => handleCloseViewAppointmentModal()}
          // handleEdit={() => handleEditAppointment()}
        />
      </Modal>
      <ConfirmDialog
        isOpen={isOpenDialog}
        onClose={handleCloseConfirm}
        handleConfirm={handleConfirmDeletMensalista}
        title={`Deseja realmente apagar  o mensalista ${nomeMensalista} ?`}
      />
    </Container>
  );
};

export default Calendar;

import styled from 'styled-components';

export const Container = styled.div`
  height: 90%;
`;

export const Content = styled.div`
  height: 100%;
  margin-top: 40px;
  form {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .select {
      width: inherit;
      margin: 7px 0px;
    }
    .inputDateTime {
      width: inherit;
      height: 52px;
      /* border: 1px solid #d2d2d4; */
      padding: 22px;
      margin-top: 7px;
    }
    button[type='submit'] {
      margin: 15px 0;
    }
  }
`;

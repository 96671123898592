import React, { useCallback, useState, useEffect } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/Input';
import Button from 'components/Button';
import ModalHeader from 'components/ModalHeader';
import api from 'services/api';

import { toast } from 'react-toastify';
import { Container, Content } from './styles';

interface Item {
  id: string;
  name: string;
}
interface PageProps {
  closeModal: () => void;
  closeModalAndRefresh: () => void;
  childrenItem: Item;
}

interface LastStepProps {
  name: string;
}

const schema = yup.object().shape({
  name: yup.string().required(),
});

const EditItemModal: React.FC<PageProps> = ({
  closeModal,
  closeModalAndRefresh,
  childrenItem,
}) => {
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, setValue, control, errors } =
    useForm<LastStepProps>({
      resolver: yupResolver(schema),
    });
  useEffect(() => {
    setLoading(true);
    setValue('name', childrenItem.name);
    setLoading(false);
  }, [setValue, childrenItem, setLoading]);
  const onSubmit = useCallback(
    ({ name }: LastStepProps) => {
      const data = {
        id: childrenItem.id,
        name,
      };

      api
        .put('/interests/updateChildrenInterestItem', data)
        .then((response) => {
          toast.success('Item atualizado com sucesso!');
          closeModalAndRefresh();
        });
    },
    [closeModalAndRefresh, childrenItem]
  );

  return (
    <Container>
      <ModalHeader title="Novo item" closeModal={closeModal} />
      <Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            label="Nome"
            name="name"
            register={register}
            error={!!errors.name?.message}
          />
          <Button type="submit">{loading ? 'Entrando...' : 'Salvar'}</Button>
        </form>
      </Content>
    </Container>
  );
};

export default EditItemModal;

import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  form {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 40vh;
  }
`;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-alert */
import React, { useEffect, useCallback, useState } from 'react';

import Dropdown, { Option as ComboOptions } from 'react-dropdown';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { FaCheckCircle, FaMinusCircle } from 'react-icons/fa';

import ModalHeader from 'components/ModalHeader';
import FullLoader from 'components/FullLoader';
import { Teacher } from 'interfaces/teacher';
import { TeacherClass } from 'interfaces/teacherClass';
import { UserClasses } from 'interfaces/usersClasses';
import { CreateTeacherUserClasses } from 'interfaces/createTeacherUserClasses';

import api from 'services/api';
import { toast } from 'react-toastify';
import {
  Container,
  Content,
  TeacherItem,
  TeacherClasses,
  HourItem,
  SaveButton,
} from './styles';

interface PageProps {
  id_user: string;
  user_name: string;
  ssn: string;
  classes: UserClasses[];
  closeModal: () => void;
  closeModalAndRefresh: () => void;
}

interface TeacherClassList {
  day_of_week: string;
  hours: TeacherClass[];
}

const AddUserToClassModal: React.FC<PageProps> = ({
  id_user,
  user_name,
  ssn,
  classes,
  closeModal,
  closeModalAndRefresh,
}) => {
  const [loading, setLoading] = useState(false);
  const [remainingPackages, setRemainingPackages] = useState(0);
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [selectedTeacher, setSelectedTeacher] = useState<Teacher>(
    {} as Teacher
  );
  const [teacherClasses, setTeacherClasses] = useState<TeacherClassList[]>([]);
  const [selectedClasses, setSelectedClasses] = useState<
    CreateTeacherUserClasses[]
  >([]);
  const [comboOptions, setComboOptions] = useState<ComboOptions[]>([
    { value: 'Nenhum', label: 'Nenhum' },
    { value: 'Mensal', label: 'Mensal' },
  ]);

  useEffect(() => {
    api
      .get(`/payers/findRemainingPackages?id_user=${id_user}`)
      .then((response) => {
        setRemainingPackages(response.data);
        setLoading(false);
        if (Number(response.data) > 0) {
          setComboOptions([
            ...comboOptions,
            { value: 'Pacotes', label: 'Pacotes' },
          ]);
        }
      });
  }, [id_user]);

  useEffect(() => {
    api.get('/teachers/findAllTeachers').then((response) => {
      setTeachers(response.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (selectedTeacher.id > '') {
      setLoading(true);
      api
        .get(`/teachers/findTeacherClasses?id_teacher=${selectedTeacher.id}`)
        .then((response) => {
          setTeacherClasses(response.data);
          setLoading(false);
        });
    }
  }, [selectedTeacher]);

  const handleAddClassToCart = useCallback(
    (hour: CreateTeacherUserClasses) => {
      const index = selectedClasses
        .map(function temp(e) {
          return e.id_class;
        })
        .indexOf(hour.id_class);

      if (index > -1) {
        if (hour.type === 'Nenhum') {
          setSelectedClasses(
            selectedClasses.filter((item2) => item2.id_class !== hour.id_class)
          );
        } else {
          const tempArray = selectedClasses;
          tempArray[index] = hour;
          setSelectedClasses(tempArray);
        }
      } else {
        setSelectedClasses([...selectedClasses, hour]);
      }
    },
    [selectedClasses]
  );

  const handleFinish = useCallback(() => {
    const result = window.confirm(
      'Deseja realmente adicionar este cliente nas aulas selecionadas?'
    );
    if (result) {
      setLoading(true);
      selectedClasses.map((item) => {
        const data = {
          id_class: item.id_class,
          id_user,
          id_transaction: '',
          name: user_name,
          ssn,
          birth_date: new Date(),
          type: item.type,
        };
        api.post('/teachers/createClassUser', data);
        return null;
      });
      setLoading(false);
      toast.success('Aulas adicionadas com sucesso!');
      closeModalAndRefresh();
    }
  }, [selectedClasses, id_user, user_name, ssn, closeModalAndRefresh]);

  const verifyUserIsInClass = useCallback(
    (id_class) => {
      const index = classes
        .map(function temp(e) {
          return e.id_teacherclass;
        })
        .indexOf(id_class);

      if (index > -1) {
        return true;
      }
      return false;
    },
    [classes]
  );

  function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        aria-hidden
        className={className}
        style={{
          ...style,
          display: 'block',
          background: '#000',
          borderRadius: 60,
        }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SampleNextArrow />,
  };

  return (
    <>
      <Container>
        <ModalHeader title="Selecione as aulas" closeModal={closeModal} />
        <Content>
          <ScrollMenu>
            {teachers.map((teacher) => {
              return (
                <TeacherItem
                  selected={teacher === selectedTeacher}
                  onClick={() => setSelectedTeacher(teacher)}
                >
                  <p>{teacher.name}</p>
                </TeacherItem>
              );
            })}
          </ScrollMenu>
          <TeacherClasses>
            {teacherClasses.map((item) => {
              return (
                <div>
                  <h1>{item.day_of_week}</h1>
                  {item.hours.map((hour) => {
                    return (
                      <HourItem selected>
                        <p>
                          {Number(hour.hour) < 10
                            ? `0${hour.hour}:00`
                            : `${hour.hour}:00`}
                        </p>
                        <p>{`R$ ${String(hour?.price).replace('.', ',')}`}</p>
                        <p>{`${hour.users_in_list} aluno(s)`}</p>
                        {hour.limit - hour.users_in_list > 0 ? (
                          <>
                            {!verifyUserIsInClass(hour.id) ? (
                              <div>
                                {/* @ts-ignore */}
                                <Dropdown
                                  options={comboOptions}
                                  placeholder="Selecione"
                                  onChange={(value) =>
                                    handleAddClassToCart({
                                      id_user,
                                      id_class: hour.id,
                                      type: value.value,
                                    })
                                  }
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                        {verifyUserIsInClass(hour.id) ? (
                          <></>
                        ) : (
                          <div className="statusBadge">
                            {selectedClasses
                              .map(function temp(e) {
                                return e.id_class;
                              })
                              .indexOf(hour.id) > -1 ? (
                              <FaCheckCircle color="#006400" size={15} />
                            ) : (
                              <FaMinusCircle color="#ffd700" size={15} />
                            )}
                          </div>
                        )}
                      </HourItem>
                    );
                  })}
                </div>
              );
            })}
          </TeacherClasses>
        </Content>
        <SaveButton onClick={() => handleFinish()}>
          <p>Salvar</p>
        </SaveButton>
        <FullLoader show={loading} />
      </Container>
    </>
  );
};

export default AddUserToClassModal;

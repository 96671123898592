import { useMenu } from 'context';
import React from 'react';

function Dashboard() {
  const { setPageTitle } = useMenu();

  React.useEffect(() => {
    setPageTitle('Dashboard');
  }, [setPageTitle]);

  return <h1>Olá!</h1>;
}

export default Dashboard;

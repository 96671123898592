import React, { useEffect, useMemo } from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';

import { io } from 'socket.io-client';
import { MenuProvider, AuthProvider, PlaceProvider } from 'context';
import { useUser } from 'hooks/users';
import { toast } from 'react-toastify';
import PrivateRoute from './PrivateRoute';
import menus from './menus';

const Routes: React.FC = () => {
  const user = useUser();

  // const socket = useMemo(
  //   () =>
  //     io(`http://localhost:8888/`, {
  //       query: {
  //         id_user: user?.id || '',
  //       },
  //     }),
  //   [user?.id]
  // );

  // useEffect(() => {
  //   socket.on('newMonthlyRequest', () => {
  //     alert('entrou aqui');
  //     toast.info('Nova solicitação de mensalista!');
  //   });
  // }, [socket]);

  return (
    <Router>
      <Switch>
        <MenuProvider>
          <AuthProvider>
            <PlaceProvider>
              {menus
                .filter((menu) => menu.component)
                .map(({ path, component, isPrivate }, index) => {
                  if (index === 0) {
                    return (
                      <PrivateRoute
                        key={index}
                        exact
                        path={path}
                        component={component as React.FC}
                      />
                    );
                  }

                  return isPrivate ? (
                    <PrivateRoute
                      key={index}
                      path={path}
                      exact
                      component={component as React.FC}
                    />
                  ) : (
                    <Route
                      exact
                      key={index}
                      path={path}
                      component={component}
                    />
                  );
                })}
            </PlaceProvider>
          </AuthProvider>
        </MenuProvider>
      </Switch>
    </Router>
  );
};

export default Routes;

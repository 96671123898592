import React, { useCallback, useState, useRef } from 'react';

import Webcam from 'react-webcam';
import { Container } from './styles';

interface PageProps {
  id_user: string;
}

const UserPhoto: React.FC<PageProps> = ({ id_user }) => {
  const webcamRef = useRef<Webcam>(null);

  return (
    <Container>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        style={{ borderRadius: 40 }}
      />
    </Container>
  );
};

export default UserPhoto;

import React from 'react';

import { Container } from './styles';

const BlinkingDot: React.FC = () => {
  return (
    <Container>
      <span className="blink_me" />
      <p>Atualizando...</p>
    </Container>
  );
};

export default BlinkingDot;

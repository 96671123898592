import React, { useEffect, useCallback, useState } from 'react';

import Classes from './Classes';
import CreditCardToCharge from './CreditCardToCharge';
import Charges from './Charges';
import { Container, OptionsContainer, Option, Content } from './styles';

interface RadioTypes {
  id: string;
  label: string;
  value: string;
}

interface PageProps {
  id_user: string;
  user_name: string;
  ssn: string;
}

const Monthly: React.FC<PageProps> = ({ id_user, user_name, ssn }) => {
  const [type, setType] = useState('');

  const radioTypes: RadioTypes[] = [
    {
      id: 'Aulas',
      label: 'Aulas',
      value: '1',
    },
    {
      id: 'Pagamento',
      label: 'Dados Pagamento',
      value: '2',
    },
    {
      id: 'Cobranças',
      label: 'Cobranças',
      value: '3',
    },
  ];
  return (
    <Container>
      <OptionsContainer>
        {radioTypes.map((item) => (
          <Option
            key={item.id}
            selected={type === item.value}
            onClick={() => setType(item.value)}
          >
            <button type="button" />
            <p>{item.label}</p>
          </Option>
        ))}
      </OptionsContainer>
      <Content>
        {type !== '' ? (
          <>
            {type === '1' ? (
              <Classes id_user={id_user} user_name={user_name} ssn={ssn} />
            ) : (
              <>
                {type === '2' ? (
                  <CreditCardToCharge id_user={id_user} ssn={ssn} />
                ) : (
                  <Charges id_user={id_user} />
                )}
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </Content>
    </Container>
  );
};

export default Monthly;

/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useCallback, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';

import Table from 'components/Table';
import FullLoader from 'components/FullLoader';
import Pagination from 'components/Pagination';
import { modalStyle } from 'components/ModalStyle';
import { MdEdit, MdDeleteForever } from 'react-icons/md';
import api from 'services/api';
import { toast } from 'react-toastify';
import { useQuery } from 'hooks/query';
import { FaFileAlt, FaPlus } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';
import AddItemtModal from './AddItemModal';
import EditItemModal from './EditItemModal';
import ResponseReportModal from './ResponseReportModal';

interface ItemsInterestProps {
  id: string;
  name: string;
}

const ViewItems: React.FC = () => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [addItemModalOpen, setAddItemModalOpen] = useState(false);
  const [editItemModalOpen, setEditItemModalOpen] = useState(false);
  const [responseReportModalOpen, setResponseReportModalOpen] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [idChildrenInterestItem, setIdChildrenInterestItem] = useState('');
  const [childrenItem, setChildrenItem] = useState<ItemsInterestProps>(
    {} as ItemsInterestProps
  );

  const [interestItems, setInterestItems] = useState<ItemsInterestProps[]>();

  const id_interest_item = useQuery().get('id_interest_item');
  const history = useHistory();
  const dispatch = useDispatch();

  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setItemsPerPage(Number(e.target.value));
    setPage(1);
  };

  const handleNextPage = React.useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  const handlePreviousPage = React.useCallback(() => {
    setPage((prev) => prev - 1);
  }, []);

  useEffect(() => {
    api
      .get(
        `/interests/findChildrenInterestItemById?id_interest_item=${id_interest_item}`
      )
      .then((response) => {
        setInterestItems(response.data);
        setLoading(false);
      });
  }, [page, itemsPerPage, dispatch, id_interest_item]);

  const handleCloseModal = useCallback(() => {
    setAddItemModalOpen(false);
    setEditItemModalOpen(false);
    setResponseReportModalOpen(false);
  }, []);
  const handleCloseModalAndRefresh = useCallback(() => {
    setAddItemModalOpen(false);
    setEditItemModalOpen(false);
    setLoading(true);
    api
      .get(
        `/interests/findChildrenInterestItemById?id_interest_item=${id_interest_item}`
      )
      .then((response) => {
        setInterestItems(response.data);
        setLoading(false);
      });
  }, [setAddItemModalOpen, setInterestItems, id_interest_item]);

  const handleDeleteChildrenInterestItem = useCallback(
    (id: string) => {
      const result = window.confirm('Deseja realmente apagar Item?');
      if (result) {
        setLoading(true);
        api
          .delete(`/interests/deleteChildrenInterestItem?id=${id}`)
          .then((response) => {
            toast.success('Pacote apagado com sucesso!');
            api
              .get(
                `/interests/findChildrenInterestItemById?id_interest_item=${id_interest_item}`
              )
              .then((response2) => {
                setInterestItems(response2.data);
                setLoading(false);
              });
          });
      }
    },
    [setLoading, id_interest_item]
  );
  const handleEditItem = useCallback((item) => {
    setChildrenItem(item);
    setEditItemModalOpen(true);
  }, []);
  const handleResponseReportModalOpen = useCallback((idItem) => {
    // setChildrenItem(item);
    setIdChildrenInterestItem(idItem);
    setResponseReportModalOpen(true);
  }, []);
  return (
    <Container>
      <FullLoader show={loading} />
      <button
        className="addButton"
        type="button"
        onClick={() => setAddItemModalOpen(true)}
      >
        <FaPlus size={20} color="#fff" />
        <span>Adicionar Item</span>
      </button>

      <div className="table-container">
        <Table>
          <thead>
            <tr>
              <th align="left">Item</th>
              <th align="left">Editar</th>
              <th align="left">Excluir</th>
              <th align="left">Relatório</th>
            </tr>
          </thead>
          <tbody>
            {interestItems?.map((item, index) => (
              <tr key={index}>
                <td>
                  <p>{item?.name}</p>
                </td>
                <td>
                  <button type="button" onClick={() => handleEditItem(item)}>
                    <MdEdit color="#286738" size={20} />
                  </button>
                </td>
                <td>
                  <button
                    type="button"
                    onClick={() => handleDeleteChildrenInterestItem(item.id)}
                  >
                    <MdDeleteForever color="#cc0000" size={20} />
                  </button>
                </td>
                <td>
                  <button
                    type="button"
                    onClick={() => handleResponseReportModalOpen(item.id)}
                  >
                    <FaFileAlt color="#d1ff06" size={20} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="pagination-container">
          <div className="pagination-options">
            <p>Itens por página</p>
            <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div className="pagination">
            {/* <Pagination
              offset={(page - 1) * itemsPerPage}
              currentCount={interestItems?.length}
              total={interestItems?.length}
              handlePreviousPage={handlePreviousPage}
              handleNextPage={handleNextPage}
            /> */}
          </div>
          <button
            type="button"
            title="Relatório Geral"
            onClick={() => history.push(`/responsereport`)}
          >
            <FaFileAlt color="#286738" size={20} />
          </button>
        </div>
      </div>
      <Modal isOpen={addItemModalOpen} style={modalStyle}>
        <AddItemtModal
          closeModal={() => handleCloseModal()}
          closeModalAndRefresh={() => handleCloseModalAndRefresh()}
          id_interest_item={id_interest_item}
        />
      </Modal>
      <Modal isOpen={addItemModalOpen} style={modalStyle}>
        <AddItemtModal
          closeModal={() => handleCloseModal()}
          closeModalAndRefresh={() => handleCloseModalAndRefresh()}
          id_interest_item={id_interest_item}
        />
      </Modal>
      <Modal isOpen={editItemModalOpen} style={modalStyle}>
        <EditItemModal
          closeModal={() => handleCloseModal()}
          closeModalAndRefresh={() => handleCloseModalAndRefresh()}
          childrenItem={childrenItem}
        />
      </Modal>
      <Modal isOpen={responseReportModalOpen} style={modalStyle}>
        <ResponseReportModal
          closeModal={() => handleCloseModal()}
          closeModalAndRefresh={() => handleCloseModalAndRefresh()}
          idItem={idChildrenInterestItem}
        />
      </Modal>
    </Container>
  );
};

export default ViewItems;

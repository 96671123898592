import config from 'config';
import ProjectProps from './dtos/ProjectProps';
import Haka from './Haka';
import Reinaldo from './Reinaldo';
import Ahaya from './Ahaya';

export default (): ProjectProps => {
  switch (config.currentProject) {
    case 'Reinaldo':
      return Reinaldo;
    case 'Haka':
      return Haka;
    case 'Ahaya':
      return Ahaya;
    default:
      return Reinaldo;
  }
};

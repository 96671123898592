import React, { useMemo } from 'react';

import { MdSportsBaseball } from 'react-icons/md';
import FullCalendar, { EventContentArg } from '@fullcalendar/react';
import validator from 'validator';
import { CalendarProps } from '../../../interfaces/calendar';
import { Container, AppointmentDetail, EventTitle } from './styles';

interface PageProps {
  eventsFiltered: CalendarProps;
  eventContent: EventContentArg;
  handleCancelAppointment: (
    id_appointment: string,
    id_transaction: string | undefined
  ) => void;
}

const CalendarItem: React.FC<PageProps> = ({
  eventsFiltered,
  eventContent,
  handleCancelAppointment,
}) => {
  function itemTitle(item: string): string {
    if (validator.isUUID(item.split(' - App')[0])) {
      return 'Day Use';
    }
    return item.split('-')[0];
  }

  return (
    <AppointmentDetail>
      <EventTitle>
        <MdSportsBaseball name="#fff" size={20} />
        <p>{itemTitle(eventContent.event.title)}</p>
      </EventTitle>
      <br />
      {eventsFiltered?.appointmentDetail ? (
        <>
          {Number(eventsFiltered.appointmentDetail?.price) === 0 ? (
            <p>Evento criado pelo administrador</p>
          ) : (
            <>
              <p>Reserva avulsa</p>
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </AppointmentDetail>
  );
};

export default CalendarItem;

import React, { useEffect, useCallback, useState } from 'react';

import { MdSave } from 'react-icons/md';
import Card from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

import api from 'services/api';

import FullLoader from 'components/FullLoader';
import { toast } from 'react-toastify';
import { Container } from './styles';

interface PageProps {
  id_user: string;
  ssn: string;
}

interface CardData {
  id_user: string;
  ssn: string;
  number: string;
  holder: string;
  expiry: string;
  cvc: string;
}

const CreditCardToCharge: React.FC<PageProps> = ({ id_user, ssn }) => {
  const [loading, setLoading] = useState(false);
  const [cardData, setCardData] = useState<CardData>({
    id_user: '',
    ssn: '',
    number: '',
    holder: '',
    expiry: '',
    cvc: '',
  });

  useEffect(() => {
    api
      .get(`/payers/findUserCreditCard?id_user=${id_user}`)
      .then((response) => {
        if (response.data) {
          setCardData(response.data);
        }
        setLoading(false);
      });
  }, [id_user]);

  const handleSubmit = useCallback(() => {
    setLoading(true);
    const newData = cardData;
    newData.id_user = id_user;
    newData.ssn = ssn;
    api
      .post('/payers/createMonthlyCreditCard', newData)
      .then((response) => {
        toast.success('Cartão criado com sucesso!');
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [cardData, id_user, ssn]);

  return (
    <Container>
      <div key="Payment">
        <div className="App-payment">
          <Card
            number={cardData.number}
            name={cardData.holder}
            expiry={cardData.expiry}
            cvc={cardData.cvc}
          />
          <form>
            <div className="form-group">
              <input
                type="tel"
                name="number"
                className="form-control"
                placeholder="Número"
                pattern="[\d| ]{16,22}"
                required
                value={cardData.number}
                onChange={(e) =>
                  setCardData({ ...cardData, number: e.target.value })
                }
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="Nome (como está no cartão)"
                required
                value={cardData.holder}
                onChange={(e) =>
                  setCardData({ ...cardData, holder: e.target.value })
                }
              />
            </div>
            <div className="col-6">
              <input
                type="tel"
                name="expiry"
                className="form-control"
                placeholder="Validade"
                pattern="\d\d/\d\d"
                required
                value={cardData.expiry}
                onChange={(e) =>
                  setCardData({ ...cardData, expiry: e.target.value })
                }
              />
            </div>
            <div className="col-6">
              <input
                type="tel"
                name="cvc"
                className="form-control"
                placeholder="CVC"
                pattern="\d{3,4}"
                required
                value={cardData.cvc}
                onChange={(e) =>
                  setCardData({ ...cardData, cvc: e.target.value })
                }
              />
            </div>

            <input type="hidden" name="issuer" />
            <div className="form-actions">
              <button
                type="button"
                className="btn btn-primary btn-block"
                onClick={handleSubmit}
              >
                <MdSave color="#fff" size={15} />
                <p>Salvar</p>
              </button>
            </div>
          </form>
        </div>
      </div>
      <FullLoader show={loading} />
    </Container>
  );
};

export default CreditCardToCharge;

/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable array-callback-return */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useCallback, useState } from 'react';
import Modal from 'react-modal';
import { useMenu, usePlace } from 'context';
import FullLoader from 'components/FullLoader';
import { modalStyle } from 'components/ModalStyle';
import api from 'services/api';
import { FaEdit } from 'react-icons/fa';
import { PriceCourts } from 'interfaces/priceCourts';
import Select from 'components/ReactSelect';
import { toast } from 'react-toastify';
import colors from 'config/colors';
import AddPriceModal from './AddPriceModal';
import { Container } from './styles';
import EditPriceModal from './EditPriceModal';
import AddExceptionModal from './AddExceptionModal';

interface Court {
  id: string;
  name: string;
  value: string;
}

interface PageProps {
  courtSelected: Court;
}
interface RowsTable {
  rows: [Court[]];
}
interface selectProps {
  value: string;
  label: string;
}
const Reservation: React.FC<PageProps> = ({ courtSelected }) => {
  const { place } = usePlace();

  const [loading, setLoading] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [exceptionModalOpen, setExceptionModalOpen] = useState(false);
  const [courts, setCourts] = React.useState<selectProps[]>([]);
  const [priceSelected, setPriceSelected] = useState<PriceCourts>(
    {} as PriceCourts
  );
  const [pricesCourt, setPricesCourt] = useState<RowsTable[]>(
    [] as RowsTable[]
  );
  const [pricesCourtTest, setPricesCourtTest] = useState<PriceCourts[]>(
    [] as PriceCourts[]
  );
  const [selectTypeUser, setSelectTypeUser] = useState<string>('');

  const formatDataTable = useCallback((data) => {
    setLoading(false);
    // setPricesCourt(response.data);
    const rowsTable = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24,
    ];
    const array: any = [];
    rowsTable.map((horario) => {
      const PricesAtTheTameTime = data.filter(
        (price: PriceCourts) => price.hour === horario
      );

      const row: any = [{}, {}, {}, {}, {}, {}, {}];
      PricesAtTheTameTime.map((price: PriceCourts) => {
        if (Number(price.week_day) === 1) {
          row[0] = price;
        }
        if (Number(price.week_day) === 2) {
          row[1] = price;
        }
        if (Number(price.week_day) === 3) {
          row[2] = price;
        }
        if (Number(price.week_day) === 4) {
          row[3] = price;
        }
        if (Number(price.week_day) === 5) {
          row[4] = price;
        }
        if (Number(price.week_day) === 6) {
          row[5] = price;
        }
        if (Number(price.week_day) === 7) {
          row[6] = price;
        }
      });
      row.map((price: any, indice: number) => {
        if (price.week_day === undefined) {
          row[indice] = {
            hour: horario,
            week_day: indice + 1,
          };
        }
      });

      array.push(row);
    });

    setPricesCourt(array);
  }, []);

  const handleSearchCourtPrice = useCallback(
    (item: Court) => {
      setLoading(true);
      api
        .get(`/appointments/findPricesByCourt?id_court=${item.id}`)
        .then((response) => {
          setLoading(false);
          formatDataTable(response.data);
        });
    },
    [formatDataTable]
  );

  useEffect(() => {
    if (courtSelected?.id) {
      handleSearchCourtPrice(courtSelected);
    }
  }, [handleSearchCourtPrice, courtSelected]);

  useEffect(() => {
    api
      .get(`/courts/findAll?id_place=f13f0061-01f0-476f-9d6c-fe4a1a1f64ca`)
      .then((response) => {
        const courtsFiltered = response.data.filter(
          (court: Court) => court.id !== courtSelected.id
        );
        const atempArray: any = [];
        courtsFiltered.map((court: any) => {
          atempArray.push({
            value: court.id,
            label: court.name,
          });
        });
        setCourts(atempArray);
      });
  }, [setCourts, courtSelected]);

  const handleOpenModal = useCallback((item) => {
    if (item.price!!) {
      setPriceSelected(item);
      setEditModalOpen(true);
    } else {
      setPriceSelected(item);
      setAddModalOpen(true);
    }
  }, []);

  const handleCloseModal = useCallback(() => {
    setAddModalOpen(false);
    setEditModalOpen(false);
    api
      .get(`/appointments/findPricesByCourt?id_court=${courtSelected.id}`)
      .then((response) => {
        formatDataTable(response.data);
      });
  }, [courtSelected, formatDataTable]);

  const handleCopyCourt = useCallback(
    (idCourtPaste: string) => {
      const data = {
        id_court_from: courtSelected.id,
        id_court_to: idCourtPaste,
      };
      api.put(`/appointments/copyPrice`, data).then(() => {
        toast.success('Cópia feita com sucesso!');
      });
    },
    [courtSelected]
  );

  const handleOpenExceptionsModal = useCallback(() => {
    setEditModalOpen(false);
    setExceptionModalOpen(true);
  }, []);

  const handleCloseExceptionModal = useCallback(() => {
    setExceptionModalOpen(false);
    setTimeout(() => {
      setEditModalOpen(true);
    }, 500);
  }, []);

  return (
    <Container>
      <FullLoader show={loading} />
      {pricesCourt.length > 0 ? (
        <>
          <div className="section">
            <Select
              className="select"
              options={courts}
              closeMenuOnSelect
              onChange={(e) => handleCopyCourt(e?.value || '')}
              name="courts"
              isClearable={false}
              placeholder="Copiar preços"
            />
          </div>
          <table className="colapseTable">
            <tr>
              <th className="titleTable c" />
              <th className="titleTable">Segunda</th>
              <th className="titleTable">Terça</th>
              <th className="titleTable">Quarta</th>
              <th className="titleTable">Quinta</th>
              <th className="titleTable">Sexta</th>
              <th className="titleTable">Sabado</th>
              <th className="titleTable">Domingo</th>
            </tr>
            {pricesCourt.map((item: any, i: number) => {
              return (
                <tr key={i}>
                  <th className="cellTable">
                    {i < 9 ? `0${i + 1}:00` : `${i + 1}:00`}
                  </th>
                  {[0, 1, 2, 3, 4, 5, 6].map((j) => (
                    <td
                      className="cellTable"
                      style={{
                        backgroundColor: item[j].allow_discount
                          ? place.secondaryColor
                          : 'transparent',
                        color: item[j].allow_discount
                          ? colors.white
                          : colors.black,
                      }}
                      onClick={() => handleOpenModal(item[j])}
                    >
                      {item[j].price ? (
                        `R$ ${String(item[j].price).replace('.', ',')}`
                      ) : (
                        <button
                          className="addPriceButton"
                          type="button"
                          title="Adicionar preço"
                        >
                          <FaEdit size={16} />
                        </button>
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </table>
        </>
      ) : (
        <h1>Selecione uma quadra para ver os preços</h1>
      )}

      <Modal isOpen={addModalOpen} style={modalStyle}>
        <AddPriceModal
          closeModal={() => handleCloseModal()}
          idCourt={courtSelected.id}
          priceSelected={priceSelected}
        />
      </Modal>
      <Modal isOpen={editModalOpen} style={modalStyle}>
        <EditPriceModal
          priceSelected={priceSelected}
          closeModal={() => handleCloseModal()}
          openExceptionModal={() => handleOpenExceptionsModal()}
        />
      </Modal>
      <Modal isOpen={exceptionModalOpen} style={modalStyle}>
        <AddExceptionModal
          closeModal={() => handleCloseExceptionModal()}
          idCourt={courtSelected.id}
          priceSelected={priceSelected}
        />
      </Modal>
    </Container>
  );
};

export default Reservation;

import React, { useEffect, useCallback, useState } from 'react';

import { MdSave } from 'react-icons/md';
import { toast } from 'react-toastify';
import Dropdown, { Option as ComboOptions } from 'react-dropdown';
import 'react-dropdown/style.css';

import { Sports } from 'interfaces/sports';
import { Category } from 'interfaces/categories';
import api from 'services/api';

import FullLoader from 'components/FullLoader';
import {
  Container,
  OptionsContainer,
  Option,
  ComboboxContainer,
} from './styles';

interface PageProps {
  id_user: string;
}

const Categories: React.FC<PageProps> = ({ id_user }) => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedSport, setSelectedSport] = useState<Sports>({} as Sports);
  const [userCategory, setUserCategory] = useState<ComboOptions>({
    value: '0',
    label: 'Selecione',
  } as ComboOptions);
  const [sports, setSports] = useState<Sports[]>([]);
  const [options, setOptions] = useState<ComboOptions[]>([]);
  const [selectedOption, setSelectedOption] = useState<ComboOptions>({
    value: '',
    label: '',
  } as ComboOptions);

  useEffect(() => {
    api
      .get(`sports/findAll?id_type=78bc852d-f910-43f0-b380-5377897fcfdf`)
      .then((response) => {
        setSports(response.data);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const tempArray: ComboOptions[] = [];

    categories.map((item) => {
      const newItem: ComboOptions = {
        value: item.id,
        label: item.identifier,
      };
      tempArray.push(newItem);
      return null;
    });
    setOptions(tempArray);
  }, [categories]);

  const handleSportChange = useCallback(
    (sport: Sports) => {
      setLoading(true);
      setSelectedSport(sport);
      api
        .get(`/sports/findAllCategories?id_sport=${sport.id}`)
        .then((response) => {
          setCategories(response.data);
          api
            .get(
              `/users/findUserCategoryBySport?id_user=${id_user}&id_sport=${sport.id}`
            )
            .then((response2) => {
              const { id_category } = response2.data;
              if (id_category) {
                response.data.map((item: Category) => {
                  if (item.id === id_category) {
                    setUserCategory({ value: item.id, label: item.identifier });
                  }
                  return null;
                });
              } else {
                setUserCategory({ value: '0', label: 'Selecione' });
              }
              setLoading(false);
            });
        });
    },
    [id_user]
  );

  const handleUpdateUserCategory = useCallback(() => {
    setLoading(true);
    const data = {
      id_user,
      id_sport: selectedSport.id,
      id_category: selectedOption.value,
    };
    api
      .put(`/users/updateUserCategory`, data)
      .then((response) => {
        setLoading(false);
        toast.success('Categoria atualizada com sucesso!');
      })
      .catch(() => setLoading(false));
  }, [id_user, selectedSport, selectedOption]);

  return (
    <Container>
      <OptionsContainer>
        <h1>Esportes</h1>
        {sports.map((sport) => (
          <Option
            key={sport.id}
            selected={selectedSport === sport}
            onClick={() => handleSportChange(sport)}
          >
            <button type="button" />
            <p>{sport.name}</p>
          </Option>
        ))}
      </OptionsContainer>
      {options.length > 0 ? (
        <ComboboxContainer disabled={selectedOption.value === ''}>
          {/* @ts-ignore */}
          <Dropdown
            options={options}
            placeholder="Select an option"
            value={userCategory}
            onChange={(item) => setSelectedOption(item)}
          />
          <button type="button" onClick={handleUpdateUserCategory}>
            <MdSave color="#fff" size={16} />
            Salvar
          </button>
        </ComboboxContainer>
      ) : (
        <></>
      )}
      <FullLoader show={loading} />
    </Container>
  );
};

export default Categories;

function getModalWindowWidth(): number {
  const { innerWidth: width, innerHeight: height } = window;
  if (width > 800) {
    return (width * 40) / 100;
  }
  return (width * 90) / 100;
}

export const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    marginLeft: '20vh',
    width: getModalWindowWidth(),
    right: 'auto',
    bottom: 'auto',
    height: '60%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
  },
};
export const modalStyleLarge = {
  content: {
    top: '50%',
    left: '50%',
    marginLeft: '20vh',
    width: '575px',
    right: 'auto',
    bottom: 'auto',
    height: '60%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
  },
};

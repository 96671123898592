import styled from 'styled-components';
import { shade } from 'polished';

interface OptionProps {
  selected: boolean;
}

interface ContinueButtonProps {
  enabled: boolean;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: row;
`;

export const OptionsContainer = styled.div`
  height: 10%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Option = styled.div<OptionProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    height: 30px;
    width: 30px;
    border: 1px solid #999;
    border-radius: 50px;
    background: ${(props) => (props.selected ? '#ccc' : 'transparent')};
  }
  p {
    margin-left: 10px;
  }
`;
export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 80vh;
`;

export const MonthlyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 50%;
  height: 30vh;

  .dateContent {
    width: 40%;
    span {
      color: #999;
      font-size: 12px;
      width: 100%;
    }
  }

  input {
    border: 1px solid #ccc;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: arial;
    font-size: 17px;
    width: 100%;
    color: #999;
  }
`;

export const PackagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30vh;

  span {
    color: #999;
    margin-right: 5%;
  }
  p {
    width: 10px;
    color: #999;
  }
  button {
    height: 30px;
    width: 30px;
    margin: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    border-radius: 50px;
    background: #cb5223;

    &:hover {
      background: ${shade(0.2, '#cb5223')};
    }
  }
`;

export const PaymentTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  margin-top: 5%;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    span {
      color: #999;
      margin-top: 2px;
    }
  }
`;

export const PaymentTypeButton = styled.button<OptionProps>`
  height: 40px;
  width: 40px;
  background: ${(props) => (props.selected ? '#999' : 'transparent')};
  border: 1px solid #999;
  border-radius: 50px;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
`;

export const ContinueButton = styled.button<ContinueButtonProps>`
  background: ${(props) => (props.enabled ? '#cb5223' : shade(0.5, '#cb5223'))};
  border: 0;
  height: 40px;
  width: 550px;
  span {
    color: ${(props) => (props.enabled ? '#fff' : shade(0.5, '#fff'))};
  }
`;

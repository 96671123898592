import styled, { css } from 'styled-components';

interface PaginationProps {
  isBackButtonDisabled?: boolean;
  isFowardButtonDisabled?: boolean;
}

export const Container = styled.div<PaginationProps>`
  width: 100%;
  max-width: 220px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: flex-start;
    margin-left: 12px;
  }

  p {
    font-size: 14px;
    color: #7a8799;
  }

  .pagination-buttons {
    display: flex;
    flex-direction: row;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: none;
      color: #fff;
      background: transparent;
      transition: 0.3s all;

      svg {
        color: #9fa2b4;
        transition: 0.3s all;
      }

      &:hover {
        svg {
          color: #006edb;
        }
      }

      &:first-child {
        margin-left: 12px;
        ${(props) =>
          props.isBackButtonDisabled &&
          css`
            pointer-events: none;
            svg {
              color: #ddd;
            }
          `}
      }

      &:last-child {
        ${(props) =>
          props.isFowardButtonDisabled &&
          css`
            pointer-events: none;
            svg {
              color: #ddd;
            }
          `}
      }
    }
  }
`;

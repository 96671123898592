import styled from 'styled-components';

export const Container = styled.div``;

export const AppointmentDetail = styled.div`
  button {
    background: #fff;
    color: #000;
    border: 0px;
    height: 20px;
    font-size: 12px;
    width: 20%;
    margin-top: 20px;
  }
`;

export const EventTitle = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-left: 10px;
  }
`;

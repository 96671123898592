/* eslint-disable react/jsx-wrap-multilines */
import React, { useCallback, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { Checkbox } from '@mui/material';
import { FormControlLabel } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import CurrencyInput from 'components/CurrencyInput';
import Button from 'components/Button';
import ModalHeader from 'components/ModalHeader';
import api from 'services/api';

import { toast } from 'react-toastify';
import { PriceCourts } from 'interfaces/priceCourts';
import { Container, Content } from './styles';

interface PageProps {
  closeModal: () => void;
  idCourt: string;
  priceSelected: PriceCourts;
}

interface LastStepProps {
  price: string;
  discount_value: string;
}

const schema = yup.object().shape({
  price: yup.string().required(),
  discount_value: yup.string(),
});

const AddPriceModal: React.FC<PageProps> = ({
  closeModal,
  idCourt,
  priceSelected,
}) => {
  const [loading, setLoading] = useState(false);
  const [allowDiscount, setAllowDiscount] = useState(false);
  const [isNoble, setIsNoble] = useState(false);

  const { register, handleSubmit, control, errors } = useForm<LastStepProps>({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    ({ price, discount_value }: LastStepProps) => {
      setLoading(true);
      const priceFormat = price.replace('R$', '');
      let discountPriceFormat = '0';
      if (discount_value) {
        discountPriceFormat = discount_value.replace('R$', '');
      }

      const data = {
        id_court: idCourt,
        id_sport: '4aa105a3-cef3-461b-840b-e3e31a8175f8',
        hour: Number(priceSelected.hour),
        price: Number(priceFormat.replace(',', '.')),
        week_day: priceSelected.week_day,
        allow_discount: allowDiscount,
        discount_value: Number(discountPriceFormat.replace(',', '.')),
        is_noble: isNoble,
      };

      api.post('/appointments/createPrice', data).then(() => {
        toast.success('Preço criado com sucesso!');
        setLoading(false);
        closeModal();
      });
    },
    [closeModal, idCourt, priceSelected, allowDiscount, isNoble]
  );

  return (
    <Container>
      <ModalHeader title="Novo preço" closeModal={closeModal} />
      <Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <CurrencyInput
              control={control}
              label="Preço"
              name="price"
              prefix="R$"
              register={register}
              error={!!errors.price?.message}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="isNoble"
                  defaultChecked={priceSelected.isNoble}
                  value={isNoble}
                  onChange={() => setIsNoble(!isNoble)}
                />
              }
              label="É horario nobre?"
              className="checkDiscount"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="allowDiscount"
                  value={allowDiscount}
                  onChange={() => setAllowDiscount(!allowDiscount)}
                />
              }
              label="Permitir desconto"
              className="checkDiscount"
            />
            <CurrencyInput
              control={control}
              label="Valor do desconto"
              name="discount_value"
              prefix="R$"
              hidden={!allowDiscount}
              register={register}
              error={!!errors.discount_value?.message}
            />
          </div>
          <Button type="submit">{loading ? 'Aguarde...' : 'Salvar'}</Button>
        </form>
      </Content>
    </Container>
  );
};

export default AddPriceModal;

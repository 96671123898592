import React from 'react';

interface MenuContextData {
  showMenu: boolean;
  toggleMenu: () => void;
  pageTitle: string;
  setPageTitle: (value: string) => void;
}

export const MenuContext = React.createContext<MenuContextData>(
  {} as MenuContextData
);

export const MenuProvider: React.FC = ({ children }) => {
  const [showMenu, setShowMenu] = React.useState(false);
  const [pageTitle, setPageTitle] = React.useState('');

  const toggleMenu = React.useCallback(() => {
    if (window.innerWidth <= 1024) {
      setShowMenu((prev) => !prev);
    } else {
      setShowMenu(true);
    }
  }, []);

  return (
    <MenuContext.Provider
      value={{
        showMenu,
        toggleMenu,
        pageTitle,
        setPageTitle,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export function useMenu(): MenuContextData {
  const context = React.useContext(MenuContext);

  if (!context) {
    throw new Error('useMenu must be used within a MenuProvider');
  }

  return context;
}

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { MdPersonAdd, MdEdit, MdDeleteForever } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import { Table } from '@pdasolucoes/web';
import FullLoader from 'components/FullLoader';
import Pagination from 'components/Pagination';
import { modalStyleLarge } from 'components/ModalStyle';

import { User } from 'interfaces';
import { useMenu, usePlace } from 'context';

import Input from 'components/Input';
import api from 'services/api';
import ConfirmDialog from 'components/ConfirmDialog';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { FaEye } from 'react-icons/fa';
import Modal from 'react-modal';
import { Checkbox } from '@mui/material';
import {
  Container,
  TableContent,
  UserAvatar,
  SectionAvatar,
  SectionButtonEditting,
  Pontuacao,
} from './styled';
import PointsModal from './PointsModal';

interface PropsSelect {
  value: string;
  label: string;
}

const Users: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(true);
  const [itemsPerPage, setItemsPerPage] = React.useState(10);
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const [modalEditOpen, setModalEditOpen] = React.useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [idUser, setIdUser] = React.useState('');
  const [showAdmin, setShowAdmin] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [usersFilter, setUsersFilter] = useState<User[]>([]);
  const [count, setCount] = useState(0);
  const { user_types } = usePlace();
  const history = useHistory();

  const { setPageTitle } = useMenu();

  const handleOpenConfirm = useCallback((row: User) => {
    setIdUser(row.id);
    setIsOpenDialog(true);
  }, []);

  const handleCloseConfirm = useCallback(() => {
    setIsOpenDialog(false);
  }, []);

  const handleLoadUsers = useCallback(() => {
    api
      .get(
        // eslint-disable-next-line prettier/prettier
        `/users/findAll?limit=${itemsPerPage}&offset=${(page - 1) * itemsPerPage
        }`
      )
      .then((response) => {
        setUsers(response.data.users);
        setUsersFilter(response.data.users);
        setCount(response.data.count);
        setLoading(false);
      });
  }, [itemsPerPage, page]);
  const handleConfirm = useCallback(async () => {
    try {
      setLoading(true);
      setIsOpenDialog(false);
      await api.delete(`/users/deleteUser?id_user=${idUser}`).then(() => {
        toast.success('Usuario excluido Com sucesso !');
        setLoading(false);
        handleLoadUsers();
      });
    } catch (error) {
      console.log(error);
    }
  }, [idUser, handleLoadUsers]);

  useEffect(() => {
    handleLoadUsers();
  }, [handleLoadUsers]);

  const handleFilterUsers = useCallback(
    (name) => {
      setLoading(true);
      setTimeout(() => {
        api
          .get(
            // eslint-disable-next-line prettier/prettier
            `/users/findAll?limit=${itemsPerPage}&offset=${(page - 1) * itemsPerPage
            }&name=${name}`
          )
          .then((response) => {
            setUsers(response.data.users);
            setLoading(false);
          });
      }, 500);
    },
    [itemsPerPage, page]
  );

  const handleFilterUsersType = useCallback(() => {
    setLoading(true);
    setShowAdmin(!showAdmin);
    if (!showAdmin) {
      const typeAdmin = user_types.find(
        (type) => type.name === 'Administrator'
      );
      const usersFiltered = usersFilter.filter(
        (user) => user.user_type === typeAdmin?.id
      );
      setUsers(usersFiltered);
    } else {
      setUsers(usersFilter);
    }

    setLoading(false);
  }, [user_types, usersFilter, showAdmin]);

  React.useEffect(() => {
    setPageTitle('Usuários');
  }, [setPageTitle]);

  return (
    <Container>
      <FullLoader show={loading} />
      <div className="section">
        <button
          onClick={() => history.push('/adduser')}
          className="addClientButton"
          type="button"
        >
          <MdPersonAdd size={20} color="#fff" />
          <span>Adicionar cliente</span>
        </button>
        <Input
          placeholder="Filtrar por nome"
          onChange={(e) => handleFilterUsers(e.target.value)}
        />
        <aside className="filterPaid">
          <Checkbox
            checked={showAdmin}
            onClick={() => handleFilterUsersType()}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
          />
          <span>Mostrar apenas administradores</span>
        </aside>
        <br />
      </div>
      {users.length !== 0 ? (
        <>
          <div className="table-container">
            <TableContent>
              <Table<User>
                defaultNumberOfRows={10}
                columns={[
                  {
                    title: '',
                    type: 'string',
                    orderable: true,
                    props: ['name'],
                    cssTitle: {
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                    },
                    cssProps: {
                      width: '2%',
                      paddingRight: '8px',
                      fontFamily: 'sans-serif',
                    },
                    cssText: {
                      paddingLeft: '8px',
                    },
                    renderItem: (row) => {
                      return (
                        <SectionAvatar>
                          <UserAvatar src="https://app-arenaibirapuera.s3.amazonaws.com/avatar.jpg" />
                        </SectionAvatar>
                      );
                    },
                  },
                  {
                    title: 'Nome',
                    type: 'string',
                    orderable: true,
                    props: ['name'],
                    cssTitle: {
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                    },
                    cssProps: {
                      width: '2%',
                      paddingRight: '8px',
                      fontFamily: 'sans-serif',
                    },
                    cssText: {
                      paddingLeft: '8px',
                    },
                  },
                  {
                    title: 'CPF ',
                    type: 'string',
                    props: ['ssn'],
                    cssTitle: {
                      justifyContent: 'flex-start',
                      textAlign: 'center',
                    },
                    cssProps: {
                      width: '2%',
                      paddingRight: '8px',
                      paddingLeft: '16px',
                      fontFamily: 'sans-serif',
                    },
                    cssText: {
                      paddingLeft: '8px',
                    },
                  },
                  {
                    delimiter: '3',
                    title: 'Editar',
                    type: 'string',
                    // orderable: true,
                    props: ['id'],
                    cssTitle: {
                      justifyContent: 'flex-start',
                      textAlign: 'center',
                    },
                    cssProps: {
                      width: '2%',
                      paddingRight: '8px',
                      paddingLeft: '16px',
                      fontFamily: 'sans-serif',
                    },
                    cssText: {
                      paddingLeft: '8px',
                    },
                    renderItem: (row) => {
                      return (
                        <SectionButtonEditting>
                          <button
                            type="button"
                            onClick={() =>
                              history.push(`/edituser?id_user=${row.id}`)
                            }
                          >
                            <MdEdit color="#286738" size={20} />
                          </button>
                        </SectionButtonEditting>
                      );
                    },
                  },
                  {
                    delimiter: '3',
                    title: 'Excluir',
                    type: 'string',
                    // orderable: true,
                    props: ['id'],
                    cssTitle: {
                      justifyContent: 'flex-start',
                      textAlign: 'center',
                    },
                    cssProps: {
                      width: '2%',
                      paddingRight: '8px',
                      paddingLeft: '16px',
                      fontFamily: 'sans-serif',
                    },
                    cssText: {
                      paddingLeft: '8px',
                    },
                    renderItem: (row) => {
                      return (
                        <SectionButtonEditting>
                          <button
                            type="button"
                            onClick={() => handleOpenConfirm(row)}
                          >
                            <MdDeleteForever color="#d32727" size={20} />
                          </button>
                        </SectionButtonEditting>
                      );
                    },
                  },
                ]}
                rows={[
                  ...users.map((item) => ({
                    ...item,
                  })),
                ]}
              />
            </TableContent>
          </div>
          <Pontuacao>
            <button
              onClick={() => setModalEditOpen(true)}
              className="addClientButton"
              type="button"
            >
              <FaEye size={20} color="#fff" />
              <span>Ver pontuação geral</span>
            </button>
          </Pontuacao>
        </>
      ) : (
        <h3 className="notFoundUsers">Nenhum usuário encontrado .</h3>
      )}
      <ConfirmDialog
        title="Deseja realmente excluir esse cliente?"
        isOpen={isOpenDialog}
        onClose={handleCloseConfirm}
        handleConfirm={handleConfirm}
      />
      <Modal isOpen={modalEditOpen} style={modalStyleLarge}>
        <PointsModal closeModal={() => setModalEditOpen(false)} users={users} />
      </Modal>
    </Container>
  );
};

export default Users;

/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useCallback, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { MdAdd, MdEdit, MdDeleteForever } from 'react-icons/md';

import FullLoader from 'components/FullLoader';
import Pagination from 'components/Pagination';
import { modalStyle } from 'components/ModalStyle';

import { useUsers } from 'hooks/users';
import api from 'services/api';

import { useMenu } from 'context';
import { Table } from '@pdasolucoes/web';
import { toast } from 'react-toastify';
import ConfirmDialog from 'components/ConfirmDialog';
import AddSportModal from './AddSportModal';
import { Container, TableContent } from './styles';
import EditSportModal from './EditSportModal';

interface SportProps {
  id: string;
  id_type: string;
  name: string;
  photo: any;
  photo_url: string;
  created_at: Date;
  updated_at: Date;
}

const Sports: React.FC = () => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [idSport, setIdSport] = useState('');
  const [sports, setSports] = useState<SportProps[]>([]);
  const [sport, setSport] = useState<SportProps>({} as SportProps);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const dispatch = useDispatch();
  const { setPageTitle } = useMenu();
  const { count = 0, users = [] } = useUsers();

  const loadSports = useCallback(() => {
    api.get(`/sports/findAll`).then((response) => {
      setSports(response.data);
      setLoading(false);
    });
  }, []);

  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setItemsPerPage(Number(e.target.value));
    setPage(1);
  };

  const handleNextPage = React.useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  const handlePreviousPage = React.useCallback(() => {
    setPage((prev) => prev - 1);
  }, []);

  useEffect(() => {
    setPageTitle('Esportes');
    api.get(`/sports/findAll`).then((response) => {
      setSports(response.data);
      setLoading(false);
    });
  }, [page, itemsPerPage, dispatch, setPageTitle]);

  const handleOpenEditModal = useCallback((sportTarget: SportProps) => {
    setSport(sportTarget);
    setEditModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    api.get(`/sports/findAll`).then((response) => {
      setSports(response.data);
      setAddModalOpen(false);
      setEditModalOpen(false);
    });
  }, []);

  const handleOpenConfirm = useCallback((row: SportProps) => {
    setIdSport(row.id);
    setIsOpenDialog(true);
  }, []);
  const handleCloseConfirm = useCallback(() => {
    setIsOpenDialog(false);
  }, []);
  const handleConfirm = useCallback(async () => {
    try {
      setIsOpenDialog(false);
      setLoading(true);
      api.delete(`/sports/deleteSport?id_sport=${idSport}`).then(() => {
        toast.success('Esporte apagado com sucesso !');
        setLoading(false);
        loadSports();
      });
    } catch (error) {
      // console.log(error);
    }
  }, [idSport, loadSports]);
  return (
    <Container>
      <FullLoader show={loading} />
      <button
        onClick={() => setAddModalOpen(true)}
        className="addPackageButton"
        type="button"
      >
        <MdAdd size={20} color="#fff" />
        <span>Adicionar Esporte</span>
      </button>
      <TableContent>
        <Table<SportProps>
          defaultNumberOfRows={10}
          columns={[
            {
              title: 'Nome',
              type: 'string',
              orderable: true,
              props: ['name'],
              cssTitle: {
                justifyContent: 'flex-start',
                textAlign: 'left',
              },
              cssProps: {
                width: '70%',
                paddingRight: '8px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
            },
            {
              // cssRow:{
              //   width: 'auto';
              // },
              delimiter: '3',
              title: 'Editar',
              type: 'string',
              // orderable: true,
              props: ['id'],
              cssTitle: {
                justifyContent: 'flex-start',
                textAlign: 'center',
              },
              cssProps: {
                width: '2%',
                paddingRight: '8px',
                paddingLeft: '16px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
              renderItem: (row) => {
                return (
                  <button
                    type="button"
                    onClick={() => handleOpenEditModal(row)}
                  >
                    <MdEdit color="#286738" size={20} />
                  </button>
                );
              },
            },
            {
              // cssRow:{
              //   width: 'auto';
              // },
              delimiter: '3',
              title: 'Excluir',
              type: 'string',
              // orderable: true,
              props: ['id'],
              cssTitle: {
                justifyContent: 'center',
                textAlign: 'center',
              },
              cssProps: {
                width: '2%',
                paddingRight: '8px',
                paddingLeft: '16px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
              renderItem: (row) => {
                return (
                  <button type="button" onClick={() => handleOpenConfirm(row)}>
                    <MdDeleteForever color="#d32727" size={20} />
                  </button>
                );
              },
            },
          ]}
          rows={sports}
        />
      </TableContent>
      <Modal isOpen={addModalOpen} style={modalStyle}>
        <AddSportModal closeModal={() => handleCloseModal()} />
      </Modal>
      <Modal isOpen={editModalOpen} style={modalStyle}>
        <EditSportModal closeModal={() => handleCloseModal()} sport={sport} />
      </Modal>
      <ConfirmDialog
        title="Deseja realmente excluir este Esporte?"
        isOpen={isOpenDialog}
        onClose={handleCloseConfirm}
        handleConfirm={handleConfirm}
      />
    </Container>
  );
};

export default Sports;

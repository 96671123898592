/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import MaskedInput from 'react-text-mask';
import { Controller } from 'react-hook-form';

import { Container } from './styled';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  register?: any;
  error?: boolean;
  label?: string;
  title?: string;
  mask?: any;
  name?: any;
  defaultValue?: any;
  control?: any;
  textHint?: string;
  currency?: boolean;
}

const Input: React.FC<InputProps> = ({
  register,
  error,
  label,
  title,
  mask,
  name,
  textHint,
  defaultValue,
  control,
  currency,
  ...rest
}) => {
  return (
    <Container error={error}>
      {title && <label>{title}</label>}
      {mask && control ? (
        <Controller
          render={({ onChange, value }) => (
            // @ts-ignore
            <MaskedInput
              mask={mask as any}
              placeholderChar=" "
              guide={false}
              onChange={onChange}
              value={value}
              {...rest}
            />
          )}
          name={name}
          control={control}
          defaultValue={defaultValue || ''}
        />
      ) : (
        <input
          name={name}
          defaultValue={defaultValue}
          placeholder={label}
          ref={register}
          {...rest}
        />
      )}
      {textHint && <span className="text-hint">{textHint}</span>}
    </Container>
  );
};

export default Input;

import styled, { css } from 'styled-components';
import { shade } from 'polished';
import white_label from 'white_label';

const { secondary_color } = white_label();
interface PaymentTypeProps {
  selected: boolean;
}
interface ExpiryButtonProps {
  selected: boolean;
}
interface FinishButtonProps {
  disabled: boolean;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  aside {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 40%;
    margin-top: 20px;

    main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 20%;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        border: 0;
      }

      .expiryDiv {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        span {
          color: #999;
        }
      }
    }
    h1 {
      color: #999;
    }
    span {
      color: #000;
    }
  }
`;

export const PaymentType = styled.div<PaymentTypeProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  button {
    height: 30px;
    width: 30px;
    border: 1px solid #999;
    border-radius: 50px;
    background: ${(props) => (props.selected ? '#ccc' : 'transparent')};
  }

  p {
    margin-top: 10px;
    color: #999;
  }
`;

export const FinishButton = styled.button<FinishButtonProps>`
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  height: 48px;
  border: none;
  color: #fff;
  font-size: 18px;
  margin-top: 40px;
  background: ${secondary_color};
  transition: 0.3s all;

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        background: ${shade(0.2, secondary_color)};
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      background: ${shade(0.2, secondary_color)};
      color: #ccc;
    `}
`;

export const ExpiryButton = styled.button<ExpiryButtonProps>`
  height: 20px !important;
  width: 20px !important;
  background: ${(props) =>
    props.selected ? '#999' : 'transparent'} !important;
  border: 1px solid #999 !important;
  border-radius: 50px;
`;

/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useState } from 'react';
import { MdClose } from 'react-icons/md';
import api from 'services/api';
import { PayCreditCard } from 'utils/PaymentsOffline/vindi_credit_card';
import { Container, FinalPriceContainer } from './styles';

interface PageProps {
  closeModal: () => void;
}

interface CardProps {
  cardName: string;
  cardNumber: string;
  cardCvc: string;
  cardExpiry: string;
}

interface User {
  name: string;
  ssn: string;
  email: string;
  street: string;
  complement: string;
  number: string;
  zipCode: string;
  district: string;
  city: string;
  state: string;
}

const CreditCardPayment: React.FC<PageProps> = ({ closeModal }) => {
  const [cvc, setCvc] = useState('');
  const [expiry, setExpiry] = useState('');
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [price, setPrice] = useState(40);
  const [loading, setLoading] = useState(false);

  const handleExpiryChange = useCallback((text) => {
    let formatted = '';
    if (text.length === 1) {
      formatted = text;
    } else if (text.length === 2) {
      const temp = text.replace('/', '');
      formatted = temp.replace(/(\d{2})/g, '$1/');
    } else if (text.length === 3) {
      formatted = text.replace('/', '');
    } else {
      formatted = text;
    }
    setExpiry(formatted);
  }, []);

  const handleSubmit = useCallback(async () => {
    // const user = api.get()
    // const {} = await PayCreditCard()
    // 165661
  }, []);

  return (
    <Container>
      <div className="header">
        <h1>Informe os dados abaixo</h1>
        <button type="button" onClick={closeModal}>
          <MdClose color="#fff" size={20} />
        </button>
      </div>
      {/* <Cards
        cvc={cvc}
        expiry={expiry}
        focused="cvc"
        name={name}
        number={number}
      /> */}
      <form onSubmit={(e) => handleSubmit()}>
        <div className="formFields">
          <input
            type="tel"
            name="number"
            className="form-control"
            placeholder="Número do cartão"
            pattern="[\d| ]{16,22}"
            required
            onChange={(e) => setNumber(e.target.value)}
          />

          <input
            type="text"
            name="name"
            className="form-control"
            placeholder="Nome (como está no cartão)"
            required
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="tel"
            name="expiry"
            className="form-control"
            placeholder="Validade"
            pattern="\d\d/\d\d"
            required
            value={expiry}
            onChange={(e) => handleExpiryChange(e.target.value)}
          />
          <input
            type="number"
            name="cvc"
            className="form-control"
            placeholder="CVC"
            pattern="\d{3,4}"
            required
            onChange={(e) => setCvc(e.target.value)}
          />

          <button type="submit" className="submitButton" disabled={loading}>
            <>{loading ? <p>Carregando...</p> : <p>Finalizar compra</p>}</>
          </button>
        </div>
      </form>
    </Container>
  );
};

export default CreditCardPayment;

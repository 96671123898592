import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  .modal-content {
    padding: 22px;

    h1 {
      color: #2f2f2f;
      font-size: 30px;
      margin-bottom: 16px;
    }

    p {
      color: #585858;
      font-size: 14px;
    }
  }

  .modal-actions {
    width: 100%;
    height: 66px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #f5f7fa;

    > div {
      max-width: 105px;

      & + div {
        margin: 0 22px;
      }

      button {
        height: 36px;
        font-size: 12px;
        transition: 0.3s all;

        & + button {
          margin-left: 12px;
        }
      }

      &:first-child {
        button {
          color: #7a8799;
          border: 1px solid #e4e9f0;
          background-color: #fff;

          &:hover {
            background-color: ${shade(0.1, '#fff')};
          }
        }
      }

      &:last-child {
        button {
          color: #fff;
          background-color: #006edb;

          &:hover {
            background-color: ${shade(0.3, '#006edb')};
          }
        }
      }
    }
  }
`;

import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Typography,
} from '@material-ui/core';
import { FaTimes } from 'react-icons/fa';

interface ConfirmProps {
  isOpen: boolean;
  onClose: () => void;
  handleConfirm: () => void;
  title?: string;
}

const ConfirmDialog = ({
  isOpen,
  onClose,
  handleConfirm,
  title,
}: ConfirmProps) => {
  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth>
      <DialogTitle>
        {title || 'Deseja realmente excluir esse Day Use?'}
      </DialogTitle>
      {/* @ts-ignore */}
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={onClose}>
          <FaTimes color="#d32727" size={20} />
        </IconButton>
      </Box>
      <DialogContent>
        {/* <Typography>some message here</Typography> */}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="contained" onClick={onClose}>
          Não
        </Button>
        <Button color="primary" variant="contained" onClick={handleConfirm}>
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;

import styled from 'styled-components';

export const Container = styled.div`
  height: 90%;
`;

export const Content = styled.div`
  height: 100%;
  margin-top: 20px;
  form {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    input {
      margin-bottom: 7px;
    }
    .select {
      width: inherit;
    }
    .marginButton {
      margin-bottom: 30px;
    }
    .sectionDate {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      column-gap: 5px;
    }
    .inputDateTime {
      width: inherit;
      height: 52px;
      max-height: 52px;
      border: 1px solid #d2d2d4;
      padding: 22px;
    }
    button {
      margin-bottom: 20px;
    }
  }
`;

export const InputSearch = styled.div`
  width: 100%;
  height: 52px;
  border-radius: 8px;
  border: 1px solid #e6e6f0;
  background-color: #fafafc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;

  input {
    width: 90%;
    padding: 12px;
    font-size: 15px;
    border: none;
    background: none;
    display: flex;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    width: 50px;
    height: inherit;
    background: none;
    border: none;
  }
`;

export const SearchResults = styled.div`
  width: 100%;
  height: 280px;
  border-radius: 8px;
  border: 1px solid #e6e6f0;
  /* background-color: #fafafc; */
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
  margin-bottom: 16px;
  overflow: auto;
  ul {
    width: 100%;
    height: inherit;
    list-style: none;
    padding-left: 1rem;
    li {
      &:hover {
        color: #fff;
        background: #006edb;
      }

      button {
        background: none;
        border: none;
        padding: 0px 16px;
      }
    }
  }
`;

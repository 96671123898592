/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useCallback, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { MdAdd, MdEdit, MdDeleteForever } from 'react-icons/md';

import Table from 'components/Table';
import FullLoader from 'components/FullLoader';
import Pagination from 'components/Pagination';
import { modalStyle } from 'components/ModalStyle';

import { UserClasses } from 'interfaces/usersClasses';
import { useUsers } from 'hooks/users';
import getDayOfWeekDescription from 'utils/getDayOfWeekDescription';
import api from 'services/api';

import { toast } from 'react-toastify';
import AddUserToClassModal from './AddUserToClassModal';
// import EditPackageModal from './EditPackageModal';
import { Container } from './styles';

interface PageProps {
  id_user: string;
  user_name: string;
  ssn: string;
}

const Classes: React.FC<PageProps> = ({ id_user, user_name, ssn }) => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [userClasses, setUserClasses] = useState<UserClasses[]>([]);
  const [selectedClass, setSelectedClass] = useState<UserClasses>(
    {} as UserClasses
  );
  const dispatch = useDispatch();

  const { count = 0, users = [] } = useUsers();

  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setItemsPerPage(Number(e.target.value));
    setPage(1);
  };

  const handleNextPage = React.useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  const handlePreviousPage = React.useCallback(() => {
    setPage((prev) => prev - 1);
  }, []);

  useEffect(() => {
    api.get(`/teachers/findUsersClasses?ssn=${ssn}`).then((response) => {
      setUserClasses(response.data);
      setLoading(false);
    });
  }, [page, itemsPerPage, dispatch, ssn]);

  const handleDeletePackage = useCallback(
    (id: string) => {
      const result = window.confirm('Deseja realmente apagar esta aula?');

      if (result) {
        setLoading(true);
        api
          .delete(
            `/teachers/removeUserFromClass?id_user=${id_user}&id_class=${id}`
          )
          .then((response) => {
            api
              .get(`/teachers/findUsersClasses?ssn=${ssn}`)
              .then((response2) => {
                setUserClasses(response2.data);
                setLoading(false);
                toast.success('Aula removida com sucesso!');
              });
          })
          .catch((error) => setLoading(false));
      }
    },
    [id_user, ssn]
  );

  const handleCloseModalAndRefresh = useCallback(() => {
    setAddModalOpen(false);
    setLoading(true);
    setTimeout(() => {
      api.get(`/teachers/findUsersClasses?ssn=${ssn}`).then((response) => {
        setUserClasses(response.data);
        setLoading(false);
      });
    }, 1000);
  }, [ssn]);

  return (
    <Container>
      <FullLoader show={loading} />
      <button
        onClick={() => setAddModalOpen(true)}
        className="addPackageButton"
        type="button"
      >
        <MdAdd size={20} color="#fff" />
        <span>Adicionar aula</span>
      </button>
      <div className="table-container">
        <Table>
          <thead>
            <tr>
              <th align="left">Prof.</th>
              <th align="left">Dia</th>
              <th align="left">Hora</th>
              <th align="left">Valor</th>
              <th align="left">Excluir</th>
            </tr>
          </thead>
          <tbody>
            {userClasses?.map((item, index) => (
              <tr key={index}>
                <td>{item?.teacher_name}</td>
                <td>{getDayOfWeekDescription(Number(item.day_of_week))}</td>
                <td>
                  {Number(item.hour) < 10
                    ? `0${item.hour}:00`
                    : `${item.hour}:00`}
                </td>
                <td>{`R$ ${String(item?.price).replace('.', ',')}`}</td>
                <td>
                  <button
                    type="button"
                    onClick={() => handleDeletePackage(item.id_teacherclass)}
                  >
                    <MdDeleteForever color="#cc0000" size={20} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="pagination-container">
          <div className="pagination-options">
            <p>Itens por página</p>
            <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div className="pagination">
            <Pagination
              offset={(page - 1) * itemsPerPage}
              currentCount={users?.length}
              total={count}
              handlePreviousPage={handlePreviousPage}
              handleNextPage={handleNextPage}
            />
          </div>
        </div>
      </div>
      <Modal isOpen={addModalOpen} style={modalStyle}>
        <AddUserToClassModal
          id_user={id_user}
          user_name={user_name}
          ssn={ssn}
          classes={userClasses}
          closeModal={() => setAddModalOpen(false)}
          closeModalAndRefresh={() => handleCloseModalAndRefresh()}
        />
      </Modal>
      {/* <Modal isOpen={editModalOpen} style={modalStyle}>
        <EditPackageModal
          initialData={selectedPackage}
          closeModal={() => handleCloseModal()}
        />
      </Modal> */}
    </Container>
  );
};

export default Classes;

import styled, { css } from 'styled-components';

interface MenuBarProps {
  showMenu: boolean;
  primary_color: string;
}

export const Container = styled.div`
  display: flex;
  align-items: stretch;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const Main = styled.main<MenuBarProps>`
  display: flex;
  flex: 1;
  transition: 0.1s all;
  justify-content: center;
  margin-top: 70px;
  padding: 22px;
  margin-left: 56px;

  @media (max-width: 1024px) {
    margin-left: 0;
    margin-top: 60px;
  }

  @media (min-width: 1024px) {
    ${(props) =>
      props.showMenu &&
      css`
        margin-left: 235px;
      `}
  }
`;

export const Sidebar = styled.aside<MenuBarProps>`
  transition: 0.1s all;
  background-color: ${(props) => props.primary_color};
  color: #2f2f2f;
  z-index: 1;
  position: fixed;
  height: 100%;
  padding-top: 30px;

  @media (min-width: 1024px) {
    width: 235px;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background-color: #cccccc;
    }
    ::-webkit-scrollbar-button {
      border: 1px #ff0000;
    }
    ::-webkit-scrollbar-track {
      background: #cccccc;
    }
    ::-webkit-scrollbar-track-piece {
      background: #cccccc;
    }
    ::-webkit-scrollbar-thumb {
      background: #333333;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-corner {
      background: #ff0000;
      border-radius: 5px;
    }
    ::-webkit-resizer {
      border: 1px #ff0000;
    }
  }

  svg {
    cursor: pointer;
  }

  .menu-icon-container {
    @media (max-width: 1024px) {
      padding: 14px 22px;

      ${(props) => css`
        display: ${props.showMenu ? 'flex' : 'none'};
      `}
    }

    svg {
      @media (max-width: 1024px) {
        color: #fff;
      }
    }
  }

  .menu-items {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 1024px) {
    ${(props) => css`
      width: ${props.showMenu ? '235px' : '0px'};
      overflow-y: auto;
    ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background-color: #cccccc0;
    }
    ::-webkit-scrollbar-button {
      border: 0px #ff0000;
    }
    ::-webkit-scrollbar-track {
      background: #cccccc;
    }
    ::-webkit-scrollbar-track-piece {
      background: #cccccc;
    }
    ::-webkit-scrollbar-thumb {
      background: #333333;
      border-radius: 0px;
    }
    ::-webkit-scrollbar-corner {
      background: #ff0000;
      border-radius: 0px;
    }
    ::-webkit-resizer {
      border: 0px #ff0000;
    }
  }
      .menu-items {
        display: flex;
        flex-direction: column;
      }

      svg {
        display: ${props.showMenu ? 'flex' : 'none'};
      }
    `}
  }

  .logo-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;

    .logo {
      width: 40%;
    }
  }
`;

export const AppBar = styled.header<MenuBarProps>`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 235px);
  padding: 14px 22px;
  height: 60px;
  background-color: ${(props) => props.primary_color};
  color: #000;
  transition: 0.1s all;
  z-index: 1;
  margin-left: 0;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

  @media (max-width: 1024px) {
    width: 100%;

    h3 {
      display: none;
    }
  }

  h3 {
    font-size: 24px;
    font-weight: bolder;
    color: #2f2f2f;

    @media (max-width: 1024px) {
      margin-left: 22px;
    }
  }

  @media (min-width: 1024px) {
    background: #f7f8fc;
    box-shadow: none;

    margin-left: 235px;

    height: 90px;

    .appbar-bars {
      display: none;
    }
  }

  svg {
    @media (min-width: 1024px) {
      color: #0a0c22;
    }
    cursor: pointer;
  }
`;

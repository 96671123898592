import {
  FaCog,
  FaFlask,
  FaUsers,
  FaChalkboardTeacher,
  FaInfoCircle,
  FaAward,
  FaHome,
  FaDollarSign,
  FaFontAwesomeFlag,
  FaCalendarAlt,
  FaBasketballBall,
  FaFutbol,
  FaRegListAlt,
  FaUserLock,
  FaFileInvoice,
  FaStar,
} from 'react-icons/fa';

import { BsSunrise } from 'react-icons/bs';

import SignIn from 'pages/SignIn';
import Agents from 'pages/Users';
import Agent from 'pages/Users/User';
import Dashboard from 'pages/Dashboard';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import Categories from 'pages/Categories';
import Teachers from 'pages/Teachers';
import AddUser from 'pages/Users/AddUser';
import EditUser from 'pages/Users/EditUser';
import UserPaymentType from 'pages/Users/AddUser/UserPaymentType';
import Prices from 'pages/Prices';
import Requests from 'pages/Requests';
import Courts from 'pages/Courts';
import Calendar from 'pages/Calendar';
import Interests from 'pages/Interests';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import Club from 'pages/Club';
import Sports from 'pages/Sports';
import CancellationRules from 'pages/cancellationRules';
import Reports from 'pages/Reports';
import DayUse from 'pages/DayUse';
import WeeklyCalendar from 'pages/weeklyCalendar';
import { Menu } from 'interfaces';

import ViewItems from 'pages/Interests/ViewItems';
import UsersInDayUse from 'pages/DayUse/UsersInDayUse';
import Fidelity from 'pages/Fidelity';
import {
  HOME,
  SIGNIN,
  USERS,
  USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  ADDUSER,
  EDITUSER,
  USERPAYMENT,
  PRICES,
  REQUESTS,
  CATEGORIES,
  TEACHERS,
  COURTS,
  CALENDAR,
  PRIVACYPOLICY,
  CLUB,
  INTERESTS,
  SPORTS,
  CANCELLATIONRULES,
  VIEWITEMS,
  REPORTS,
  DAYUSE,
  USERSINDAYUSE,
  WEEKLICALENDAR,
  FIDELITY,
} from './constants';

const menus: Menu[] = [
  {
    icon: FaHome,
    path: HOME,
    label: 'Home',
    isPrivate: true,
    component: Dashboard,
    iconSize: 22,
  },
  {
    icon: FaCalendarAlt,
    path: CALENDAR,
    label: 'Calendario',
    isPrivate: true,
    component: Calendar,
    iconSize: 22,
  },
  {
    icon: FaUsers,
    path: USERS,
    label: 'Clientes',
    isPrivate: true,
    component: Agents,
    iconSize: 22,
  },
  // // {
  // //   icon: FaUsers,
  // //   path: CLUB,
  // //   label: 'Clubinho',
  // //   isPrivate: true,
  // //   component: Club,
  // //   iconSize: 22,
  // // },
  // {
  //   icon: BsSunrise,
  //   iconSize: 22,
  //   path: DAYUSE,
  //   label: 'Day Use',
  //   isPrivate: true,
  //   hide: false,
  //   component: DayUse,
  // },
  {
    icon: FaFutbol,
    path: SPORTS,
    label: 'Esportes',
    isPrivate: true,
    component: Sports,
    iconSize: 22,
  },
  // {
  //   icon: FaStar,
  //   iconSize: 22,
  //   path: FIDELITY,
  //   label: 'Fidelidade',
  //   isPrivate: true,
  //   hide: false,
  //   component: Fidelity,
  // },
  // {
  //   icon: FaBasketballBall,
  //   path: INTERESTS,
  //   label: 'Interesses',
  //   isPrivate: true,
  //   component: Interests,
  //   iconSize: 22,
  // },
  {
    icon: FaDollarSign,
    path: PRICES,
    label: 'Preços',
    isPrivate: true,
    component: Prices,
    iconSize: 22,
  },

  {
    icon: FaFontAwesomeFlag,
    path: COURTS,
    label: 'Quadras',
    isPrivate: true,
    component: Courts,
    iconSize: 22,
  },

  // {
  //   icon: FaRegListAlt,
  //   path: CANCELLATIONRULES,
  //   label: 'Regras de cancelamento',
  //   isPrivate: true,
  //   component: CancellationRules,
  //   iconSize: 22,
  // },
  {
    icon: FaFileInvoice,
    path: REPORTS,
    label: 'Relatórios',
    isPrivate: true,
    component: Reports,
    iconSize: 22,
  },
  // {
  //   icon: FaInfoCircle,
  //   path: REQUESTS,
  //   label: 'Solicitações',
  //   isPrivate: true,
  //   component: Requests,
  //   iconSize: 22,
  // },
  // {
  //   icon: FaUserLock,
  //   path: PRIVACYPOLICY,
  //   label: 'Politica de privacidade',
  //   isPrivate: true,
  //   component: PrivacyPolicy,
  //   iconSize: 22,
  // },

  {
    path: USER,
    isPrivate: true,
    hide: true,
    component: Agent,
  },
  {
    path: USERSINDAYUSE,
    isPrivate: true,
    hide: true,
    component: UsersInDayUse,
  },
  {
    path: ADDUSER,
    isPrivate: true,
    hide: true,
    component: AddUser,
  },
  {
    path: EDITUSER,
    isPrivate: true,
    hide: true,
    component: EditUser,
  },
  {
    path: VIEWITEMS,
    isPrivate: true,
    hide: true,
    component: ViewItems,
  },
  {
    path: USERPAYMENT,
    isPrivate: true,
    hide: true,
    component: UserPaymentType,
  },
  {
    path: SIGNIN,
    isPrivate: false,
    hide: true,
    component: SignIn,
  },
  {
    path: FORGOT_PASSWORD,
    isPrivate: false,
    hide: true,
    component: ForgotPassword,
  },
  {
    path: RESET_PASSWORD,
    isPrivate: false,
    hide: true,
    component: ResetPassword,
  },
];

export default menus;

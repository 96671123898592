import React, { useCallback, useState, useEffect } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/Input';
import Button from 'components/Button';
import ModalHeader from 'components/ModalHeader';
import api from 'services/api';
import { Chart } from 'react-google-charts';
import { toast } from 'react-toastify';
import FullLoader from 'components/FullLoader';
import PieChart from 'components/PieChart';
import { Container, Content } from './styles';

interface PageProps {
  closeModal: () => void;
  closeModalAndRefresh: () => void;
  idItem: string;
}

interface LastStepProps {
  name: string;
}

const schema = yup.object().shape({
  name: yup.string().required(),
});

const ResponseReportModal: React.FC<PageProps> = ({
  closeModal,
  closeModalAndRefresh,
  idItem,
}) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({
    title: 'Gráfico de Pizza',
    is3D: true,
    slices: {
      0: { color: '#286738' },
      1: { color: '#cb5223' },
    },
    animation: {
      startup: true,
      easing: 'linear',
      duration: 1500,
    },
  });
  const [responsesYes, setResponsesYes] = useState(0);
  const [responsesNo, setResponsesNo] = useState(0);
  const [renderGrafic, setRenderGrafic] = useState(false);
  const [data, setData] = useState([
    ['sim', 'não'],
    ['Respostas sim', responsesYes],
    ['Respostas não', responsesNo],
  ]);

  const { register, handleSubmit, setValue, control, errors } =
    useForm<LastStepProps>({
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    setLoading(true);

    api
      .get(
        `/interests/findResponsesUserByIdChildrenInterestItem?id_children_Interest_item=${idItem}`
      )
      .then((response) => {
        let currentResNo = 0;
        let currentResyes = 0;
        // eslint-disable-next-line array-callback-return
        response.data.map((item: any) => {
          if (item.responses_no) {
            currentResNo += 1;
          }
          if (item.responses_yes) {
            currentResyes += 1;
          }
        });
        setResponsesYes(currentResyes);
        setResponsesNo(currentResNo);
        setRenderGrafic(true);
      });
    setLoading(false);
  }, [idItem, setValue, setLoading, setResponsesYes, setResponsesNo]);

  return (
    <Container>
      <FullLoader show={loading} />
      <ModalHeader title="Relatório de respostas" closeModal={closeModal} />
      <Content>
        {renderGrafic ? (
          <PieChart responsesYes={responsesYes} responsesNo={responsesNo} />
        ) : (
          ''
        )}
      </Content>
    </Container>
  );
};

export default ResponseReportModal;

import { createGlobalStyle } from 'styled-components';
import white_label from 'white_label';

const { primary_color, secondary_color } = white_label();

export default createGlobalStyle`
  :root {
    --primary: ${primary_color};
    --secondary: ${secondary_color};
    --red: #33cc95;
    --blue: #cc0000;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    font-family: 'Poppins', sans-serif;
  }

  body {
    z-index: -1;
    background-color: #F7F8FC;
  }

  body, -moz-user-input, button {
    font-size: 14px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
    border-radius: 4px;
  }

  input {
    border-radius: 4px;
  }

  .row-2 {
    display: flex;
    justify-content: space-between;
    margin: 12px 0;

    > div {
      width: calc(50% - 12px);
    }

    @media (max-width: 768px) {
      flex-direction: column;

      > div {
        width: 100%;

        & + div {
          margin-top: 12px;
        }
      }
    }
  }

  .row-3 {
    display: flex;
    justify-content: space-between;
    margin: 12px 0;

    > div {
      width: calc(33% - 12px);
    }

    @media (max-width: 768px) {
      flex-direction: column;

      > div {
        width: 100%;

        & + div {
          margin-top: 12px;
        }
      }
    }
  }

  .submit-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 22px 0 0;

    div {
      max-width: 220px;

      @media(max-width: 768px) {
        max-width: 100%;
      }
    }
  }

  .disabled {
    background-color: #ddd !important;
    pointer-events: none;
  }

  .App-payment {
    padding: 30px;
  }


  .form-actions {
    margin-top: 15px;
  }

  .App-cards {
    margin: 0 auto;
    max-width: 1280px;
    text-align: center;
  }

  .App-cards-list {
    display: flex;
    margin-top: 30px;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .App-cards-list > * {
    transform: scale(0.8);
    margin-bottom: 30px !important;
  }

  .App-highlight {
    font-family: serif;
    margin: 15px auto 0;
    max-width: 300px;
  }

  .App-highlight > div {
    padding-left: 40px;
  }

  .App-badges {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .App-badges a {
    display: block;
  }

  .App-badges a + a {
    margin-top: 18px;
  }

  .github__btn {
    align-items: center;
    background-color: #ccc;
    border-radius: 4px;
    color: #444;
    display: flex;
    line-height: 1;
    padding: 8px 12px;
  }

  .github__btn:hover {
    color: #444;
    text-decoration: none;
  }

  .github__btn img {
    display: inline-block;
    height: 28px;
    margin-right: 8px;
  }

  .App-credits {
    background-color: #000;
    color: #fff;
    line-height: 50px;
    text-align: center;
  }

  .App-credits a {
    color: #fff;
    font-weight: bold;
  }

  @media screen and (min-width: 600px) {
    .App-badges {
      flex-direction: row;
    }

    .App-badges a + a {
      margin-top: 0;
      margin-left: 18px;
    }
  }

`;

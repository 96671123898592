import ProjectProps from './dtos/ProjectProps';

const Project: ProjectProps = {
  id_place: 'f13f0061-01f0-476f-9d6c-fe4a1a1f64ca',
  primary_color: '#006edb',
  secondary_color: '#273a9a',
  login_background_img:
    'https://app-haka.s3.amazonaws.com/fundo_beach_tennis.jpeg',
  logo_url: 'https://app-ahaya.s3.amazonaws.com/ahaya_logo_white.png',
  api_url: 'https://ahaya.pluma.tech',
};

export default Project;

/* eslint-disable react/button-has-type */
import React from 'react';
import { usePlace } from 'context';
import { Container } from './styled';

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  type?: 'button' | 'submit' | 'reset';
}

const Button: React.FC<ButtonProps> = ({ children, type, ...rest }) => {
  const { place, loading } = usePlace();

  return (
    <>
      {!loading && (
        <Container secondary_color={place.secondaryColor}>
          <button type={type || 'button'} {...rest}>
            {children}
          </button>
        </Container>
      )}
    </>
  );
};

export default Button;

import React, { useEffect, useCallback, useState } from 'react';
import { MdAdd, MdRemove } from 'react-icons/md';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { addDays } from 'date-fns';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';

import FullLoader from 'components/FullLoader';
import { User } from 'interfaces/user';
import { useQuery } from 'hooks/query';
import { useMenu } from 'context';
import api from 'services/api';
import CreditCardPayment from './CreditCardPayment';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Container,
  Content,
  OptionsContainer,
  Option,
  MonthlyContainer,
  PackagesContainer,
  Footer,
  ContinueButton,
  PaymentTypeContainer,
  PaymentTypeButton,
} from './styles';

interface RadioTypes {
  id: string;
  label: string;
  value: string;
}

const UserPaymentType: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [type, setType] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [finalDate, setFinalDate] = useState<Date | null>(
    addDays(new Date(), 30)
  );
  const [packages, setPackages] = useState(1);
  const [payment, setPayment] = useState('');
  const [user, setUser] = useState<User>({} as User);

  const radioTypes: RadioTypes[] = [
    {
      id: 'Mensal',
      label: 'Mensal',
      value: '1',
    },
    {
      id: 'Pacotes',
      label: 'Pacotes',
      value: '2',
    },
  ];
  const payments: string[] = [
    'Pagamento por fora',
    'Pagamento aqui',
    'Pagamento no app',
  ];
  function getModalWindowWidth(): number {
    const { innerWidth: width, innerHeight: height } = window;
    if (width > 800) {
      return (width * 40) / 100;
    }
    return (width * 90) / 100;
  }

  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      marginLeft: '20vh',
      width: getModalWindowWidth(),
      right: 'auto',
      bottom: 'auto',
      height: '60%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1,
    },
  };

  const id_user = useQuery().get('id_user');
  const { setPageTitle } = useMenu();

  useEffect(() => {
    setPageTitle('Cliente será mensalista ou utilizará pacotes?');
  }, [setPageTitle]);

  useEffect(() => {
    api.get(`/users/findById?id_user=${id_user}`).then((response) => {
      setUser(response.data);
    });
  }, [id_user]);

  const handleRemovePackages = useCallback(() => {
    if (packages === 1) {
      toast.error('Pelo menos 1 pacote');
    } else {
      setPackages(packages - 1);
    }
  }, [packages]);

  const handleAddPackages = useCallback(() => {
    setPackages(packages + 1);
  }, [packages]);

  const handleContinue = useCallback(() => {
    setLoading(true);
    const data = {
      id_user,
      type: type === '1' ? 'Mensal' : 'Pacotes',
      due_date: finalDate,
      packages,
      in_use: payment === 'Pagamento por fora',
    };
    if (payment === 'Pagamento aqui') {
      setPaymentModalOpen(true);
    } else {
      api
        .put('/users/updateUserPayer', data)
        .then((response) => {
          toast.success('Dados de pagamento cadastrados!');
          setLoading(false);
          history.push('/usuarios');
        })
        .catch((error) => {
          toast.error('Erro!');
          setLoading(false);
        });
    }
  }, [payment, finalDate, id_user, packages, type, history]);

  return (
    <Container>
      <OptionsContainer>
        {radioTypes.map((item) => (
          <Option
            key={item.id}
            selected={type === item.value}
            onClick={() => setType(item.value)}
          >
            <button type="button" />
            <p>{item.label}</p>
          </Option>
        ))}
      </OptionsContainer>
      <Content>
        {type !== '' ? (
          <>
            {type === '1' ? (
              <MonthlyContainer>
                <div className="dateContent">
                  <span>Data início</span>
                  <DatePicker
                    selected={startDate}
                    onChange={(date: Date | null) => setStartDate(date)}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
                <div className="dateContent">
                  <span>Data final (próxima cobrança)</span>
                  <DatePicker
                    selected={finalDate}
                    onChange={(date: Date | null) => setFinalDate(date)}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
              </MonthlyContainer>
            ) : (
              <PackagesContainer>
                <span>Quantidade de pacotes: </span>
                <button type="button" onClick={() => handleRemovePackages()}>
                  <MdRemove color="#fff" size={20} />
                </button>
                <div>
                  <p>{packages}</p>
                </div>
                <button type="button">
                  <MdAdd
                    color="#fff"
                    size={20}
                    onClick={() => handleAddPackages()}
                  />
                </button>
              </PackagesContainer>
            )}
            <PaymentTypeContainer>
              {payments.map((item) => (
                <div>
                  <PaymentTypeButton
                    selected={payment === item}
                    onClick={() => setPayment(item)}
                  />
                  <span>{item}</span>
                </div>
              ))}
            </PaymentTypeContainer>
            <Footer onClick={() => handleContinue()}>
              <ContinueButton enabled={type !== '' && payment !== ''}>
                <span>Continuar</span>
              </ContinueButton>
            </Footer>
          </>
        ) : (
          <></>
        )}
      </Content>
      <FullLoader show={loading} />
      <Modal isOpen={paymentModalOpen} style={modalStyle}>
        <CreditCardPayment closeModal={() => setPaymentModalOpen(false)} />
      </Modal>
    </Container>
  );
};

export default UserPaymentType;

/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import ReactSelect, { Props } from 'react-select';
import { Controller, useForm } from 'react-hook-form';

import { Container } from './styled';

interface SelectProps extends Props {
  id?: string;
  name?: string;
  control?: any;
  defaultValue?: any;
  label?: string;
  error?: boolean;
  colourStyles?: any;
  isMulti?: any;
  backgroundColor?: string;
  height?: number | string;
}

const Select: React.FC<SelectProps> = ({
  id,
  name,
  error,
  label,
  control,
  isMulti,
  defaultValue,
  backgroundColor,
  height,
  ...rest
}) => {
  const defaultColourStyles = {
    control: (styles: any) => ({
      ...styles,
      borderRadius: 8,
      backgroundColor: backgroundColor || '#fafafc',
      minHeight: 52,
      height: height || 52,
      border: '1px solid #e6e6f0',
      padding: '0 16px',
      boxShadow: 'none',
    }),
  };

  return (
    <Container error={!!error}>
      {label && <label htmlFor={id}>{label}</label>}
      {control ? (
        <Controller
          render={({ ref, name: itemName, onChange, onBlur, value }) => (
            <ReactSelect
              id={id}
              {...rest}
              name={itemName}
              ref={ref}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              styles={defaultColourStyles}
              isMulti={isMulti as any}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          )}
          name={name || ''}
          control={control}
          defaultValue={defaultValue || ''}
        />
      ) : (
        <ReactSelect
          id={id}
          {...rest}
          isMulti={isMulti as any}
          defaultValue={defaultValue || ''}
          styles={defaultColourStyles}
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      )}
    </Container>
  );
};

export default Select;

import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import config from 'config';
import moment from 'moment';

import 'react-toastify/dist/ReactToastify.css';

import Routes from 'routes';

import GlobalStyle from 'styles/global';

import { store, persistor } from './store';

moment.locale('pt-br');

function App() {
  document.title = config.currentProject;

  return (
    <Provider store={store}>
      <Routes />
      <GlobalStyle />
      <ToastContainer />
    </Provider>
  );
}

export default App;

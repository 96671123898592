import styled from 'styled-components';

interface CheckInButtonProps {
  checkIn: boolean;
}

export const Container = styled.div`
  height: 90%;
`;

export const Content = styled.div`
  height: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;
export const Row = styled.div`
  width: 100%;
  display: flex;
  &.students {
    display: block;
    p {
      padding-left: 5%;
      padding-top: 2%;
      width: 65%;
      display: flex;
      justify-content: space-between;
      .check {
        color: #286738;
      }
      .minus {
        color: #cc0000;
      }
    }
  }
  h3 {
    width: 35%;
  }
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .whats {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .actionButton {
    background: #3464cc;
    height: 32px;
    width: 150px;
    border: 0;
    border-radius: 8px;
    color: #fff;
    margin-top: 16px;
    &: nth-child(2) {
      background: #ec4434;
      margin-left: 16px;
    }
  }
`;

export const ChangeTypeButton = styled.button<CheckInButtonProps>`
  background: ${(props) => (props.checkIn ? '#cb5223' : '#cc0000')};
  border: none;
  padding: 0 6px;
  color: #fff;
  position: absolute;
  margin-left: 12px;
`;
export const GoWhatsapp = styled.button`
  border: none;
  background: none;
  /* padding: 0 6px; */
  /* color: #fff; */
  position: absolute;
  margin-left: 12px;
`;

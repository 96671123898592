import { User } from 'interfaces';
import { takeLatest, call, put, all } from 'redux-saga/effects';

import { createUserAPI, listUsersAPI, updateUserAPI } from 'services/user';

import { Types, UserAction, getUsersSuccess } from './ducks';

function* createUser(action: UserAction): any {
  const { meta, data } = action;
  try {
    yield call(createUserAPI, data as User);
    if (meta?.onSuccess) yield call(meta.onSuccess);
  } catch (error) {
    if (meta?.onFailure) yield call(meta.onFailure);
  }
}

function* getUsers(action: UserAction): any {
  const { meta, query } = action;
  try {
    const response = yield call(listUsersAPI, query as string);
    if (meta?.onSuccess) yield call(meta.onSuccess);
    yield put(getUsersSuccess(response.data));
  } catch (error) {
    if (meta?.onFailure) yield call(meta.onFailure);
  }
}

function* updateUser(action: UserAction): any {
  const { meta, data } = action;
  try {
    yield call(updateUserAPI, data as User);
    if (meta?.onSuccess) yield call(meta.onSuccess);
  } catch (error) {
    if (meta?.onFailure) yield call(meta.onFailure);
  }
}

function* agentsRoot(): any {
  yield all([
    takeLatest(Types.GET, getUsers),
    takeLatest(Types.CREATE, createUser),
    takeLatest(Types.UPDATE, updateUser),
  ]);
}

export default agentsRoot;

import styled from 'styled-components';
import { lighten, shade } from 'polished';
import getCourtColor from 'utils/getCourtColors';
import white_label from 'white_label';

interface OptionProps {
  selected: boolean;
  borderColor?: string;
}

const { secondary_color } = white_label();

export const Container = styled.div`
  display: flex;

  width: 100%;
  height: 80vh;
  flex-direction: row;
  .sectionCalendar {
    height: 90%;
    width: 100%;
  }
`;

export const OptionsContainer = styled.div`
  height: 90%;
  display: flex;
  flex-direction: column;
  width: 20%;
`;

export const Option = styled.div<OptionProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  button {
    height: 30px;
    width: 30px;
    border: 1px solid
      ${(props) => (props.borderColor ? props.borderColor : '#999')};
    border-radius: 50px;
    background: ${(props) => (props.selected ? props.borderColor : '#fff')};
  }
  p {
    margin-left: 10px;
  }
`;
export const StyleWrapper = styled.div`
  .fc-button.fc-prev-button,
  .fc-button.fc-next-button,
  .fc-button.fc-button-primary,
  .fc-button-active {
    background: var(--secondary) !important;
    background-image: none;
    border: none;
  }
  .fc-button-active {
    filter: brightness(0.8);
  }
  .fc-direction-ltr .fc-timegrid-slot-label-frame {
    height: 150px !important;
  }
`;
export const SectionCourts = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
`;
export const SectionScheduling = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  select {
    width: 80%;
    border-radius: 5px;
    padding: 3px;
    border: 1px solid #999;
    margin-top: 8px;
  }
`;

export const EventTitle = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-left: 10px;
  }
`;

export const UserDetailsContainer = styled.div`
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const AppointmentDetail = styled.div`
  button {
    background: #fff;
    color: #000;
    border: 0px;
    height: 20px;
    font-size: 12px;
    width: 20%;
    margin-top: 20px;
  }
`;
export const InputSearch = styled.div`
  width: 100%;
  height: 52px;
  border-radius: 8px;
  border: 1px solid #e6e6f0;
  background-color: #fafafc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;

  input {
    width: 90%;
    padding: 12px;
    font-size: 15px;
    border: none;
    background: none;
    display: flex;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    width: 50px;
    height: inherit;
    background: none;
    border: none;
  }
`;
export const SectionSearch = styled.div`
  .addClientButton {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 50px;
    background: ${secondary_color};
    border: 0;
    transition: 0.3s all;
    span {
      margin-left: 20px;
      color: #fff;
    }
    &:hover {
      background: ${shade(0.2, secondary_color)};
    }
  }
`;
export const SearchResults = styled.div`
  width: 100%;
  height: 57px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  overflow: auto;
  ul {
    width: 100%;
    height: inherit;
    list-style: none;
    padding-left: 1rem;
    li {
      &:hover {
        color: #fff;
        background: #006edb;
      }
    }
  }
`;

import styled from 'styled-components';
import { shade } from 'polished';
import white_label from 'white_label';

const { secondary_color } = white_label();
interface OptionProps {
  selected: boolean;
}

interface SaveButtonProps {
  disabled: boolean;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: row;
  padding-left: 60px;
`;

export const OptionsContainer = styled.div`
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h1 {
    font-size: 16px;
  }
`;

export const Option = styled.div<OptionProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    height: 30px;
    width: 30px;
    border: 1px solid #999;
    border-radius: 50px;
    background: ${(props) => (props.selected ? '#ccc' : 'transparent')};
  }
  p {
    margin-left: 10px;
  }
`;

export const ComboboxContainer = styled.div<SaveButtonProps>`
  height: 40%;
  width: 50%;
  margin: 0 auto;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 35px;
    margin-top: 30px;
    border: 0;
    color: #fff;
    font-size: 16px;
    background: ${(props) =>
      props.disabled ? shade(0.4, secondary_color) : secondary_color};
    transition: 0.3s all;
    svg {
      margin-right: 10px;
    }
    &:hover {
      background: ${shade(0.2, secondary_color)};
    }
  }
`;

import styled, { css } from 'styled-components';

interface ContainerProps {
  error?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    font-size: 16px;
    color: #999;
  }

  input {
    width: 100%;
    height: 52px;
    border-radius: 8px;
    border: 1px solid #e6e6f0;
    padding: 22px;
    font-size: 15px;
    background-color: #fafafc;

    &::placeholder {
      color: #999;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }

    ${(props) =>
      props.error &&
      css`
        border: 1px solid #ed2945;
      `}
  }

  .text-hint {
    margin-top: 2px;
    color: #838383;
    font-size: 10px;
  }
`;

const cpfMask = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

const cnpjMask = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

const phoneMask = [
  '(',
  /\d/,
  /\d/,
  ')',
  '\u2000',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const cellPhoneMask = [
  '(',
  /\d/,
  /\d/,
  ')',
  '\u2000',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const cepMask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

const birthMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

const dashDateMask = [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
const currencyMask = [
  /^(?=(?:,?\d){1,99}(?:\.|$))\d{1,3}(?:,\d{3})*(?:\.\d{2,10})?$/gm,
];

export {
  cpfMask,
  cnpjMask,
  phoneMask,
  cepMask,
  birthMask,
  cellPhoneMask,
  dashDateMask,
  currencyMask,
};

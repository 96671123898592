import React from 'react';
import axios from 'axios';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import Input from 'components/Input';
import Button from 'components/Button';
import api from 'services/api';

import { useAuth } from 'context';

import white_label from 'white_label';

import { Container } from './styles';

interface RecoveryProps {
  password: string;
  password_confirmation: string;
}

const schema = yup.object().shape({
  password: yup.string(),
  password_confirmation: yup.string().when('password', (password: any) => {
    if (password) {
      return yup
        .string()
        .oneOf(
          [yup.ref('password')],
          'A senha não coincide, por favor repita a senha'
        );
    }
    return yup.string();
  }),
});

const ResetPassword: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();

  const { logo_url } = white_label();

  const history = useHistory();

  const { register, handleSubmit, errors } = useForm<RecoveryProps>({
    resolver: yupResolver(schema),
  });

  const { isAuthenticated } = useAuth();

  const onSubmit = React.useCallback(
    async ({ password, password_confirmation }: RecoveryProps) => {
      setLoading(true);

      const token = new URLSearchParams(location.search).get('token');

      if (!token) {
        throw new Error();
      }

      await api
        .post('/password/reset', {
          password,
          password_confirmation,
          token,
        })
        .then((response) => {
          toast.success('Senha alterada com sucesso!');
          history.push('/signin');
          setLoading(false);
        });
    },
    [history, location.search]
  );

  React.useEffect(() => {
    if (isAuthenticated) history.push('/');
  }, [isAuthenticated, history]);

  return (
    <Container>
      <div className="form">
        <div className="logo">
          <img src={logo_url} alt="logo" />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            label="Nova senha"
            type="password"
            name="password"
            register={register}
            error={!!errors.password?.message}
          />
          <Input
            label="Confirme a nova senha"
            type="password"
            name="password_confirmation"
            register={register}
            error={!!errors.password_confirmation?.message}
          />
          <Button type="submit">{loading ? 'Aguarde...' : 'Salvar'}</Button>
        </form>
      </div>
      <div className="bg" />
    </Container>
  );
};

export default ResetPassword;

import { shade } from 'polished';
import styled from 'styled-components';
import white_label from 'white_label';

const { login_background_img, primary_color } = white_label();

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: stretch;

  .bg {
    flex: 1;
    background: url(${login_background_img});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .form {
    padding: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    max-width: 470px;
    background-color: ${primary_color};

    img {
      width: 100%;
      max-width: 220px;
    }

    form {
      display: flex;
      width: 100%;
      max-width: 340px;
      flex-direction: column;

      > div + div {
        margin-top: 22px;
      }

      .text-button {
        margin: 22px 0;
        font-size: 15px;
        color: #fff;
        border: none;
        background: transparent;
        transition: 0.3s all;
        text-decoration: underline;

        &:hover {
          color: ${shade(0.3, '#fff')};
        }
      }
    }
  }
`;

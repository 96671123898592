import React from 'react';
import axios from 'axios';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import Input from 'components/Input';
import Button from 'components/Button';

import { useAuth } from 'context';

import logo from 'assets/logo.png';

import { Container } from './styled';

interface FormProps {
  passToken: string;
}

interface SendTokenProps {
  tokenSent: (sent: boolean) => void;
}

const schema = yup.object().shape({
  passToken: yup.string().required(),
});

const SendToken = ({ tokenSent }: SendTokenProps) => {
  const [loading, setLoading] = React.useState(false);

  const history = useHistory();

  const { register, handleSubmit, errors } = useForm<FormProps>({
    resolver: yupResolver(schema),
  });

  const { isAuthenticated } = useAuth();

  const onSubmit = React.useCallback(
    async ({ passToken }: FormProps) => {
      try {
        setLoading(true);
        const passId = localStorage.getItem('passId');

        if (!passId) return;

        const response = await axios.post(
          `${
            process.env.REACT_APP_BASE_URL || 'http://localhost:8080'
          }/api/v1/login/password`,
          { passToken, passId }
        );
        if (response?.data) {
          tokenSent(true);
          localStorage.setItem('recoveryToken', response.data.token);
        }
      } catch {
        setLoading(false);
      }
    },
    [tokenSent]
  );

  React.useEffect(() => {
    if (isAuthenticated) history.push('/');
  }, [isAuthenticated, history]);

  return (
    <Container>
      <div className="bg" />
      <div className="form">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="partner">
          <span>Portal do Parceiro</span>
          <p>Insira o token enviado no seu e-mail</p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            label="Token"
            name="passToken"
            register={register}
            error={!!errors.passToken?.message}
          />
          <div className="signin-button-container">
            <Button type="submit">{loading ? 'enviando...' : 'enviar'}</Button>
          </div>
          <button
            type="button"
            className="text-button"
            onClick={history.goBack}
          >
            voltar
          </button>
        </form>
      </div>
    </Container>
  );
};

export default SendToken;

import styled from 'styled-components';
import { shade } from 'polished';

interface ButtonProps {
  secondary_color: string;
}

export const Container = styled.div<ButtonProps>`
  width: 100%;

  button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    height: 52px;
    border: none;
    color: #fff;
    font-size: 18px;
    background: ${(props) => props.secondary_color};
    transition: 0.3s all;

    &:hover {
      background: ${(props) => shade(0.2, props.secondary_color)};
    }
  }
`;

/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import * as yup from 'yup';
import { addDays, addMonths } from 'date-fns';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Court } from 'interfaces/courts';
import CurrencyInput from 'components/CurrencyInput';
import Input from 'components/Input';
import Button from 'components/Button';
import ModalHeader from 'components/ModalHeader';
import api from 'services/api';
import { toast } from 'react-toastify';
import Select from 'components/ReactSelect';
import formatHour from 'utils/formatHour';
import formatDateUS from 'utils/formatDateUS';
import { FaFilter, FaPen, FaSearch, FaTimes } from 'react-icons/fa';
import { EventInput } from '@fullcalendar/react';
import clearString from 'utils/clearString';
import formatDateHour from 'utils/formatDateHour';
import formatDate from 'utils/formatDate';
import { Container, Content, InputSearch, SearchResults } from './styles';

interface PageProps {
  closeModal: () => void;
  handleFiltereservations: (name: string) => void;
  inputSearch: string;
  eventsCalendarFiltered: EventInput[];
}

interface EventModalProps {
  title: string;
  price: string;
  starthour: string;
  finishour: string;
  players: number;
}
const schema = yup.object().shape({
  title: yup.string().required(),
  price: yup.string().required(),
  players: yup.number().required(),
});

const SearchModal: React.FC<PageProps> = ({
  closeModal,
  inputSearch,
  eventsCalendarFiltered,
  handleFiltereservations,
}) => {
  const [loading, setLoading] = useState(false);
  const [dataStartEvent, setDataStartEvent] = useState('');
  const [startHour, setstartHour] = React.useState('');
  const [finishHour, setfinishHour] = React.useState('');
  const [selectedCourts, setSelectedCourts] = useState<any>({} as any);
  const [courts, setCourts] = useState<Court[]>([]);
  const [events, setEvents] = useState<EventInput[]>([]);

  const { register, handleSubmit, setValue, control, errors } =
    useForm<EventModalProps>({
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    const formatEvents = eventsCalendarFiltered.map((event) => {
      event.title = event.title?.split('-')[0].trim();
      // @ts-ignore
      event.start = event.start ? new Date(event.start) : '';

      return {
        ...event,
      };
    });
    setEvents(formatEvents);
  }, [eventsCalendarFiltered]);

  return (
    <Container>
      <ModalHeader title={inputSearch} closeModal={closeModal} />
      <Content>
        <InputSearch>
          <button type="button" title="Filtrar">
            <FaSearch color="#999" size={20} />
          </button>
          <input
            id="FilterUser"
            type="text"
            placeholder="Filtrar"
            onChange={(e) => handleFiltereservations(e.target.value)}
            value={inputSearch}
          />
          <button
            type="button"
            title={inputSearch ? 'Limpar' : 'Filtrar'}
            // onClick={() => handleClearFilter()}
          >
            {inputSearch ? (
              <FaTimes color="#999" size={20} />
            ) : (
              <FaFilter color="#999" size={20} />
            )}
          </button>
        </InputSearch>
        <SearchResults>
          <ul>
            {events.map((event) => (
              <li>
                {/* @ts-ignore */}
                {event.title} -{formatDateHour(new Date(event.start))} -
                <button type="button">
                  <FaPen color="#999" size={16} />
                </button>
              </li>
            ))}
          </ul>
        </SearchResults>
      </Content>
    </Container>
  );
};

export default SearchModal;

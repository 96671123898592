import { Reducer } from 'redux';
import { Metadata, User, UsersResponse } from 'interfaces';

interface UserState {
  data: {
    items: UsersResponse;
    agent: User | null;
  };
}

export interface UserAction {
  type: string;
  data?: UsersResponse | User | string | null;
  meta?: Metadata;
  query?: string;
}

// Action Types
export const Types = {
  GET: 'agents/get',
  GET_SUCCESS: 'agents/getSuccess',
  SET: 'agents/set',
  CREATE: 'agents/create',
  UPDATE: 'agents/update',
  UPDATE_SUCCESS: 'agents/updateSuccess',
  DELETE: 'agents/delete',
};

// Action Creators
export const getUsers = (meta?: Metadata, query?: string): UserAction => ({
  type: Types.GET,
  meta,
  query,
});

export const getUsersSuccess = (data: UsersResponse): UserAction => ({
  type: Types.GET_SUCCESS,
  data,
});

export const setUser = (data: User | null): UserAction => ({
  type: Types.SET,
  data,
});

export const createUser = (data: User, meta?: Metadata): UserAction => ({
  type: Types.CREATE,
  data,
  meta,
});

export const updateUser = (data: User, meta?: Metadata): UserAction => ({
  type: Types.UPDATE,
  data,
  meta,
});

export const deleteUser = (data: string, meta?: Metadata): UserAction => ({
  type: Types.DELETE,
  data,
  meta,
});

export const updateUserSuccess = (data: User, meta?: Metadata): UserAction => ({
  type: Types.UPDATE_SUCCESS,
  data,
  meta,
});

// Reducer
const INITIAL_STATE: UserState = {
  data: {
    items: {
      count: 0,
      users: [],
    },
    agent: null,
  },
};

const agentReducer: Reducer<UserState, UserAction> = (
  state = INITIAL_STATE,
  action
): UserState => {
  switch (action.type) {
    case Types.GET_SUCCESS:
      return {
        ...state,
        data: { ...state.data, items: action.data as UsersResponse },
      };
    case Types.SET:
      return {
        ...state,
        data: { ...state.data, agent: action.data as User },
      };
    default:
      return state;
  }
};

export default agentReducer;

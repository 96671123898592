import React, { useEffect, useState, createContext, useContext } from 'react';
import api from 'services/api';

interface PlaceProps {
  id_place: string;
  logo: string;
  name: string;
  backgroundUrl: string;
  primaryColor: string;
  secondaryColor: string;
  options: string[];
}

interface UserType {
  id: string;
  name: string;
}
interface PlaceContextData {
  place: PlaceProps;
  loading: boolean;
  user_types: UserType[];
}

export const PlaceContext = createContext<PlaceContextData>(
  {} as PlaceContextData
);

export const PlaceProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<PlaceProps>({} as PlaceProps);
  const [loading, setLoading] = useState(true);
  const [userTypes, setUserTypes] = useState<UserType[]>([]);

  useEffect(() => {
    api.get('/places/findWebProps').then((response) => {
      setData(response.data);
      api.get(`/users/findAllUserTypes`).then((response2) => {
        setUserTypes(response2.data);
        setLoading(false);
      });
    });
  }, []);

  return (
    <PlaceContext.Provider
      value={{
        place: data,
        loading,
        user_types: userTypes,
      }}
    >
      {children}
    </PlaceContext.Provider>
  );
};

export function usePlace(): PlaceContextData {
  const context = useContext(PlaceContext);

  if (!context) {
    throw new Error('usePlace must be used within a PlaceProvider');
  }

  return context;
}

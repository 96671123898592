import styled from 'styled-components';

export const Container = styled.div`
  height: 90%;
`;

export const Content = styled.div`
  height: 100%;
  margin-top: 10px;

  form {
    padding-bottom: 70px;
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .select {
      width: inherit;
      /* margin-bottom: 30px;
      margin-top: 10px; */
    }
    .inputDateTime {
      width: inherit;
      height: 52px;
      border: 1px solid #d2d2d4;
      padding: 22px;
    }
    button[type='submit'] {
      margin: 15px 0;
    }
  }
`;
export const SectionHour = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  margin-top: 10px;
`;

/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useCallback, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import * as yup from 'yup';
import { MdSearch } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import Select from 'components/ReactSelect';
import Input from 'components/Input';
import Button from 'components/Button';
import FullLoader from 'components/FullLoader';
import ConfirmDialog from 'components/ConfirmDialog';
import api from 'services/api';
import { cpfMask, cellPhoneMask, cepMask } from 'masks';
import { User } from 'interfaces/user';
import { getAddressByCEP } from 'services/cep';
import { usePlace } from 'context';

import { Container } from './styles';

interface PageProps {
  user: User;
}

interface LastStepProps {
  name: string;
  email: string;
  ssn: string;
  cellphone: string;
  zipCode: string;
  street: string;
  number: string;
  complement: string;
  district: string;
  birthDate: string;
  city: string;
  state: string;
}

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().required(),
  ssn: yup.string().required(),
  cellphone: yup.string().required(),
  zipCode: yup.string().required(),
  street: yup.string().required(),
  number: yup.string().required(),
  complement: yup.string().required(),
  district: yup.string().required(),
  birthDate: yup.date().required(),
});

const UserData: React.FC<PageProps> = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectSex, setSelectSex] = useState(
    {} as { value: string; label: string }
  );
  const history = useHistory();

  const { register, handleSubmit, setValue, getValues, errors, control } =
    useForm<LastStepProps>({
      resolver: yupResolver(schema),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optionsSelectSex = [
    { value: 'Masculino', label: 'Masculino' },
    { value: 'Feminino', label: 'Feminino' },
    { value: 'Outros', label: 'Outros' },
  ];

  const { user_types } = usePlace();

  useEffect(() => {
    setValue('name', user.name);
    setValue('ssn', user.ssn);
    setValue('email', user.email);
    setValue('cellphone', user.cellphone);
    setValue('zipCode', user.zipCode);
    setValue('street', user.street);
    setValue('number', user.number);
    setValue('number', user.number);
    setValue('complement', user.complement);
    setValue('district', user.district);
    setValue('city', user.city);
    setValue('state', user.state);
    setValue('birthDate', format(new Date(user.birth_date), 'yyyy-MM-dd'));
    if (user.gender > '') {
      setSelectSex({ value: user.gender, label: user.gender });
    }
    if (
      user_types.filter((i) => i.id === user.user_type)[0]?.name ===
      'Administrator'
    ) {
      setIsAdmin(true);
    }
  }, [setValue, user, user_types]);

  console.log(user);

  const findZipCode = useCallback(async () => {
    setLoading(true);
    const zipCode = getValues('zipCode');
    try {
      const { data } = await getAddressByCEP(zipCode.replace('-', ''));

      setValue('street', data.street);
      setValue('district', data.neighborhood);
      setValue('city', data.city);
      setValue('state', data.state);
      setLoading(false);
    } catch (error) {
      toast.error('CEP não encontrado!');
      setLoading(false);
    }
  }, [getValues, setValue]);

  const onSubmit = useCallback(
    ({
      name,
      email,
      ssn,
      cellphone,
      zipCode,
      street,
      number,
      complement,
      district,
      city,
      state,
      birthDate,
    }: LastStepProps) => {
      const userType = isAdmin
        ? user_types.filter((i) => i.name === 'Administrator')[0].id
        : user_types.filter((i) => i.name !== 'Administrator')[0].id;

      const userData = {
        user_id: user.id,
        name,
        email,
        ssn,
        cellphone,
        zipCode,
        street,
        number,
        complement,
        district,
        city,
        state,
        birth_date: `${format(
          new Date(birthDate),
          'yyyy-MM-dd'
        )}T18:25:43.511Z`,
        gender: selectSex.label,
        user_type: userType,
      };
      setLoading(true);
      api
        .put('/profile', userData)
        .then(() => {
          toast.success('Cliente criado com sucesso!');
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [user, selectSex, isAdmin, user_types]
  );
  const handleOpenConfirm = useCallback(() => {
    setIsOpenDialog(true);
  }, []);

  const handleCloseConfirm = useCallback(() => {
    setIsOpenDialog(false);
  }, []);

  const handleConfirm = useCallback(async () => {
    try {
      setLoading(true);
      await api.delete(`/users/deleteUser?id_user=${user.id}`).then(() => {
        toast.success('Usuario excluido Com sucesso !');
        setLoading(false);
        setIsOpenDialog(false);
        history.push('/usuarios');
      });
    } catch (error) {
      console.log(error);
    }
  }, [history, user.id]);
  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={isAdmin}
                onChange={() => setIsAdmin(!isAdmin)}
              />
            }
            label="É administrador?"
          />
        </FormGroup>
        <Input
          placeholder="Nome completo"
          name="name"
          autoCapitalize="words"
          register={register}
          error={!!errors.name?.message}
        />
        <Input
          placeholder="Data de nascimento"
          type="date"
          name="birthDate"
          autoCapitalize="words"
          register={register}
          error={!!errors.birthDate?.message}
        />
        <Select
          className="select"
          options={optionsSelectSex}
          closeMenuOnSelect
          onChange={(e) => setSelectSex(e || { value: '', label: '' })}
          name="sex"
          isClearable={false}
          value={selectSex}
          placeholder="Sexo"
          register={register}
          // error={!!errors.sex?.message}
        />
        <Input
          placeholder="E-mail"
          name="email"
          register={register}
          error={!!errors.email?.message}
        />
        <Input
          placeholder="CPF"
          name="ssn"
          mask={cpfMask}
          control={control}
          error={!!errors.ssn?.message}
        />
        <Input
          placeholder="Celular"
          name="cellphone"
          mask={cellPhoneMask}
          control={control}
          error={!!errors.cellphone?.message}
        />
        <div className="zipCodeContainer">
          <Input
            placeholder="CEP"
            name="zipCode"
            mask={cepMask}
            control={control}
            error={!!errors.zipCode?.message}
          />
          <button type="button" onClick={findZipCode}>
            <MdSearch size={20} color="#fff" />
          </button>
        </div>
        <Input
          placeholder="Logradouro"
          name="street"
          register={register}
          error={!!errors.street?.message}
        />
        <Input
          placeholder="Número"
          name="number"
          register={register}
          error={!!errors.number?.message}
        />
        <Input
          placeholder="Complemento"
          name="complement"
          register={register}
          error={!!errors.complement?.message}
        />
        <Input
          placeholder="Bairro"
          name="district"
          register={register}
          error={!!errors.district?.message}
        />
        <Input
          placeholder="Cidade"
          name="city"
          register={register}
          error={!!errors.city?.message}
        />
        <Input
          placeholder="Estado"
          name="state"
          register={register}
          error={!!errors.state?.message}
        />
        <div className="actions">
          <Button type="submit">{loading ? 'Aguarde...' : 'Salvar'}</Button>
          <Button
            type="button"
            className="remove"
            onClick={() => handleOpenConfirm()}
          >
            Excluir
          </Button>
        </div>
      </form>
      <FullLoader show={loading} />
      <ConfirmDialog
        title="Deseja realmente excluir esse Cliente?"
        isOpen={isOpenDialog}
        onClose={handleCloseConfirm}
        handleConfirm={handleConfirm}
      />
    </Container>
  );
};

export default UserData;

/* eslint-disable react/jsx-indent */
import React, { useEffect, useRef, useLayoutEffect, useState } from 'react';

import {
  MdCheckCircleOutline,
  MdNotInterested,
  MdSchool,
} from 'react-icons/md';
import { EventContentArg } from '@fullcalendar/react';
import { CalendarProps } from '../../../interfaces/calendar';
import { Container, EventTitle, UserDetailsContainer } from './styles';

interface PageProps {
  eventsFiltered: CalendarProps;
  eventContent: EventContentArg;
}

const ClassItem: React.FC<PageProps> = ({ eventsFiltered, eventContent }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState(0);

  useLayoutEffect(() => {
    if (ref.current) {
      setDimensions(ref.current.clientWidth);
    }
  }, [dimensions]);
  return (
    <Container ref={ref} id="xxxxx">
      {eventsFiltered ? (
        <>
          {eventsFiltered.classUsers ? (
            <>
              <EventTitle className={dimensions < 200 ? 'p' : 'g'}>
                <MdSchool name="#fff" size={20} />
                <p>{eventContent.event.title.replace('- Aula', '')}</p>
                <p>{dimensions}</p>
              </EventTitle>
              <br />
              {dimensions < 200
                ? ''
                : eventsFiltered.classUsers.map((item) => (
                    <UserDetailsContainer>
                      <p>{item.name}</p>

                      {item.retrieved ? (
                        <MdCheckCircleOutline color="#7FFF00" size={15} />
                      ) : (
                        <MdNotInterested color="yellow" size={15} />
                      )}
                    </UserDetailsContainer>
                  ))}
              {/* <p>Alunos:</p> */}
              {}
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default ClassItem;

import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: #fff;
  flex-direction: column;
  /* border: 1px solid #dfe0eb; */
  border-radius: 8px;

  .filterPaid {
    margin-top: 8px;
    span {
      margin-left: 8px;
      color: #999;
    }
  }

  .addPackageButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    background: #cb5223;
    border: 0;
    transition: 0.3s all;
    span {
      margin-left: 20px;
      color: #fff;
    }

    &:hover {
      background: ${shade(0.2, '#cb5223')};
    }
  }

  .pagination-container {
    display: flex;
    justify-content: flex-end;
    margin: 22px 22px 22px 0;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .pagination-options {
      display: flex;
      margin-right: 90px;
      align-items: center;

      @media (max-width: 768px) {
        margin-left: 12px;
      }

      p {
        color: #7a8799;
      }

      select {
        margin-left: 22px;
        border: none;
        background-color: #fff;
        color: #7a8799;
      }
    }
  }

  .table-container {
    overflow-y: auto;
  }
  button {
    border: 0;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .options {
    svg {
      & + svg {
        margin-left: 12px;
      }
    }
  }
`;
export const SectionFilters = styled.div`
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  /* input {
    margin: 0px 15px;
  } */
  .body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 15px;

    justify-content: space-around;
    align-items: flex-end;
    padding: 15px;
  }
`;
export const Foother = styled.div`
  width: 100%;

  padding: 15px;
  div {
    width: 100%;
    display: flex;
    align-items: end;
    justify-content: end;
    button {
      background-color: #273a9a;
      width: 100px;
    }
  }
`;
export const SectionDate = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15px;
`;
export const TableContainer = styled.div`
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  padding: 1rem;
  margin-top: 18px;
`;
export const InputRetrieved = styled.div`
  input {
    border: none;
    &:focus {
      border: 1px solid #273a9a;
    }
  }
`;

export const TableContent = styled.div`
  background: #fff;
  color: #505050;
  border-radius: 15px;
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .links {
    button {
      color: #000;
    }
  }
  .actionsContainer {
    .MuiIconButton-root {
      padding: 10px !important;
      /* Update colors in light.ts and dark.ts */
      background-color: #f2f2f2 !important;
    }

    .MuiButtonBase-root {
      .MuiIconButton-root {
        padding: 10px !important;
        background-color: #f2f2f2 !important;
      }
    }
  }

  .render-number,
  .render-string {
    font-weight: 400;
    line-height: 18px;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    color: #505050;
    padding: 0 8px;
  }
  .render_checkBox {
    font-weight: 400;
    line-height: 18px;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    color: #505050;
    padding: 0 8px;
  }

  .render-number {
    text-align: right;
    padding: 0 8px;
  }

  .detailTableWrap {
    display: flex;
    align-items: center;
    margin-bottom: 0.7rem;

    p {
      margin-left: 0.7rem;
    }
  }
`;

export const TotalsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 60px;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      color: #7a8799;
    }
    span {
      color: #000;
      margin-top: 10px;
    }
  }
`;

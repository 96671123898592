import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  p {
    margin-left: 8px;
    color: #999;
  }

  .blink_me {
    animation: blinker 1s linear infinite;
    width: 20px;
    height: 20px;
    display: inline-block;
    border: 1px solid #fcc404;
    background-color: #fcc404;
    border-radius: 100%;
  }
  @keyframes blinker {
    50% {
      opacity: 0.4;
    }
  }
`;

import React, { useEffect, useCallback, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import Input from 'components/Input';
import Button from 'components/Button';
import FullLoader from 'components/FullLoader';
import api from 'services/api';
import { User } from 'interfaces/user';
import { Container } from './styles';

interface PageProps {
  user: User;
}

interface LastStepProps {
  password: string;
}

const schema = yup.object().shape({
  password: yup.string().required(),
});

const UserPassword: React.FC<PageProps> = ({ user }) => {
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, setValue, errors, control } =
    useForm<LastStepProps>({
      resolver: yupResolver(schema),
    });

  const onSubmit = useCallback(
    ({ password }: LastStepProps) => {
      const userData = {
        email: user.email,
        password,
      };
      setLoading(true);
      api
        .post('/password/reset', userData)
        .then(() => {
          toast.success('Senha alterada com sucesso!');
          setLoading(false);
          setValue('password', '');
        })
        .catch((error) => {
          setLoading(false);
        });
    },
    [user, setValue]
  );

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          placeholder="Nova senha"
          name="password"
          autoCapitalize="words"
          register={register}
          error={!!errors.password?.message}
        />
        <Button type="submit">{loading ? 'Aguarde...' : 'Salvar'}</Button>
      </form>
      <FullLoader show={loading} />
    </Container>
  );
};

export default UserPassword;

import styled from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  height: inherit;
`;

export const EventTitle = styled.div`
  &.p {
    p {
      writing-mode: vertical-rl;
    }
  }
  &.g {
    display: flex;
    align-items: center;
    p {
      margin-left: 10px;
    }
  }
`;

export const UserDetailsContainer = styled.div`
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

import styled from 'styled-components';
import { shade } from 'polished';
import white_label from 'white_label';

const { secondary_color } = white_label();
interface CameraProps {
  isEnabled: boolean;
}

export const Container = styled.div<CameraProps>`
  display: flex;
  flex: 1;

  form {
    flex: 1;
    width: 100%;
    height: ${(props) => (props.isEnabled ? 150 : 100)}vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    row-gap: 0.5rem;
    padding-bottom: 2rem;
    padding-top: 5%;
    .webcamContainer {
      .previewPhotoContainer {
        display: flex;
        flex-direction: row;
        img {
          border-radius: 40px;
        }
      }
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      align-self: center;
      .takePhotoContainer {
        display: flex;
        flex-direction: row;
      }
      .cancelPhotoButton {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        background: red;
        margin-right: -30px;
        z-index: 2;
      }
      .takePhotoButton {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        width: 80px;
        height: 80px;
        border-radius: 40px;
        background: ${secondary_color};
        margin-top: 420px;
        margin-left: -50px;
        z-index: 2;

        &:hover {
          background: ${shade(0.2, secondary_color)};
        }
      }
      .enablePhotoButton {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        width: 80px;
        height: 80px;
        border-radius: 40px;
        background: ${secondary_color};
        transition: 0.2s;

        &:hover {
          background: ${shade(0.2, secondary_color)};
        }
      }
    }
    .zipCodeContainer {
      display: flex;
      flex-direction: row;
      input {
        width: 95%;
      }

      button {
        border: 0;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${secondary_color};
        border-radius: 50px;
        &:hover {
          background: ${shade(0.2, secondary_color)};
        }
      }
    }
  }
`;

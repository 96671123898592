import styled from 'styled-components';

export const Container = styled.div`
  height: 90%;
`;

export const Content = styled.div`
  height: 100%;
  margin-top: 40px;
  .sectionFields {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 90%;
  }
`;

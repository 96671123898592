import React from 'react';
import axios from 'axios';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import Input from 'components/Input';
import Button from 'components/Button';

import { useAuth } from 'context';

import logo from 'assets/logo.png';

import SendEmail from './SendEmail';

import { Container } from './styled';
import SendToken from './SendToken';

interface RecoveryProps {
  password: string;
  confirmPassword: string;
}

const schema = yup.object().shape({
  password: yup.string().min(6),
  confirmPassword: yup.string().when('password', (password: any) => {
    if (password) {
      return yup
        .string()
        .oneOf(
          [yup.ref('password')],
          'A senha não coincide, por favor repita a senha'
        );
    }
    return yup.string();
  }),
});

const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [emailSent, setEmailSent] = React.useState(false);
  const [tokenSent, setTokenSent] = React.useState(false);

  const history = useHistory();

  const { register, handleSubmit, errors } = useForm<RecoveryProps>({
    resolver: yupResolver(schema),
  });

  const { isAuthenticated } = useAuth();

  const onSubmit = React.useCallback(
    async ({ password, confirmPassword }: RecoveryProps) => {
      try {
        setLoading(true);
        const token = localStorage.getItem('recoveryToken');

        if (!token) return;

        const response = await axios.put(
          `${
            process.env.REACT_APP_BASE_URL || 'http://localhost:8080'
          }/api/v1/login/password`,
          { password, confirmPassword },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response?.data) {
          toast.success('Senha alterada com sucesso!');
          history.push('/signin');
          localStorage.removeItem('recoveryToken');
          localStorage.removeItem('passId');
        }
      } catch {
        setLoading(false);
      }
    },
    [history]
  );

  React.useEffect(() => {
    if (isAuthenticated) history.push('/');
  }, [isAuthenticated, history]);

  return (
    <>
      {!emailSent && <SendEmail emailSent={setEmailSent} />}
      {!tokenSent && emailSent && <SendToken tokenSent={setTokenSent} />}
      {emailSent && tokenSent && (
        <Container>
          <div className="bg" />
          <div className="form">
            <div className="logo">
              <img src={logo} alt="logo" />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Input
                label="Senha"
                type="password"
                name="password"
                register={register}
                error={!!errors.password?.message}
              />
              <Input
                label="Confirme a Senha"
                type="password"
                name="confirmPassword"
                register={register}
                error={!!errors.confirmPassword?.message}
              />
              <Button type="submit">
                {loading ? 'enviando...' : 'enviar'}
              </Button>
              <button type="button" className="text-button">
                voltar
              </button>
            </form>
          </div>
        </Container>
      )}
    </>
  );
};

export default ForgotPassword;

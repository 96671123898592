import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .header {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    h1 {
      font-size: 16px;
      color: #999;
      font-family: 'Arial';
    }
    button {
      height: 30px;
      width: 30px;
      background: red;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
    }
  }

  form {
    width: 80%;
    display: flex;
    align-self: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    input {
      width: 100%;
      padding: 10px;
      border-radius: 5px;
      margin-bottom: 5px;
      border: 2px solid #286738;
    }
  }

  button {
    border: 0;
    height: 40px;
    width: 100%;
    margin-top: 20px;
    background: #cb5223;
    color: #fff;
    &:hover {
      background: ${shade(0.2, '#cb5223')};
    }
  }
`;

export const FinalPriceContainer = styled.div`
  margin-top: 20px;
`;

import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
`;

export const Content = styled.div`
  height: 90%;
  margin-top: 20px;
  form {
    width: 100%;
    height: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  p {
    color: #999;
    margin-right: auto;
    margin-bottom: 20px;
  }
  span {
    color: #999;
    margin-right: auto;
    font-size: 16px;
  }
  aside {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 45%;
    justify-content: space-between;
    .contentInputs {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    .dateInput {
      width: 100%;
      height: 52px;
      border-radius: 8px;
      border: 1px solid #e6e6f0;
      padding: 22px;
      font-size: 15px;
      background-color: #fafafc;
    }
  }
`;

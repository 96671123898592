import { shade } from 'polished';
import styled from 'styled-components';

interface ContainerProps {
  background_img_url: string;
  primary_color: string;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  justify-content: stretch;

  .bg {
    flex: 1;
    background: url(${(props) => props.background_img_url});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .form {
    padding: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    max-width: 470px;
    background: ${(props) => props.primary_color};

    img {
      width: 100%;
      max-width: 220px;
    }

    form {
      display: flex;
      width: 100%;
      max-width: 340px;
      flex-direction: column;
      margin-top: 30px;

      > div + div {
        margin-top: 22px;
      }

      .text-button {
        margin: 22px 0;
        font-size: 15px;
        color: #fff;
        text-decoration: underline;
        border: none;
        background: transparent;
        transition: 0.3s all;

        &:hover {
          color: ${shade(0.3, '#fff')};
        }
      }
    }

    .register {
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        font-size: 15px;
        color: #dff;
      }

      button {
        background: transparent;
        border: none;
        font-size: 15px;
        font-weight: bold;

        transition: 0.3s all;
        text-decoration: underline;

        &:hover {
          color: ${shade(0.3, '#2f2f2f')};
        }
      }
    }
  }
`;
